import React, { useEffect, useMemo, useState, useContext } from "react";








import "../../App.css";
import { MfctContext } from "../../context";
import InputData from "./InputData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { data } from "../../service/data";
import VarContent from "../VarContent";


function InputContent({
  id,
  schema, label, onChange, value,className,domain,opened,context

}) {


  const [show,setShow]=useState(opened)
  const { project, getSchemas  } =
    useContext(MfctContext);
  
    const schemas = getSchemas();

    if(!schema.content  ) 
    {  
      
      schema = schemas.find(s=>s.content_id===schema.content_id)
    }
  const [optionValues, setOptionValues] = useState({});


  const getProps = (tpl) => {
    var props = [];
    if (tpl) {
      //Text
      //Boolean
      //Number
      //Image
      //Video
      //Media
      //Template
      //Objet

      
      if (tpl.schema_url) {
        props.push({ name: tpl.schema_url, schema_id: 466 });
      }
      if (tpl.schema_link) {
        props.push({ name: tpl.schema_link, schema_id: 42 });
      }
      if (tpl.schema_label) {
        if (!tpl.schema_id) {
          switch (tpl.tag) {
            case "text":
              tpl.schema_id = 42;
              tpl.defaultValue = tpl.text;
              break;
            case "img":
              tpl.schema_id = 50;
              tpl.defaultValue = tpl.imageUrl;
              break;
            default:
          }
        }
        if (tpl.isList) {
          tpl.type_nombre = "F";
        }
        props.push({ name: tpl.schema_label, ...{ ...tpl, children: null } });
      }
      //Liste
    }
    if (tpl.children && tpl.children.length > 0) {
      tpl.children.forEach((_tpl) => {
        props = [...props, ...getProps(_tpl)];
      });
    }

    return props;
  };



  const modele = useMemo(() => {
  return schema.content.schema
  
  }, [schema]);

  useEffect(() => {
    async function getOptionValues() {
      if (!modele  || schemas.length===0) return;

      var update = false;
      var _optionValues = { ...optionValues };

      for (var i = 0; i < modele.length; i++) {
        console.log("modele", i, modele);

        var _schema_id = modele[i].schema_id;
        if(!_schema_id) continue;
        var _sub_schema = schemas.find((s) => s.content_id === _schema_id);
       
      
        if (
          _schema_id &&
          (_optionValues[_schema_id] || _sub_schema?.content.is_value)
        )
          continue;
        update = true;
        // Contents
        var _values = await  data.getContents( (c)=>c.app_id===parseInt(value.app_id) &&  parseInt(c.schema_id)===parseInt(_schema_id), true)
  
     //   var _values = await project.api("/get_schema_contents", {
      //    body: { app_id: parseInt(value.app_id), schema_id: _schema_id },
      //  });
        _optionValues[_schema_id] = _values;
        console.log("_optionValues", _optionValues);
      }
      if (update) setOptionValues(_optionValues);
    }

    getOptionValues();
  }, []);



  return (
 (
   
        <div className="z-1 w-full mt-4  p-2 flex flex-col items-start ">
     
      {!opened &&  <div className="font-bold w-full p-2 text-left flex justify-between" onClick={()=>setShow(!show)} ><span>{label }</span> <FontAwesomeIcon icon={faAlignLeft} /></div>}

          {
          show &&    
         modele &&     modele.map((s, i) => (
                <div className="w-full flex flex-col " key={i}>
                  <InputData
                    id={id +"."+ s.name + "-schema_label"}
                    prop={s}
                    schema={
                      schemas.find((c) => c.content_id === s.schema_id) ?? {
                        content: { code: "text" },
                      }
                    }
                    className={"w-full my-2"}
                    defaultValue={s.defaultValue}
                    value={value ? value[s.name]    :null }
                    onChange={async (newvalue) => {
                    /*
                      console.log("a charger !!!", newvalue);
                      if (
                        newvalue &&
                        typeof newvalue === "object" &&
                        newvalue.content_id
                      ) {
                        console.log("a charger !!!", newvalue);
                        // Charge le contenu de l'object par son id
                        newvalue = await getContent(newvalue.content_id);
                      } else if (Array.isArray(newvalue)) {
                        for (var j = 0; j < newvalue.length; j++) {
                          var _v = newvalue[j];
                          if (
                            _v &&
                            typeof _v === "object" &&
                            _v.content_id &&
                            Object.keys(_v).length === 1
                          ) {
                            newvalue[j] = await getContent(_v.content_id);
                          }
                        }
                      }*/
                     var  _value = {...(value??{})} 
                     _value[s.name] = newvalue;
                      onChange(_value);
                    }}
                    context={context}
                    options={optionValues[s.schema_id]}
                    placeholder={s.defaultValue ?? "Choisir " + s.name}
                    domain={domain}
                  />
                  {s.defaultValue && (
                    <div className="text-xs italic -mt-2  mx-2 p-2 border border-t-0  border-dashed rounded-b-xl border-zinc-400 text-zinc-500">
                      {s.defaultValue}
                    </div>
                  )}
                </div>
              ))}




           

        </div>
  
    )
  );
}

export default InputContent;
