import  { useEffect,  useState } from 'react';

const getBrightness = (r, g, b) => {
  // Calcule la luminosité avec la formule utilisée pour les normes WCAG
  return (r * 0.299 + g * 0.587 + b * 0.114);
};
const useDynamicTextColor = (elementRef) => {
    const [textColor, setTextColor] = useState('#000000'); // Valeur par défaut
    const [backgroundColor, setBackgroundColor] = useState('#000000'); // Valeur par défaut
    useEffect(() => {



      if (elementRef.current) {

var elt = elementRef.current
while(elt){
        // Récupérer le style calculé pour obtenir la couleur de fond
        const computedStyle = window.getComputedStyle(elt); 
        const _backgroundColor = computedStyle.backgroundColor;
  //console.log("backgroundColor" + backgroundColor);

        // Extraire les valeurs RGB de la couleur de fond
        const rgbValues = _backgroundColor.match(/\d+/g);
        if (rgbValues) {
          const [r, g, b,a] = rgbValues.map(Number);
          if( typeof a ==="number"   && a===0)
            {
elt= elt.parentNode;
continue;
            }
        
          const brightness = getBrightness(r, g, b);
  
          // Définir la couleur du texte en fonction de la luminosité
          setTextColor(brightness > 128 ? '#000000' : '#FFFFFF'); // Noir si clair, blanc si foncé
          setBackgroundColor(_backgroundColor)
          break;
        }
        break;
    }
        }
      
    }, [elementRef]);
  
    return {textColor,backgroundColor};
  };



  export default useDynamicTextColor