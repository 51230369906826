
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import { data } from "../../../../../service/data";

import InputData from "../../../../../components/inputs/InputData";
const TemplateProps = ({template,setShow,schemas,roles,conditions,internalContent,setInternalContent,deleteTemplate,key_schemas}) => {



    if(!template ) return <></>  
    return    <div id={"TemplateParams"}className=" flex-none p-2 w-80   flex flex-col items-center justify-center border-t border-gray-400  shadow">
      <div className="flex justify-between items-center text-xs w-full p-2 text-white bg-black rounded-xl mb-1 ">
      <FontAwesomeIcon icon={fa.faGears}></FontAwesomeIcon>
        Propriétés Template 
        <FontAwesomeIcon icon={fa.faClose}  onClick={ ()=> setShow((show) => ({ ...show, Props: !show.Props })) }  className="appbutton"  ></FontAwesomeIcon>
        </div>
    
          {
            <>
                                <InputData
                      className={"w-full"}
                      prop={{
                        name: "Label",
                        schema_id: 124,
                        code: "definition",
                        type_nombre: "I",
                      }}
                      value={template.props && Array.isArray(template.props) ?template.props :  []}
                      schema={schemas.find(
                        (s) => s.content.code === "definition"
                      )}
                  onChange={(props) => {
                    data.updateTemplate({ ...template, props });
                  }
                    }
                    context={{flex:"col"}}
                    />
             </>
          }
    

        </div>
      };
  


      export default TemplateProps