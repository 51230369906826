import { useEffect, useMemo, useRef } from "react";
import { useState } from "react";
import VarContent from "../VarContent";
import { retrieveProject } from "../../service/mftc-api";
import { arrayMerge } from "../../tools/tpl";
import useDynamicTextColor from "../../tools/useDynamicTextColor";
import SelectDropDown from "./SelectDropDown";
import InputText from "./InputText";

var gProps;

const PropContent = ({value})=><div className="w-full text-sm ">   {value.name +
  (value.compute_id ? "(Calcul)" : "") +
  (value.from === "schema_label"
    ? "(Label)"
    : value.name === "self" ||
    value.from === "self" ||
    value.is_prop
    ? ""
    : "(Request)")}</div>


const InputSelectPropSchema = ({
  label,
  onChange,
  value,
  className,
  options,
  placeholder,
  trueValue,
  criteria_schema,
  globalSchema,
  schemas,
  parentSchema_prop,
  context,
}) => {
  const elementRef = useRef(null);
  const { textColor, backgroundColor } = useDynamicTextColor(elementRef);

  const [_value, setValue] = useState(value);

  const [propExplode, setPropExplode] = useState({});

  const propsBySchema = (schema, preValue = "") => {
    var _result = [];
    schema.content.schema.forEach((c) => {
      if (!criteria_schema || criteria_schema(c))
        _result.push({
          schema_id: c.schema_id,
          value: preValue + c.name,
          label: `<${preValue + c.name}>`,
        });

      var c_schema =
        schemas && schemas.find((s) => s.content_id === c.schema_id);
      if (c_schema) {
        if (!c_schema.content.is_value) {
          if (!propExplode[preValue + c.name]) {
            _result.push({
              ...c,
              value: preValue + c.name + ".*",
              label: "<" + preValue + "" + c.name + ">.*",
            });
            //  console.log(" Hola ",   schema.content.Titre, schema.content.schema)
          } else {
            _result = [
              ..._result,
              ...propsBySchema(c_schema, preValue + c.name + "."),
            ];
          }
        }
      }
    });

    return _result;
  };

  //const [ schemaProps, setSchemaProps ] =useState()

  const [props_schema, setProps_schema] = useState([]);

  useEffect(() => {
    const getProps_schema = async () => {
      if (!globalSchema) return;

      var _result = await retrieveProject().api("/get_props/:schema_id", {
        args: { schema_id: globalSchema.content_id },
      });

      setProps_schema(_result);
    };

    getProps_schema();
  }, [globalSchema]);

  const schemaProps = useMemo(() => {
    const tprops =
      (context?.template?.props_from_templates &&
        context?.template.props_from_templates[context?.template.template_id]
          ?.props) ??
      {};

    const tp_props = (
      context?.template?.props && Array.isArray(context?.template?.props)
        ? context?.template?.props
        : []
    ).map((p) => ({ ...p, from: "schema_label" }));
    var _result = arrayMerge(props_schema, tp_props, "name", false);
    _result = arrayMerge(_result, Object.values(tprops).filter(p=>p.from!=="schema_suid"), "name", false);
    return _result;
  }, [props_schema, context]);

  useEffect(() => {
    setValue(value);
  }, [value, trueValue]);
  return (
    <>
      <div
        ref={elementRef}
        style={{ color: textColor }}
        className={" relative " + className}
      >
        {parentSchema_prop && "parentProp : " + parentSchema_prop}
        <select
          onChange={(e) => {
            var schema_prop = e.target.value;

            var schema_id = null;
            var isList = null;

            console.log("$$$$$$$$ schemaprop", schema_prop, schemaProps);
            if (schema_prop && schema_prop !== `-- ${placeholder} --`) {
              var c = schemaProps.find((s) => s.name === schema_prop);
              schema_id =
                c?.schema_id ??
                (c.is_prop ? c.prop_schema_id : c.porp_schema_id);
              isList =
                c.type_nombre === "I" ||
                c.type_nombre === "L" ||
                (c.is_prop ? c.prop_is_list : c.porp_is_list);
            }
            if (schema_prop === `-- ${placeholder} --`) schema_prop = null;

            onChange(schema_prop, schema_id, isList);
          }}
          value={_value}
          id={"input_" + label}
          className="block px-2.5 pb-2.5 pt-4 w-full text-sm  bg-transparent rounded-lg border border-gray-400 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer"
        >
          {placeholder && (
            <option
              value={null}
              className="   text-opacity-50   text-zinc-500    italic"
            >
              -- {placeholder} --
            </option>
          )}
          {schemaProps &&
            schemaProps.map((prop, i) => (
              <option key={i} value={prop.name}>
                {prop.name +
                  (prop.compute_id ? "(Calcul)" : "") +
                  (prop.from === "schema_label"
                    ? "(Label)"
                    : prop.name === "self" ||
                      prop.from === "self" ||
                      prop.is_prop
                    ? ""
                    : "(Request)")}
              </option>
            ))}
        </select>
        <label
          style={{ background: backgroundColor }}
          htmlFor={"input_" + label}
          className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
        >
          {label}
        </label>
      </div>
      <div className="w-full">

   
      <SelectDropDown 
      value={ _value &&  schemaProps.find((s) => s.name === _value)}
options={schemaProps}
label={label}
fieldId={"name"}
fieldSearch={"name"}

className={"w-full text-left"}
onChange={(prop) => {
  if(prop){
  var schema_prop =prop?.name;

  var schema_id = prop?.prop_schema_id;
  var isList =   prop.type_nombre === "I" ||
  prop.type_nombre === "L" ||
  (prop.is_prop ? prop.prop_is_list : prop.porp_is_list);

  onChange(schema_prop, schema_id, isList);
  }
  else  onChange(null, null, null);
}}
      OptionRender={PropContent}
      />
      </div>
    </>
  );
};
export default InputSelectPropSchema;
