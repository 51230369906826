import { useEffect, useMemo, useState } from "react";
import { data } from "../../service/data";
import InputSelect from "./InputSelect";
import InputData from "./InputData";
import VarContent from "../VarContent";

const InputFiltreEtat = ({
  id,
  label,
  onChange,
  value,
  className,
  bg = "bg-white",
  infos,
  disable,
  context,
}) => {
  if (label) context = { ...context, [label]: true };
  var level = context?.level ?? 1;
  value = value ?? {};
  const [logiqueOperators, setLogiqueOperators] = useState(
    context?.logiqueOperators
  );
  const [Variables, setVariables] = useState(context?.Variables);

  const [compareOperators, setCompareOperators] = useState(
    context?.compareOperators
  );
  var color = [
    Math.round(360 * Math.random()),
    Math.round(0 * Math.random() + 80),
    Math.round(30 + 12 * Math.random()),
  ];
  color = ` hsl(${color[0]},${color[1]}%,${color[2]}%) `;
  const [schemaFiltreEtat, setSchemaFiltreEtat] = useState(
    context?.schemaFiltreEtat
  );
  const [schemaVariable, setSchemaVariable] = useState(context?.schemaVariable);
  useEffect(() => {
    const getOperators = async () => {
      await data.getSchemaContents(693, setLogiqueOperators);
      await data.getSchemaContents(457, setCompareOperators);
      var schemaO = await data.getSchema(693);

      setLogiqueOperators(schemaO.content.values);

      var comparators = await data.getSchemaContents(457);

      setCompareOperators(comparators);

      var schemaV = await data.getSchema(694);
      var schemaF = await data.getSchema(692);
      setSchemaFiltreEtat(schemaF);
      setSchemaVariable(schemaV);

      var schema_object_id = context["Data Source"].content.schema.content_id;

      var schemaObject = await data.getSchema(schema_object_id);
      var standardsProp = [];

      if (!context["Data Source"].content.isList)
        standardsProp.push({
          name: "content_id",
          schema_id: 732/// schemaObject.content_id,
        });
      standardsProp.push({ name: "owner", schema_id: 61 });
      standardsProp.push({ name: "app", schema_id: 60 });
      var props = schemaObject.content.schema.filter(
        (prop) => context.FiltreFront || !prop.front
      ).map(p=>({...p, Liste: p.type_nombre === "I" || p.type_nombre === "F"}));

      var propsCount = props
        .filter((p) => p.Liste)
        .map((p) => ({
          ...p,
          name: p.name + "_count",
          schema_id: 44,
          type_nombre: null,
          Liste:null
        }));
      setVariables([...props, ...standardsProp, ...propsCount]);

      // todo sous-propietes
    };

    if (!schemaVariable) getOperators();
  }, [schemaVariable]);

  const OperatorComponent = ({ plus }) => (
    <InputSelect
      className={"text-xs "}

      style={{ backgroundColor: color ,color:"white"}}
      styleOption={{ color: color }}
      classNameSelect={"p-1  font-bold  appearance-none rounded-full text-center"}

      value={plus ? "" : value?.Operator}
      options={["", ...logiqueOperators]}
      onChange={(Operator) => {
        if (plus) {
          if (Operator === value.Operator) {
            onChange({ ...value, ListCondition: [...value.ListCondition, {}] });
          } else {
            value.ListCondition = [{ ...value }, {}];
            value.Operator = Operator;

            onChange({ ...value });
          }
        } else {
          if (!Operator) {
            if (value.ListCondition && value.ListCondition.length > 0) {
              value = { ...value.ListCondition[0], ListCondition: null };
            }
          } else {
            if (!value.ListCondition || value.ListCondition.length === 0) {
              value = {
                ...value,
                A: null,
                B: null,
                Compare: null,
                ListCondition: [{ ...value }, {}],
              };
            }
          }

          onChange({ ...value, Operator });
        }
      }}
    />
  );

  var ctx = {
    ...context,
    logiqueOperators,
    compareOperators,
    schemaFiltreEtat,
    schemaVariable,
    Variables,
    level: level + 1,
  };

  const propA =useMemo(()=>{
    return   Variables?.find(
        (p) => p.name === value?.A
      )
  },[Variables,value])

  if (!schemaVariable) return <></>;

  return (
    <>
      {label && <div className=" ">{label}</div>}

      <div
        className="p-1 border-l-4 border-r-4 rounded-lg  m-1  text-xs flex flex-wrap items-center relative px-3"
        style={{ borderColor: color }}
      >
        <div
          className="absolute bottom-0 left-1 text-xs"
          style={{ color: color }}
        >
          {" "}
          {level}{" "}
        </div>
        <div
          className="absolute bottom-0 right-1  text-xs"
          style={{ color: color }}
        >
          {" "}
          {level}{" "}
        </div>
        {(!value.ListCondition || value.ListCondition.length === 0) && (
          <>

            <InputData
              value={value.A}
              prop={schemaFiltreEtat.content.schema.find((p) => p.name === "A")}
              schema={schemaVariable}
              context={{ ...ctx, idVariable: "A", value }}
              onChange={(A) => onChange({ ...value, A, B: null, Compare: "" })}
            />
            {value.A &&  propA &&    (
              <>
                {" "}
                <InputSelect
                  className={"text-xs font-bold "}
                  style={{ backgroundColor: color ,color:"white"}}
                  styleOption={{ color: color }}
                  classNameSelect={"p-1  font-bold  appearance-none rounded-full text-center"}
                  value={value?.Compare}
                  options={[
                    "",
                    ...compareOperators
                      .filter(
                        (c) =>
                         !!c.content.Liste ===
                            !!propA.Liste &&
                          (!c.content.Schema ||
                            c.content.Schema.find( s=>s.content_id+""===""+ propA.schema_id)
                          
                          )
                      )
                      .map((p) => p.title),
                  ]}
                  onChange={(Compare) =>
                    onChange({ ...value, Compare, B: null })
                  }
                />
                {value.Compare && (
                  <>
                  {    !compareOperators.find(c=>value.Compare ===c.title).content.Alone &&
                    <InputData
                      value={value.B}
                      prop={schemaFiltreEtat.content.schema.find(
                        (p) => p.name === "B"
                      )}
                      schema={schemaVariable}
                      context={{ ...ctx, idVariable: "B", Filtre: value }}
                      onChange={(B) => onChange({ ...value, B })}
                    />

                                        }                    <OperatorComponent />
                  </>
                )}
              </>
            )}
          </>
        )}

        {value.ListCondition &&
          value.ListCondition.length > 0 &&
          value.ListCondition.map((v, i) => (
            <span key={i} className="flex flex-wrap">
              {i > 0 && <OperatorComponent />}
              <InputFiltreEtat
                key={i}
                value={v}
                context={ctx}
                onChange={(vn) => {
                  var ListCondition = [...value.ListCondition];
                  ListCondition[i] = vn;
                  onChange({ ...value, ListCondition });
                }}
              />
            </span>
          ))}

        {value.ListCondition && value.ListCondition.length > 0 && (
          <OperatorComponent plus={true} />
        )}
      </div>
    </>
  );
};
export default InputFiltreEtat;
