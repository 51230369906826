import React, { useEffect, useState, useRef } from 'react';
import './Frame.css'; // Pour les styles supplémentaires
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';

function getRelativeCoords(elementId, parentId) {
  let element = document.getElementById(elementId);
  let parent = document.getElementById(parentId);

  if (!element || !parent) {
    console.error("Élément ou parent non trouvé");
    return null;
  }

  let relativeTop = 0;
  let relativeLeft = 0;

  let currentElement = element;

  while (currentElement && currentElement !== parent) {
    const style = window.getComputedStyle(currentElement);
    const position = style.position;

    // Ajout des offsets de l'élément actuel
    relativeTop += currentElement.offsetTop;
    relativeLeft += currentElement.offsetLeft;

    // Ajustement avec les marges de l'élément actuel
    const marginTop = parseFloat(style.marginTop);
    const marginLeft = parseFloat(style.marginLeft);
    //relativeTop += marginTop;
    //relativeLeft += marginLeft;

    // Si le parent est en position `fixed`, on arrête de remonter
    if (position === 'fixed') {
      break;
    }

    // Ajustement avec le défilement (scrolling)
    if (currentElement !== document.body) {
      relativeTop -= currentElement.scrollTop;
      relativeLeft -= currentElement.scrollLeft;
    }

    // Passer à l'élément parent (offsetParent)
    currentElement = currentElement.offsetParent;
  }

  // Ajustement avec le padding et margin du parent final
  if (parent) {
    const parentStyle = window.getComputedStyle(parent);
    const parentPaddingTop = parseFloat(parentStyle.paddingTop);
    const parentPaddingLeft = parseFloat(parentStyle.paddingLeft);
    relativeTop -= parentPaddingTop;
    relativeLeft -= parentPaddingLeft;
  }

  return { top: relativeTop, left: relativeLeft };
}


const Frame = ({ id, parent,insert,insertInside }) => {
  const [elementRect, setElementRect] = useState(null);
  const frameRef = useRef(null);

  const updatePosition = () => {
    const parentElement = document.getElementById(parent);
    const element = document.getElementById(id);

    if (element && parentElement) {
     

      const coords = getRelativeCoords(id,parent)
     

      const elementComputedStyle = window.getComputedStyle(element);
      
 
      // Ajuster la taille et la position en fonction des marges et du padding
      const relativeRect = {
        top: coords.top,// elementRect.top - parentRect.top,//+  paddingTop,
        left: coords.left ,//elementRect.left - parentRect.left,//+ paddingLeft,
        width:     elementComputedStyle.width ,//elementRect.width ,//+ paddingLeft + paddingRight,
        height:     elementComputedStyle.height, // elementRect.height,// + paddingTop + paddingBottom,
      };

      setElementRect(relativeRect);
    }
  }
  
  
  useEffect(() => {
    const element = document.getElementById(id);
    const parentElement = document.getElementById(parent);

    if (!element || !parentElement) {
      return;
    }

    // Observer les changements de taille avec ResizeObserver
    const resizeObserver = new ResizeObserver(() => {
      updatePosition(); // Mettre à jour la position quand l'élément ou parent est redimensionné
    });
    resizeObserver.observe(element);
    resizeObserver.observe(parentElement);

    // Observer les changements dans les attributs avec MutationObserver
    const mutationObserver = new MutationObserver(() => {
      updatePosition(); // Mettre à jour la position quand l'élément ou parent change d'attribut
    });
    mutationObserver.observe(element, { attributes: true, childList: true, subtree: true });
    mutationObserver.observe(parentElement, { attributes: true, childList: true, subtree: true });

    // Nettoyer les observers lors du démontage du composant
    return () => {
      resizeObserver.disconnect();
      mutationObserver.disconnect();
    };
  }
  , [id, parent]);

  if (!elementRect) return null;

  return (
    <div
      ref={frameRef}
      className="frame transition-all overflow-visible"
      style={{
        position: 'absolute',
        top: elementRect.top,
        left: elementRect.left,
        width: elementRect.width,
        height: elementRect.height,
        zIndex:1000000
      }}
    >
{ (insert )&& 
<>
<FontAwesomeIcon   className=' absolute  h-5 w-5 rounded-full  bg-white border-white cursor-copy   text-green-600 pointer-events-auto top-middle -mt-5'icon={faCirclePlus}  onClick={()=>insert("top")} />


<FontAwesomeIcon className='absolute text-green-600 h-5 w-5   bg-white rounded-full border border-white cursor-copy    bottom-middle -mb-5 pointer-events-auto' onClick={()=>insert("bottom")} icon={faCirclePlus}/>
<FontAwesomeIcon className='absolute text-green-600 h-5 w-5    bg-white  rounded-full border border-white cursor-copy   middle-left  -ml-5 pointer-events-auto' onClick={()=>insert("left")} icon={faCirclePlus}/>
<FontAwesomeIcon className='absolute text-green-600 h-5 w-5    bg-white  rounded-full border border-white cursor-copy   middle-right  -mr-5 pointer-events-auto' onClick={()=>insert("right")} icon={faCirclePlus}/>
{insertInside &&<FontAwesomeIcon className='absolute text-green-600 h-5 w-5    bg-white  rounded-full border border-white cursor-copy     middle  pointer-events-auto' onClick={()=>insertInside("inside")} icon={faCirclePlus}/>
}
</>

}


      {/* Lignes */}
      <div className="line top transition-all"></div>
      <div className="line bottom transition-all"></div>
      <div className="line left transition-all"></div>
      <div className="line right transition-all"></div>

      {/* Petits ronds */}
      <div className="circle top-left transition-all"></div>
      <div className="circle top-middle transition-all"></div>
      <div className="circle top-right transition-all"></div>
      <div className="circle bottom-left transition-all"></div>
      <div className="circle bottom-middle transition-all"></div>
      <div className="circle bottom-right transition-all"></div>
      <div className="circle middle-left transition-all"></div>
      <div className="circle middle-right transition-all"></div>
    </div>
  );
};

export default Frame;
