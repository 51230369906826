
function extractPathsFromSVG(svgText,viewBox="0 0 512 512",withType=false) {
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svgText, "image/svg+xml");



    const paths = svgDoc.querySelectorAll("path");
    
    const pathData = [];
    const styles = parseStyles(svgDoc);
  const [x,y,width,height]  = viewBox.split(" ");
  const scale = 512/Math.max( width*1,height*1   )


  const shapes = svgDoc.querySelectorAll("path, circle, rect"); // Ajout des cercles et rectangles

  shapes.forEach((shape) => {
      let points = [];

      if (shape.tagName === 'path') {
        const d = shape.getAttribute("d");
        points = parsePathCommands(d, scale, withType);
              // Extraction et parsing de la commande 'd'

    } else if (shape.tagName === 'circle') {
        const centerX = parseFloat(shape.getAttribute("cx"))*scale;
        const centerY = parseFloat(shape.getAttribute("cy"))*scale;
        const radius = parseFloat(shape.getAttribute("r"))*scale;
        const k = radius * 0.552284749831*scale;
        // Calcul des sommets du triangle équilatéral
  
        points = [
          { x: centerX, y: centerY - radius, type: "M"},
          { x: centerX + k, y: centerY - radius, type: "C" },
          { x: centerX + radius, y: centerY - k, type: "C2"},
          { x: centerX + radius, y: centerY, type: "F" },
  
          { x: centerX + radius, y: centerY + k, type: "C" },
          { x: centerX + k, y: centerY + radius, type: "C2" },
          { x: centerX, y: centerY + radius, type: "F" },
  
          { x: centerX - k, y: centerY + radius, type: "C" },
          { x: centerX - radius, y: centerY + k, type: "C2" },
          { x: centerX - radius, y: centerY, type: "F" },
  
          { x: centerX - radius, y: centerY - k, type: "C" },
          { x: centerX - k, y: centerY - radius, type: "C2" },
          { x: centerX, y: centerY - radius, type: "F" },
          { x: centerX, y: centerY - radius, type: "Z" },
        ];
  
    } else if (shape.tagName === 'rect') {
        const x = parseFloat(shape.getAttribute("x")??0)*scale;
        const y = parseFloat(shape.getAttribute("y")??0)*scale;
        const width = parseFloat(shape.getAttribute("width"))*scale;
        const height = parseFloat(shape.getAttribute("height"))*scale;
        const rx = parseFloat(shape.getAttribute("rx")??0) * scale || 0; // Récupération de rx
        const ry = parseFloat(shape.getAttribute("ry")??0) * scale || rx; // Récupération de ry, par défaut égal à rx
        const kx = rx * 0.552284749831*scale;
        const ky = ry * 0.552284749831*scale;

        points = [
            { type: 'M', x: x+rx, y: y },
            { type: 'C', x: x + width-rx, y: y },
            { type: 'C2', x: x +rx, y: y },
            { type: 'F', x: x + width-rx , y: y },


//arrondi ok
...( rx+ry>0 ? [           { type: 'C', x: x + width-rx+kx, y: y  },
            { type: 'C2', x: x + width, y: y+ry-ky  },
            { type: 'F', x: x + width, y: y +ry }] :[]),



            { type: 'C', x: x + width, y: y + height-ry },
            { type: 'C2', x: x + width, y: y+ry  },
            { type: 'F', x: x + width, y: y + height-ry },


//arrondi ok
...( rx+ry>0 ? [ { type: 'C', x: x + width, y: y+height-ry+ky  },
{ type: 'C2', x: x + width-rx+kx, y: y+height },
{ type: 'F', x: x + width-rx, y: y +height }] :[]),


            { type: 'C', x: x+rx , y: y + height },
            { type: 'C2', x: x + width-rx, y: y + height   },
            { type: 'F', x: x+rx, y: y + height },

//arrondi ok

...( rx+ry>0 ? [ { type: 'C', x: x +rx-kx, y: y+height },
{ type: 'C2', x: x , y: y+height-ry+ky  },
{ type: 'F', x: x, y: y +height-ry }] :[]),



            { type: 'C', x: x, y: y+ry },
            { type: 'C2', x: x , y: y + height-ry   },
            { type: 'F', x: x, y: y+ry },


//arrondi ok
...( rx+ry>0 ? [ { type: 'C', x: x , y: y +ry -ky},
{ type: 'C2', x: x+rx-kx , y: y },
{ type: 'F', x: x +rx, y: y  }] :[]),
 

            { type: 'Z', x: x+rx, y: y } // Ferme le rectangle

        ]; // Ajout des points pour le rectangle
    }
      




         // Extraction des couleurs
  
         const computedStyle = getComputedStyle(shape, styles);
  
         // Extraction des informations de couleur et autres attributs
         const fill = getColorComponents(computedStyle.fill);
         const stroke = getColorComponents(computedStyle.stroke);
         const strokeWidth = parseFloat(computedStyle["stroke-width"]) || 0;
     
         
  points[0]= {...points[0],      fill,
    stroke,
    strokeWidth} 
      // Ajoute les informations extraites pour chaque path
      pathData.push(
  
        points
      );
    });
  
    return pathData;
  }
  
  // Fonction pour extraire les composants RGBA d'une couleur
  function getColorComponents(color) {
    if (!color) return { r: 0, g: 0, b: 0, a: 0 };
    
    let r = 0, g = 0, b = 0, a = 1; // Valeurs par défaut
    if (color.startsWith("#")) {
      // Si la couleur est en format hexadécimal
      if (color.length === 7) {
        r = parseInt(color.slice(1, 3), 16);
        g = parseInt(color.slice(3, 5), 16);
        b = parseInt(color.slice(5, 7), 16);
      }
    } else if (color.startsWith("rgb")) {
      // Si la couleur est en format rgb(a)
      const match = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([0-9.]+))?\)/);
      if (match) {
        r = parseInt(match[1]);
        g = parseInt(match[2]);
        b = parseInt(match[3]);
        a = match[4] !== undefined ? parseFloat(match[4]) : 1;
      }
    }
  
    return { r: r, g: g, b: b, a: a };
  }
  
  
  
  function parsePathCommands(d,scale=1,withType=false) {
    const commands = d.match(/[mMlLhHvVcCzZsS][^mMlLhHvVcCzZsS]*/g); // Ajout des commandes Z, z, S, s
   const points = [];
    
    let lastX = 0;
    let lastY = 0;
  
    let MX=0
    let MY=0
    commands.forEach((command) => {
      const type = command[0];
  //    const coords = command.slice(1).trim().split(/[\s,]+/).map(parseFloat);
      const coords = command.slice(1).trim().match(/-?\d*\.?\d+(e[+-]?\d+)?/gi)?.map(parseFloat);
      coords?.forEach(c=>    {

        if(isNaN(c))
            console.log("NAN !! ", { command,d } )
      }  )
         if (type === 'Z' || type === 'z') {
        // Ferme le chemin
        points.push({ type: 'Z',x:MX,y:MY });
        return; // On ne met pas à jour lastX et lastY ici
    } else if (type === 'S' || type === 's') {
        // Courbe de Bézier avec un point de contrôle implicite
        const ctrlX = lastX + (lastX - points[points.length - 2].x); // Point de contrôle implicite
        const ctrlY = lastY + (lastY - points[points.length - 2].y);
        const x = type === 'S' ? coords[0] : lastX + coords[0];
        const y = type === 'S' ? coords[1] : lastY + coords[1];
        points.push({ type: 'C', x: ctrlX, y: ctrlY });
        points.push({ type: 'C2', x: ctrlX, y: ctrlY });
        points.push({ type: 'F', x: x, y: y });
        lastX = x;
        lastY = y;
        return;
    }
      if (type === 'M' || type === 'm') {
        // Point de départ (absolu pour M, relatif pour m)
        lastX = type === 'M' ? coords[0] : lastX + coords[0];
        lastY = type === 'M' ? coords[1] : lastY + coords[1];
        points.push({ type: 'M', x: lastX, y: lastY });
        MX=lastX
        MY =lastY

      } else if (type === 'L' || type === 'l') {
        // Ligne droite (absolu pour L, relatif pour l)
        const x = type === 'L' ? coords[0] : lastX + coords[0];
        const y = type === 'L' ? coords[1] : lastY + coords[1];
  
        points.push({ type: 'C',  x: x, y: y});
        points.push({ type: withType ? 'C2':undefined ,  x: lastX, y: lastY});
        points.push({  type: withType ? 'F':undefined , x: x, y: y});
        
        lastX = x;
        lastY = y;
      } else if (type === 'H' || type === 'h') {
        // Ligne horizontale (absolu pour H, relatif pour h)
        const x = type === 'H' ? coords[0] : lastX + coords[0];
     
        points.push({ type: 'C',  x: x, y: lastY});
        points.push({type: withType ? 'C2':undefined ,  x: lastX, y: lastY});
        points.push({  type: withType ? 'F':undefined , x: x, y: lastY});
        lastX = x;
      } else if (type === 'V' || type === 'v') {
        // Ligne verticale (absolu pour V, relatif pour v)
        const y = type === 'V' ? coords[0] : lastY + coords[0];
        points.push({ type: 'C',  x: lastX, y: y});
        points.push({ type: withType ? 'C2':undefined , x: lastX, y: lastY});
        points.push({  type: withType ? 'F':undefined , x: lastX, y: y});
        lastY = y;
      } else if (type === 'C' || type === 'c') {
        // Courbe de Bézier (absolu pour C, relatif pour c)
        for (let i = 0; i < coords.length; i += 6) {
          const ctrl1X = type === 'C' ? coords[i]     : lastX + coords[i];
          const ctrl1Y = type === 'C' ? coords[i + 1] : lastY + coords[i + 1];
          const ctrl2X = type === 'C' ? coords[i + 2] : lastX + coords[i + 2];
          const ctrl2Y = type === 'C' ? coords[i + 3] : lastY + coords[i + 3];
          const x = type === 'C' ? coords[i + 4] : lastX + coords[i + 4];
          const y = type === 'C' ? coords[i + 5] : lastY + coords[i + 5];
          points.push({ type: 'C',  x: ctrl1X, y: ctrl1Y});
          points.push({ type: withType ? 'C2':undefined , x: ctrl2X, y: ctrl2Y});
          points.push({  type: withType ? 'F':undefined , x: x, y: y});
       
          lastX = x;
          lastY = y;
        }
      }
    })
  

console.log("POINTS PATH ",{commands, points} )

    return points.map(pt=>({...pt,x:pt.x*scale,y:pt.y*scale}));
  }

  
function parseStyles(svgDoc) {
    const styles = {};
    const styleElements = svgDoc.querySelectorAll("style");
  
    styleElements.forEach((styleElement) => {
      const cssText = styleElement.textContent;
      const rules = cssText.match(/[^{]+\{[^}]*\}/g) || [];
  
      rules.forEach((rule) => {
        const [selector, styleText] = rule.split(/\s*\{\s*/);
        const styleProps = styleText.replace("}", "").split(";").reduce((acc, prop) => {
          const [name, value] = prop.split(":").map((s) => s.trim());
          if (name) acc[name] = value;
          return acc;
        }, {});
  
        styles[selector.trim()] = styleProps;
      });
    });
    return styles
  }
  // Fonction pour appliquer les styles définis par classe aux éléments correspondants
  function getComputedStyle(element, styles) {
    const className = element.getAttribute("class");
    const style = { 
      fill: element.getAttribute("fill") || "none",
      stroke: element.getAttribute("stroke") || "none",
      "stroke-width": element.getAttribute("stroke-width") || "0"
    };
  
    if (className && styles["." + className]) {
      const classStyles = styles["." + className];
      style.fill = classStyles.fill || style.fill;
      style.stroke = classStyles.stroke || style.stroke;
      style["stroke-width"] = classStyles["stroke-width"] || style["stroke-width"];
    }
  
    return style;
  }


  export {   extractPathsFromSVG,   }