import * as fa from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo, useState } from "react";


const availableClasses = [

];
availableClasses.sort((a, b) => (a < b ? -1 : 1));

const InputClassName = ({id,label,onChange,value,className,infos}) => {
value =value ??{ }
const {idObj} = value;

  const classes = useMemo(() => {
 

    var parts = (value.className?? "")
      .split(" ")
      .filter((classe) => classe && classe !== "");

    return parts;
  }, [value]);

  const cssAttributes = useMemo(() => {


    var parts =   Object.keys( value.cssAttributes ?? {}).map((key) => key + ':' + value.cssAttributes[key])

    return parts;
  }, [value]);

  const removeCssAttribute = (keyvalue) => {

    var cssAttributes =value.cssAttributes;
    var key = keyvalue.split(':')[0];
    delete cssAttributes[key];

    onChange({...value, cssAttributes});
  };


  const addMixValue=(mixvalue) => {
    hoverEnd(mixvalue)
setFilter()
var inputObj= document.getElementById("inputTool0Style")
if(inputObj) inputObj.value=""
var parts = mixvalue.split(":");
if( parts.length===1 || parts[0].length<3) addClasse(mixvalue);
else addCssAttribute(mixvalue)
  }

  const addCssAttribute = (keyvalue) => {

    var cssAttributes = value.cssAttributes?? {};
    var [key,value] = keyvalue.split(':');
     cssAttributes[key]=value ;

     onChange({...value, cssAttributes});
  };

function isAttributeCSS(prop) {
  // Créer un élément div factice
  var div = document.getElementById('root');

  // Vérifier si l'attribut CSS est défini pour l'élément div
  
  return prop in div.style;
}

  const [filter, setFilter] = useState();

  const removeClasse = (classe) => {
    var _classes = classes.filter((_classe) => _classe !== classe);


    onChange({...value,  className: _classes.join(" ") });
  };

  const addClasse = (classe) => {
    var _classes = classes;

    _classes.push(classe);

    onChange({...value,  className: _classes.join(" ") });
  };

  var oldvalue =null
  const hoverStart = (mixvalue) => {
if(!idObj) return 
    var obj = document.getElementById(idObj);
    if (!obj) return
    console.log("hoverStart", obj, mixvalue);


var parts = mixvalue.split(":");
if( parts.length===1 ||  !isAttributeCSS( parts[0]))
 obj.classList.add(mixvalue);
     else {
      oldvalue =  obj.style[parts[0]]
      obj.style[parts[0]]=parts[1];
     }
  };

  const hoverEnd = (mixvalue) => {
    if(!idObj) return 
    var obj = document.getElementById(idObj);
    if (!obj) return
    var parts = mixvalue.split(":");
    console.log("hoverEnd", obj, mixvalue);
    if( parts.length===1 ||  !isAttributeCSS(   parts[0]))  
    obj.classList.remove(mixvalue);
  else  {
    obj.style[parts[0]]=oldvalue
  }
  };

  return (
    <>
   
        <div id={id}
          className={
            "w-full h-full justify-between flex flex-col relative  mr-14"
          }
        >
          <div className="w-full flex-nowrap text-start ">
            {" "}
            {classes &&
              classes.map((classe, i) => (
                <div
                  key={i}
                  onClick={() => removeClasse(classe)}
                  className="appbutton  inline-block text-xs p-1 px-2 m-1 mb-1 text-white rounded-full bg-green-500 border-2"
                >
                  <FontAwesomeIcon icon={fa.faCheck} className="hidden" />
                  {" " + classe}
                </div>
              ))}
            {cssAttributes &&
              cssAttributes.map((mixvalue, i) => (
                <div
                  key={i}
                  onClick={() => removeCssAttribute(mixvalue)}
                  className="appbutton  inline-block text-xs p-1 px-2 m-1 mb-1 text-white rounded-full bg-blue-500 border-2 "
                >
                  <FontAwesomeIcon icon={fa.faCheck} className="hidden" />
                  {" " + mixvalue}
                </div>
              ))}
          </div>



          <div className="flex flex-row  w-full p-1">
            <input
              type="text"
              id={"inputTool0Style"}
              onChange={(e) => setFilter(e.target.value)}
              className="   grow rounded-lg text border border-slate-300 text-xs p-1 bg-white text-black"
              placeholder="Saisir classe tw ou attribut css"
            />
            {filter &&
              filter.length > 2  && (
                <FontAwesomeIcon
                  icon={fa.faPlus}
                  className={"-ml-6  text-white p-2 appbutton rounded-full " + ( 
                    filter.split(":").length===1 ||   !isAttributeCSS(  filter.split(":")[0])  
                    ?"bg-green-500":"bg-blue-500")}
                  onClick={() => addMixValue(filter)}
                  onMouseOver={() => hoverStart(filter)}
                  onMouseOut={() => hoverEnd(filter)}
                />
              )}
          </div>
        </div>
      
    </>
  );
};
export default InputClassName;
