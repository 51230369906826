import React, { useEffect, useMemo, useState,useContext } from 'react';
import { Link,useNavigate,useParams } from 'react-router-dom';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../../../../App.css';
import { MfctContext } from "../../../../context";
import Content from '../../../../components/Content';
import TemplateDisplay from '../../../../components/templates/TemplateDisplay';
import { getFilterRecord } from '../../../../service/localdata';
import { data } from '../../../../service/data';
import { addWorkingStone } from '../../../../service/milestone';

function AppTemplates({app,searchValue}) {
const navigate = useNavigate()

const [contents,setContents]= useState([])
const { project, getProject,setAuthUser,authUser,updateTemplate,   getGlobalData} = useContext (MfctContext);
const [schema,setSchema]=useState();
const [schemas, setSchemas] = useState([]);

const [templates, setTemplates] = useState([]);



async function getTemplates() {
  await getGlobalData()
  var _schemas= await   data.getSchemasByApp(app.app_id)
  //  getFilterRecord((content)=>parseInt(content.app_id)===parseInt(app.app_id) && content.is_schema,"content")
 //  await project.api("/get_schemas/:app_id",{args:{app_id:app.app_id} })

    var _templates = await await  getFilterRecord((template)=>parseInt(app.app_id)===parseInt(template.app_id) ,"template") 

console.log(_templates,"templates")


_schemas = _schemas.filter(s=> _templates.find(t=>t.schema_id===s.content_id)   );
_schemas.sort((a,b)=> a.title < b.title ? -1 : 1);
var tout={title:"Tout",content_id:0}
_schemas = [...[tout,{title:"Layout - Bloc",layout:"bloc" , content_id:0}  ,
{title:"Layout - Top",layout:"top" , content_id:0} ,
{title:"Layout - Bottom",layout:"bottom" , content_id:0} ,

  ] ,..._schemas]  
setSchemas(_schemas);
setSchema(tout);

  setTemplates( (_templates?? []))
}




const  deleteTemplate = async(template)=>{
  if (!window.confirm("Are you sure you want to delete '" + template.title+"'?")) return
   await project.api("/delete_template/:template_id",{args:{template_id:template.template_id}})
await    getTemplates();
}




const newTemplate = async () => {
  await data.init("template","", false, project)
  var _template = await data.newTemplate({   app_id: app.app_id, title: " Nouveau template - " , layout : schema.layout??null ,schema_id :schema.content_id??null  })
  await data.close();
  await getTemplates()
  window.open("/template/" +_template.template_id, "viewtpl" + _template.template_id, "menubar=no,toolbar=no,location=no,address=no,")

};



const duplicateTemplate = async (template)=>{

var _template = JSON.parse(JSON.stringify(template))
delete _template.template_id
_template.title = template.title + "-Copy" 
      await data.init("template","", false, project)
       _template = await data.newTemplate(_template)
      await data.close();
      await getTemplates()
      window.open("/template/" +_template.template_id, "viewtpl" + _template.template_id, "menubar=no,toolbar=no,location=no,address=no,")
}

useEffect(()=>{
if(project)
{


    addWorkingStone( app, "Templates","app" )   
  

  
  getTemplates()
}
},[project])
 return (
    <div className="App w-full"> 
 
          <div className='block w-full text-black justify-start overflow-x-auto overflow-y-hidden'>
            <div  className='inline-flex  justify-start relative '>            {
              schemas.map((t,i)=><div key={i} onClick={()=>setSchema(t)} className={"m-2 h-16 w-24  shadow-lg border border-zinc-300 rounded-lg p-2 text-xs font-bold flex flex-col items-center justify-center " +(schema?.content_id===t.content_id  &&  schema?.layout===t.layout   ? "bg-zinc-300 sticky left-0 right-0 ":"bg-white")}>
              <FontAwesomeIcon icon={t.content?.icone ? fa[t.content.icone]: fa.faQuestionCircle} />   {t.title}{t.content_id>0  &&  "s"}
              </div>)
            }
            </div>

            </div>
            <div  className='w-full my-2 flex flex-wrap py-2 justify-between rounded-xl ' >
   {  templates.filter(t =>(!schema?.content_id || t.schema_id===schema.content_id  ) &&   (!schema?.layout || t.layout===schema.layout  ) &&   ( !searchValue || searchValue==="" || t.title?.toLowerCase().indexOf( searchValue.toLowerCase()   )>-1  ) ).map((template,i)=>
    <div key={i} className='m-2  flex flex-col items-center my-1   rounded-xl bg-black bg-opacity-40 overflow-hidden'    >
    <TemplateDisplay template={template } deleteTemplate={deleteTemplate}   duplicateTemplate={duplicateTemplate}  isEditable={true} safeDisplay={true} showLayout={false} />

   </div>
   ) 
     }
     </div>   
    
      <div onClick={newTemplate} className=' text-white mx-2 bg-orange-700  p-1 px-3 rounded-full mb-6' ><FontAwesomeIcon icon={fa.faPlusCircle} /> Créer Template {  schema?.content_id>0 ? schema.title :""  } {  schema?.layout ?? ""  } </div> 
      
    </div>
  );
}

export default AppTemplates;