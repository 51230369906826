import React, { useEffect,  useState, useContext } from "react";
import {  useNavigate, useParams } from "react-router-dom";


import "../../../../App.css";
import { MfctContext } from "../../../../context";

import ContentEdition from "../../../../components/edition/ContentEdition";
import { data } from "../../../../service/data";
import Header1 from "../../../../components/Header1";
import { addWorkingStone } from "../../../../service/milestone";

function SetContent({is_schema }) {
  const {  getSchema, getTemplate ,project} = useContext(MfctContext);
  const { content_id, app_id, schema_id, template_id } = useParams();
  const [title, setTitle] = useState();





  useEffect(() => {

if(project) console.log( "Project Loaded" ,  project.timestamp,project ) 

console.log(" SetVContent  " + content_id )

    const loadTitle = async () => {
      var _title = "";
      var _schema_id = schema_id;
      var _template_id = template_id;
      var _content;
      if (!content_id) {
        _title = " Nouveau ";
      } else {
        _title = " Edition ";
        _content = await data.getContent(content_id);
        if (_content) {
          _schema_id = _content.schema_id;
          _template_id = _content.template_id;
        }
      }

      if ((_content && _content.is_schema) || is_schema) {
        _title += "Schéma";
      } else if (_schema_id) {
        var _schema = await getSchema(_schema_id);
        _title += _schema.title;
      } else if (_template_id) {
        var _template = await getTemplate(_template_id);
        _title += " Contenu de " + _template?.title;
      } else {
        _title += " Contenu ";
      }

      setTitle(_title);
      if(_content?.content_id)
        {
         await   addWorkingStone( _content,"Edition" )   
  
        }

    };

    loadTitle();
  }, [content_id,project, app_id, schema_id, template_id]);

  const navigate = useNavigate();
  const save = async () => {}
  const cancel = async () => {}
  const saveContent = async (_content) => {
    if (!content_id)
      navigate(`/content/${_content.content_id}`, { replace: true });
  };

  const cancelContent = async () => {
    if (!content_id) navigate(-1);
  };

  return (
    <div className="App  text-zinc-600 text-sm mb-10">
      <Header1 title={title} >


      <div className=" w-full flex justify-end">
              <button
                type="button"
                className="text-xs appbutton border border-1 p-2 m-1 first-line:rounded"
                onClick={cancel}
              >
                Annuler
              </button>
              <button
                type="button"
                className="text-xs appbutton  border border-1 p-2 m-1 first-line:rounded"
                onClick={save}
                id="upload"
              >
                Enregistrer
              </button>
            </div>
      </Header1>


      <div className="z-1 w-full mt-4 bg-white p-4">
      <ContentEdition
          app_id={app_id}
          cancelContent={cancelContent}
          content_id={content_id}
          saveContent={saveContent}
          template_id={template_id}
          schema_id={schema_id}
          is_schema={is_schema}
          invprop={true}
        />
      </div>
    </div>
  );
}

export default SetContent;
