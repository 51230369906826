import { useRef, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as fa from '@fortawesome/free-solid-svg-icons';
import useDynamicTextColor from "../../tools/useDynamicTextColor";








const InputMultiSelect = ({ id, label, onChange, value, className, values }) => {

    const elementRef = useRef(null);
    const textColor = useDynamicTextColor(elementRef);
    const [visibleValues, setVisibleValues] = useState();


    const removeValue = (v) => {

        var _values = (value ?? []).filter(val => v !== val)

        onChange(_values)
    }

    const addValue = (v) => {

        var _values = value ?? []

        _values.push(v)

        onChange(_values)
    }

    if (values.length === 0) return <></>

    return (
        <>
            <div ref={elementRef}  style={{ color: textColor } } className=' px-2 text-xs border-t w-full border-dotted border-zinc-500 py-2 text-start flex flex-wrap items-center'>
                <div className='p-2 font-bold '>{label}</div>
                {values.filter(v => value && value.find(r => v.key === r)).map(v => (
                    <span key={v.key} className='mb-1 mr-2 inline-block px-2 py-1 border-2 rounded-full bg-blue-500 ' onClick={() => removeValue(v.key)}>
                        {v.label}
                    </span>
                ))}
                <FontAwesomeIcon
                    icon={visibleValues ? fa.faChevronLeft : fa.faChevronRight}
                    onClick={() => setVisibleValues(!visibleValues)}
                    title={"Gerer " + label}
                    className="tooltip  text-xs p-1 w-2 h-2  border border-gray-500 rounded-full mx-1  "
                />
                {visibleValues &&
                    <>
                        {values.filter(v => !value || !value.find(r => v.key === r)).map(v => (
                            <span key={v.key} className='mb-1 mr-2 inline-block px-2 py-1 border-2 rounded-xl border-gray-500 ' onClick={() => addValue(v.key)}>
                                {v.label}
                            </span>
                        ))}
                    </>
                }
            </div>
        </>
    )
}
export default InputMultiSelect