import React, { useEffect, useMemo, useState, useContext } from "react";

import { Link, useNavigate } from "react-router-dom";

import * as fa from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "../../../../App.css";
import { MfctContext } from "../../../../context";
import Content from "../../../../components/Content";
import InputText from "../../../../components/inputs/InputText";
import InputBoolean from "../../../../components/inputs/InputBoolean";
import InputIcon from "../../../../components/inputs/InputIcon";
import { getFilterRecord } from "../../../../service/localdata";
import { data } from "../../../../service/data";
import InputMultiSelect from "../../../../components/inputs/InputMultiSelect";
import ShowComponentAddTools from "../template/sideBars/ShowComponentAddTools";
import { ModalPositions, showModal } from "../../../../components/modal";
import InputData from "../../../../components/inputs/InputData";
import { addWorkingStone } from "../../../../service/milestone";
import InputSchema from "../../../../components/inputs/InputSchema";
import InputSelectPropSchema from "../../../../components/inputs/InputSelectPropSchema";
import InputSelectPropSchemaDirect from "../../../../components/inputs/InputSelectPropSchemaDirect";
import { getDataSources } from "../../../../tools/tpl";
import VarContent from "../../../../components/VarContent";

function AppPages({ app, searchValue }) {
  const navigate = useNavigate();

  const { project, getProject, setAuthUser, authUser, getGlobalData } =
    useContext(MfctContext);
  var pagestype = [

  ];
  const [pages, setPages] = useState([]);
  const [infoPage, setInfoPage] = useState();

  const [roles, setRoles] = useState([])
  const getRoles = async () => {

    var _roles = await project.api("/get_myroles/:app_id", { args: { app_id: app.app_id } })
    console.log(_roles)
    setRoles(_roles);
  }




  const newPage = async () => {
    await data.init("app", app.app_id, false, project)
    var page = await data.newPage({ app_id: app.app_id })
    await data.close();
    await getPages()
    window.open(`/app/${app.app_id}/page/${page.page_id}`, "viewpage" + page.page_id, "menubar=no,toolbar=no,location=no,address=no,")

  };

  const getPages = async () => {
    await getRoles()
    await getGlobalData()
    // var _pages = await project.api("/get_mypages/:app_id", {
    //   args: { app_id: app.app_id },
    //  });
    var _pages = await getFilterRecord((page) => page.app_id === app.app_id, "page")
    console.log(_pages);
    pagestype.forEach((page) => {
      if (!_pages.find((p) => page.type === p.type)) _pages.push(page);
    });
    setPages(_pages);
  };

  useEffect(() => {

    addWorkingStone( app, "Pages","app" )   
  
  },[]);
  
  const InfoPage = ({ _page }) => {

    const [page, setPage] = useState(_page)


    const [dataSources,setDataSources]=useState()

    useEffect(()=>{

const init= async () => {

var r= await getDataSources(page)

setDataSources(r)

}
init() 

    },[])
    
    



    const savePage = async () => {
     await data.save_page( page)
    //  await project.api("/set_page", { body: page });

     
      await getPages()
       
    };





    return <> <div className=" flex w-full flex-row items-center ">
      <div className=" flex    gap-2  flex-col grow">
        <InputText
          label={"Titre"}
          className={" my-2 w-full"}
          value={page.title}
          onChange={(value) => {
            setPage({ ...page, title: value });
          }}
        />

        <InputText
          label={"Type"}
          className={" my-2 w-full"}
          value={page.type}
          onChange={(value) => {
            setPage({ ...page, type: value });
          }}
        />
      </div>

    </div>

      <div className="w-full flex    gap-2  flex-col grow">
        <InputText
          label={"Label"}
          className={" my-2 w-full"}
          value={page.content.label}
          onChange={(value) => {
            setPage({
              ...page,
              content: { ...page.content, label: value },
            });
          }}
        />
        <InputText
          label={"Slug"}
          className={" my-2 w-full"}
          value={page.slug}
          onChange={(value) => {
            setPage({ ...page, slug: value });
          }}
        />
        { 
page.slug && page.slug.split("/").filter(d=>d.startsWith(":")).map((param=>
  <div className="flex flex-col w-full">
      <div key={param} className="w-full flex">
<InputSchema  label={  param }   value={   page.content.params && page.content.params[param]?.schema    }  onChange={(schema)=> {
var params =  page.content.params ?? {}
   params[param] = {schema}
   setPage({ ...page, content: { ...page.content , params  } });

}   }  context={{app_id : app.app_id}}  />
{
page.content.params && page.content.params[param]  && 
<InputSelectPropSchemaDirect  value={ page.content.params[param].prop }  schema={ page.content.params[param].schema}    onChange={(prop)=>{
var params =  page.content.params ?? {}
params[param].prop =prop
setPage({ ...page, content: { ...page.content , params  } });
}
}  />

}
</div>
{ 
  dataSources?.filter(dt=>dt.schema_id === page.content.params[param].schema.content_id ).map((dt,i)=> <div className="w-full flex flex-row justify-between items-center p-2 border-b"  key={i} >
    <span className="text-xs">{dt.schema_label  } - {dt.place} {dt.i}  [{dt.title}]</span><InputBoolean   className={"border-0"}
value={ !! ( page.content.params[param].data_labels &&  page.content.params[param].data_labels.find(ds =>  dt.schema_label===ds.schema_label && dt.place===ds.place && dt.i === ds.i) ) }  

onChange={(value)=> {
  var params = {...page.content.params ?? {}}
  params[param].data_labels =params[param].data_labels ?? []
  
if(value){
  params[param].data_labels.push(dt)
}
else {
  params[param].data_labels = params[param].data_labels.filter(ds =>  dt.schema_label!==ds.schema_label || dt.place!==ds.place || dt.i !== ds.i )
}
setPage({ ...page, content: { ...page.content , params  } });

}}

  /></div>   )
}



  </div>

))



        }
        <InputBoolean
          labelTrue={"Authentification requise"}
          labelFalse={"Authentification non requise"}
          className={" my-2 w-full text-black"}
          value={page.is_auth}
          onChange={(value) => {
            setPage({ ...page, is_auth: value });
          }}
        />
        <InputMultiSelect
          label={'Roles'}
          value={page.content.roles}
          onChange={(roles) => {
            setPage({
              ...page,
              content: { ...page.content, roles },
            });

          }}
          values={roles.map(role => ({ key: role.role_id, label: role.content.title ?? 'role ' + role.role_id }))}
        />
        <InputIcon
          label={"Icone"}
          className={" my-2 "}
          value={page.content.icone ?? "faQuestion"}
          onChange={(value) => {
            setPage({
              ...page,
              content: { ...page.content, icone: value },
            });
          }}
        />
                                        <InputData
                                id={"_event_"}
                  className={"w-full"}
                  prop={{ name: "Event Action", type_nombre: "I", define: true,define_open:true  }}
                  schema={{ content_id: 444 }}
                  value={page.content.actions ?? []}
                  onChange={(actions) =>  setPage({
                    ...page,
                    content: { ...page.content, actions },
                  })}
                />
      </div>
      <div className="p-2 m-1 bg-black text-right text-white appbutton" onClick={savePage}> Enregistrer </div>
    </>

  }


  const deletePage = async (page_id) => {
    if (window.confirm("Are you sure you want to to delete this page?")) {
      await project.api("/delete_page/:page_id", {
        args: { page_id },
      });
      await getPages();
    }
  };
  const duplicate = async (page_id) => {
    var _page = await project.api("/page/:app_id/duplicate/:page_id", {
      args: { app_id: app.app_id, page_id },
    });
    if (_page) navigate(`/app/${app.app_id}/page/${_page.page_id}`);
  };

  useEffect(() => {
    if (project) {
      getPages();
    }
  }, [project]);
  return (
    <>
      {" "}
      <div className="flex flex-row w-full py-2 bg-white px-4 rounded-xl text-black justify-between items-center border-b border-zinc-700 pb-2">
        <span>{pages.filter((p) => !p.hastoCreate).length} Pages</span>

        <div className="  mx-2 p-1 px-2 shadow-xl rounded-full " onClick={newPage}>
          Créer <FontAwesomeIcon icon={fa.faPlusCircle} />
        </div>

      </div>
      <div className=" py-4  rounded-xl text-black w-full flex flex-col   text-sm md:grid md:grid-cols-2 xl:grid-cols-4  gap-3  ">
        {pages &&
          pages.filter(page => (!searchValue || searchValue === "" || page.title?.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)).map((page, i) => (
            <div
              className=" border-b rounded-xl bg-zinc-100 w-full  text-black flex flex-col  justify-between items-start p-0  "
              key={i}
            >
              <div className="  font-extrabold p-2"> {page.title}</div>

              <div className="w-full hx-40 overflow-scroll ">

                <Link to={`/app/${app.app_id}/page/${page.page_id}`} target={"viewpage" + page.page_id}   > <img
                  src={
                    process.env.REACT_APP_MFTC_HOST_FILES +
                    "apps/" +
                    app.domain +
                    "/page_" +
                    page.page_id +
                    "_thumb.png"
                  }
                  className="w-full appbutton"
                  alt="thumb"
                /></Link>
              </div>
              <div className="w-full flex flex-col items-start">
                <div className="text-left text-sm">{page.description}</div>
              </div>
              {infoPage && page.page_id === infoPage &&
                <div className="p-2 w-full"><InfoPage _page={page} /></div>


              }
              <div className="w-full flex flex-row items-center  border-t border-zinc-200  justify-center pt-2 ">
                {page.hastoCreate && (
                  <>
                    <FontAwesomeIcon
                      icon={fa.faWarning}
                      className="text-red-600"
                    />

                    <div className=" bg-orange-500 text-white mx-2 p-1 px-2 shadow-xl rounded-full " onClick={newPage}>
                      Créer <FontAwesomeIcon icon={fa.faPlusCircle} />
                    </div>

                  </>
                )}



                {page.page_id && (
                  <>
                    <div className=" text-yellow-500  mx-2 p-1 px-2 rounded-full flex flex-col text-xs appbutton"    onClick={() => setInfoPage((v) => v ? null : page.page_id)}>
                      {" "}
                      <FontAwesomeIcon
                  
                        icon={fa.faEdit}
                     
                      />
                      Infos
                    </div>
                    <Link to={`/app/${app.app_id}/page/${page.page_id}`} target={"editpage" + page.page_id}>
                      <div className=" text-green-500  mx-2 p-1 px-2  flex flex-col rounded-full text-xs appbutton">
                        <FontAwesomeIcon icon={fa.faLayerGroup} />
                        Mise en page{" "}
                      </div>
                    </Link>
                    <a href={

                      "/domain/" + app.domain + "/"
                      + (page.slug ? page.slug :page.page_id)


                    } target="_blank" rel="noreferrer"
                    > <div className=" text-orange-500  mx-2 p-1 px-2  flex flex-col rounded-full text-xs appbutton">
                        <FontAwesomeIcon icon={fa.faEye} />
                        Aperçu{" "}
                      </div></a>
                    <div className=" text-blue-500  mx-2 p-1 px-2 rounded-full flex flex-col text-xs appbutton"       onClick={() => duplicate(page.page_id)}>
                      {" "}
                      <FontAwesomeIcon
                        icon={fa.faPaste}
                  
                      />
                      Dupliquer
                    </div>
                    <div className=" text-red-500  mx-2 p-1 px-2 rounded-full flex flex-col text-xs appbutton"    onClick={() => deletePage(page.page_id)}>
                      {" "}
                      <FontAwesomeIcon
                        icon={fa.faDeleteLeft}
                     
                      />
                      Supprimer

                    </div>
                  </>
                )}
              </div>
            </div>
          ))}
      </div>
    </>
  );
}

export default AppPages;
