import React, { useRef, useState, useEffect } from "react";
import CodeEditor from '@uiw/react-textarea-code-editor';
import { getSuggestionsFree } from "../../service/codeSuggestion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";


const InputTextCodeEditor = ({id,label,onChange,value,className,bg="inherit",infos,language,context}) => {

  const [showBox, setShowBox] = useState(false);
  const [boxPosition, setBoxPosition] = useState({ top: 0, left: 0 });
  const editorRef = useRef(null);
  const objRef = useRef(null);
  const [cursorPosition, setCursorPosition] = useState(null);
  const [currentWord, setCurrentWord] = useState('');






useEffect(() => {
    // Fonction pour gérer les clics en dehors du composant
    const handleClickOutside = (event) => {
        if (objRef.current && !objRef.current.contains(event.target)) {
          setShowBox(false);
        }
    };

    // Ajouter l'écouteur d'événement lorsque le menu est ouvert
    if (showBox) {
        document.addEventListener('mousedown', handleClickOutside);
    }

    // Nettoyer l'écouteur d'événement
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, [showBox]);
const [suggestions, setSuggestions] = useState([]);
  // Fonction pour gérer les changements de texte
  const handleChange =async  (event) => {
    updateBoxPosition();
    const newValue = event.target.value;
    const cursorPos = event.target.selectionStart;
    setCursorPosition(event.target.selectionStart);
     // Extraire le mot actuel
     const textBeforeCursor = newValue.slice(0, cursorPos);

     const words = textBeforeCursor.split(/[^a-zA-Z0-9_.]+/);
     const lastWord = words[words.length - 1];
     const alphanumericWord = lastWord.replace(/[^a-zA-Z0-9_.]/g, '');
     setCurrentWord(alphanumericWord);
     getSuggestionsFree(alphanumericWord,context,setSuggestions);
     onChange(newValue);
  };

  // Fonction pour gérer le focus ou le clic
  const handleFocus = (event) => {
    setShowBox(true);
    updateBoxPosition();
  };

  // Fonction pour mettre à jour la position de la boîte
  const updateBoxPosition = () => {
    if (editorRef.current && cursorPosition !== null) {
      const editorRect = editorRef.current.getBoundingClientRect();
      const textBeforeCursor = value.slice(0, cursorPosition);
      const lines = textBeforeCursor.split('\n');
      const currentLine = lines.length;
      const currentColumn = lines[lines.length - 1].length;

      // Calculer la position du curseur dans l'éditeur
      const lineHeight = 22; // Ajustez cette valeur selon la hauteur de ligne de votre éditeur
      const charWidth = 6; // Ajustez cette valeur selon la largeur moyenne d'un caractère

      setBoxPosition({
        top:30+ 1*(currentLine * lineHeight),
        left: 15+1*  (currentColumn * charWidth),
      });
    }
  };

  // Nouvelle fonction pour gérer le clic sur une suggestion
  const handleSuggestionClick = (suggestion) => {
    if (editorRef.current && cursorPosition !== null) {
      const textBeforeCursor = value.slice(0, cursorPosition);
      const wordStartPosition = textBeforeCursor.lastIndexOf(currentWord);
      
      const newValue = value.slice(0, wordStartPosition) + suggestion + value.slice(cursorPosition);

      onChange(newValue);
      setShowBox(false);
    }
  };

  return (
    <>
    <div className={"relative bg-inherit " +className } ref={objRef} >
     <div htmlFor={ "input_"+label } className={"text-left  text-gray-500 " +bg }>{label}</div>
     <div
      
       
        onChange={handleChange}
       
      className="relative"
      >
    <CodeEditor
   ref={editorRef}
      value={value}
      className="w-full rounded-md relative"
      language={language}
      onFocus={handleFocus}
      placeholder="Please enter JS code."
      onChange={(evn) =>{
        handleChange(evn);

        
     
      }}
      padding={15}
      style={{
        backgroundColor: "#050525",color :"white",caretColor: "white",
        fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
      }}
    />
    </div>
    {showBox && currentWord && suggestions.length>0 && (
        <div 
          style={{
            position: "absolute",
            top: `${boxPosition.top}px`,
            left: `${boxPosition.left}px`,
            backgroundColor: "#050525",
            border: "1px solid #A5A5B5",
            color:"white",
            padding: "10px",
            zIndex: 1000
          }}
          className="flex flex-col p-2 justify-start items-start"
        >
      {
        suggestions.map((suggestion,i)=>(
          <div className="w-full gap-10 text-left flex flex-row justify-between text-sm cursor-pointer hover:bg-blue-700  " onClick={() => handleSuggestionClick(suggestion.text)}>{suggestion.text} <FontAwesomeIcon icon={faCheck} /></div>
        ))
      }


        </div>
      )}
    {infos  &&  <div className="italic w-full text-xs p-1 text-left  whitespace-pre-line">{infos}</div>}
    </div>
    
</> 
)
}
export default InputTextCodeEditor
