import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useRef, useState } from "react"
import * as fa from "@fortawesome/free-solid-svg-icons";
import { icon } from "@fortawesome/fontawesome-svg-core";
import useDynamicTextColor from "../../tools/useDynamicTextColor";


const ModalSelect = ({ label, onChange, value, className, options,fieldSearch,fieldId, callClose,OptionRender}) => {

    const elementRef = useRef(null);
    const {textColor,backgroundColor } = useDynamicTextColor(elementRef);

    const [searchText, setSearchText] = useState()

    const textRef = useRef()
useEffect(()=>{

    textRef?.current.focus()

},[])

    return (
        <>

                    <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-80 flex flex-col justify-center items-center  " onClick={ callClose} >
                    <FontAwesomeIcon icon={fa.faClose} className="-ml-8 z-10 text-2xl text-white absolute right-5 top-5" onClick={ callClose} />
                    </div>
                    <div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center z-50 " >

                    
                        {label}
                        <div   className="flex flex-row w-9/12 p-2 items-center justify-center">
                            <FontAwesomeIcon icon={fa.faSearch} className="-mr-8 z-10  " />
                            <input type='text' ref={textRef} onChange={(e) => setSearchText(e.target.value)} className="text-gray-500 w-9/12 p-2 m-2 rounded-xl pl-10" placeholder={"Choisir " +label}
                            />
                            <FontAwesomeIcon icon={fa.faClose} className="-ml-8 z-10 " onClick={() => { textRef.current.value = ''; setSearchText() }} />

                        </div>
                        <div style={{minHeight:"200px",maxHeight:"70vh" , width:"75vw"}}  className=" bg-zinc-950 rounded-xl p-2 overflow-y-scroll  " >
                        <div className="w-full  flex flex-wrap justify-center ">
                            { [ ...(value? [value]:[]) ,...options.filter(option => {
                                // console.log(icon, fa[icon], typeof fa[icon])
                                if(value && value[fieldId] && value[fieldId]===option[fieldId]) return false
                                return  !searchText || option[fieldSearch].toUpperCase().includes(searchText.toUpperCase())
                            })].map((option, i) => <div  className={ className + (value && value[fieldId] && value[fieldId]===option[fieldId] ? "inline border border-blue-700 hover:border-red-600 hover:bg-red-600 ":"border border-transparent appbutton"     )}  key={i} onClick={() => { 
                                onChange((value && value[fieldId] && value[fieldId]===option[fieldId]) ? null: option); callClose() }} >
                           <OptionRender value={option} /> </div>)}

                        </div>
                        </div>
                    </div> </>
    )
}
export default ModalSelect