import { data } from "./data"
import { getAuthUser } from "./mftc-api"


var LoadedStone 

const setLoadedStone=(loadedStone)=> {

  LoadedStone = loadedStone

}

const forgetRessourceStone= async(Ressource )=>
{

if(!window.confirm(" Oublier " + Ressource.title +" ?")) return 

  var currentStone = localStorage.getItem("currentStone")
if(!currentStone) return
  if(!LoadedStone)  {
    LoadedStone =  await data.getContent(currentStone )
  //  window.alert("Loading LoadedStone")
  if(!LoadedStone) return
  }
var field="content_id";

  if( !Ressource[field]  ) field =Ressource.entity_name +"_id"



  LoadedStone.content.WorkingStone =  LoadedStone.content.WorkingStone.map( w=>{

if(w.Ressource && w.Ressource[field]===Ressource[field])  {
  w._Link =  w.Link
  delete w.Link
} 

    return w
  }  )

  LoadedStone = await data.save_content( LoadedStone   )

}

const addWorkingStone =async (content,Action="Open",entity_name=null)=>{

var currentStone = localStorage.getItem("currentStone")
if(!currentStone) return
var authUser = getAuthUser()
if(!authUser) return
var Link = window.location.pathname 
var Ressource = {}

if( content.content_id  ) {
  Ressource = {content_id:content.content_id,schema_id:content.schema_id,title: content.title,schema :content.schema_id }
}
else if(entity_name)
{
  Ressource = {[entity_name+"_id"   ]:content[entity_name+"_id"   ],entity_name,title:content.title ?? content.name}

}
else 
  {
    Ressource = {}
  
  }
var  action = "add"
var index
var workingStone ={  Date:Date.now() ,User:"user_"+authUser.user_id  ,Link, Ressource , Action ,Log :[Date.now() ]  }
if(!LoadedStone)  {
  LoadedStone =  await data.getContent(currentStone )
//  window.alert("Loading LoadedStone")
if(!LoadedStone) return
}
  
  
var WorkingStone = LoadedStone.content.WorkingStone;
var  _index = WorkingStone.findIndex(w=> w.User ==="user_"+authUser.user_id  &&   Action === w.Action  && JSON.stringify(  w.Ressource) === JSON.stringify( Ressource) )

if(_index > -1){

 action=undefined;
 index=_index   
 workingStone = WorkingStone[_index];
 if( workingStone.Date>Date.now()- 1000*60) {
  //   window.alert("Pas besoin!!")
  return    ///  Pas necessaire de ressauvagrder après 1mn
 }workingStone.Date=Date.now()
 workingStone.Log.push(Date.now())

workingStone.Ressource.title =content.title
 LoadedStone.content.WorkingStone[index] = workingStone  
}
else{

 LoadedStone.content.WorkingStone.push(workingStone)
}


//window.alert(JSON.stringify({currentStone, W:[{name:"WorkingStone"  ,value:workingStone,  action,index   }]}))

LoadedStone = await data.update_content( currentStone, [{name:"WorkingStone"  ,value:workingStone,   action,index    }],false ,false,false  )

  }




export { addWorkingStone,setLoadedStone,forgetRessourceStone}