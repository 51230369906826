import React, { useEffect, useMemo, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import { data } from "../../../../../service/data";

import { FlatObject, _GetSetTers, contentConvert, getStructure } from "../../../../../tools/tpl";
import VarContent from "../../../../../components/VarContent";
import TemplateX from "../../../../../components/templates/TemplateX";
import UserThumb from "../../../../../components/thumbs/UserThumb";
import InputSelectToggle from "../../../../../components/inputs/InputSelectToggle";
import { metaDefaultValues } from "../../../../../tools/meta";

const ShowTemplateProps = React.memo(
  ({
    setIsMod,
    isMod,
    template,
    updateTemplateStructure,
    GetSetTersProps,
    selected,
    templates,
    schemas,
    globalSchema,
    content,
    selectTpl,
    addAfter,
    selectingChoose, 
  }) => {

const sActions = [{
   label:"Modifier"
   
},
{ label: "Supprimer"},
{label :"Enregistrer",
   layout:"input"
},
{label :"Annuler",
      layout:"input"
},

]

const [searchText,setSearchText]=useState()

    const [_templates, setTemplates] = useState([]);
    const [showSubProps,setShowSubProps ] = useState({});
    const [showTplProps,setShowTplProps ] = useState({});
    const [showAllTpls,setShowAllTpls ] = useState();
    const [showAllElts,setShowAllElts ] = useState();
    const [showAllStrs,setShowAllStrs ] = useState();
const [_actions,setActions] = useState([])

    const [showInput,setShowInput  ] = useState(template.layout==="input" ? "input":"view");
    const getTemplates = async () => {
      var __templates = await data.getTemplates();
      __templates.sort((a, b) =>{

         if(a.layout!== b.layout)
         { 
            if(a.layout ==="input" || a.layout ==="select" )return 1
            if(b.layout ==="input" || b.layout ==="select")return -1
         }
         return a.title> b.title ? -1 : 1

      })
      setTemplates(__templates);
    };

const meta_templates = (p)=>{

  var ts = _templates.filter((t) => (( !p.is_value && Object.values(t.meta?? {}).length>0 )     && ( showInput==="all" || (  showInput==="view" && t.layout!=="select"  && t.layout!=="input" )  ||  (  showInput ===t.layout)   )
     && (( p.type_nombre === "F" || p.type_nombre === "I") ===    (!!t.is_list) )                
  && (( !t.is_list && !p.invprop) || ( t.is_list && p.invprop))))




  for(let i=0; i<ts.length; i++)
    {
var meta_structure = {
  schema_prop: p.name,
 schema_id : p.schema_id,

template_id :ts[i].template_id,
className:"  ",
tag: "template",
isList: p.type_nombre === "F" || p.type_nombre === "I",
listLength:
  p.type_nombre === "F" || p.type_nombre === "I" ? 2 : null,

children: [],
}
meta_structure = metaDefaultValues(ts[i].template_id,meta_structure,templates,template.template_id)
      ts[i].meta_structure =meta_structure

console.log("META MAP " + i)




    }
  return ts;
}

    const getActions = async () => {

      if(!template.schema_id) return
      var schema = schemas.find(
         (s) => s.content_id + "" === template.schema_id + ""
       );
       var argfilter =(action)=>{
         if(schema.app_id && action.app_id!==schema.app_id) return null
         return action.content?.Arguments?.find(a=>a.schema_id+""===""+template.schema_id) ;


      }
      var __actions = await data.getActions(argfilter  );
      
      setActions(__actions.map(action=>{
        
  var propArgument = argfilter (action)



  var Arguments = {};
  var asynchrone;
  var Imports = [];
var Sortie =null

    action.content.Arguments?.forEach((element) => {
      if(element.name===propArgument.name) element.value="self"
      Arguments[element.name] = element;

    });

   let elementSortie =  action.content.Sortie
if(elementSortie) {
   Sortie = elementSortie
}


    asynchrone = action.content.asynchrone;
    Imports = action.content.import;

   return {Argument :propArgument,
      Sortie,
      isComputed :  action.content.Arguments.length===1 && Sortie,
      action:{
      action_id:action.content_id,
      title: action.title,
  
      Arguments,
      asynchrone,
      Imports,
      Sortie,

    }}
   }) );
    };

    useEffect(() => {
      getTemplates();
      getActions();
    }, []);

    const getPropsBySchema=( schema_id,prefix,_index)=>{

      var schema = schemas.find(
         (s) => s.content_id + "" === schema_id + ""
       );
      
   if(!schema || schema.content.is_value || !schema.content.schema) return []
       var _props = [{
         name: "title",
         schema_id: 42,
         is_value:true,
       },...schema.content.schema].filter(p=>p.schema_id && schemas.find(s=>s.content_id+""===""+p.schema_id) ).map(p=>({...p,
is_value:schemas.find(s=>s.content_id+""===""+p.schema_id).content.is_value

       }));
   
       if (schema.app_id) {
         schemas.forEach((s) => {
           if (s.app_id === schema.app_id) {
            if(!s.content.schema) return;

             let invprops = s.content.schema.filter(
               (p) => p.schema_id === schema_id
             );
             invprops.forEach((p) => {
               if (
                 !_props.find(
                   (pp) =>
                     pp.invprop &&
                     s.title + "s" === pp.invprop.title &&
                     pp.schema_id === s.content_id
                 )
               )
                 _props.push({
                   name: s.title + "s",
                   schema_id: s.content_id,
                   type_nombre: "I",
                   is_value:false,
                   invprop: p,
                 });
             });
           }
         });
       }
   
   return _props.map((p,index)=>({...p,name:(prefix?prefix+".":"")+ p.name,index:(_index?_index+".":""  )+ (1000+ index+"") }))
   }
   
const getPropContent = (p)=>{
   var infos={}
   if( p.type_nombre === "I"  || p.type_nombre === "F") return  infos
   var name = p.name
   console.log("test _get_" + p.name, { get:content["_get_"+ p.name], value:content["_get_"+ p.name]?.do(),content})
   if(content["_get_"+ p.name]) return content["_get_"+ p.name].do()
  if(! content) return infos;
  if( content.self?.content && content.self?.content[name]) {

   if(typeof  content.self?.content[name] !=="object") return content.self?.content[name]

   infos= {...infos,...FlatObject( contentConvert( content.self?.content[name]))}
  }
  if(content[name])   {
   if(typeof  content[name] !=="object") return content[name]
   infos= {...infos,...content[name]}
  }
  return infos



}
const props =   useMemo(() => {


   var sProps= [ ...getPropsBySchema(template.schema_id)]
        
var keys = Object.keys(showSubProps)
keys.forEach(k=>{

   if(showSubProps[k])
      {
         var prop = showSubProps[k]
         sProps =[...sProps,...getPropsBySchema( prop.schema_id,k,prop.index)]
        
      }
})

sProps.sort((a,b)=>{

   return a.index>b.index ? 1 : -1


})
sProps = [ {  name:"self", schema_id : template.schema_id}  ,...sProps]
   return sProps

    },[template,showSubProps])
    const [showProps, setShowProps] = useState({});
    const [showSchemaProps, setShowSchemaProps] = useState(template.schema_id);

const  TemplateInfos =({tpl})=>{ 
   return                   <div className="p-2 w-full flex justify-between items-center">
        <span  className="appbutton p-1 text-xs bg-blue-200 text-black  rounded-md " > #{tpl.layout}</span>
        <span  className="appbutton p-1 text-xs bg-orange-200 text-black  rounded-md " > {tpl.structure.sizes?.join(" ") }</span>
      <span  className="appbutton p-1 text-xs text-blue-800 underline rounded-md " onClick={()=>viewTemplate(tpl.template_id)}> Editer</span>
     <UserThumb
       key={tpl.owner_id}
       className={"text-xs"}
       user_id={tpl.owner_id}
     />
     </div>
   
}
const newAction = async (schema_id) => {
   return 
   //  await data.init("template", "", false, project);
   var schema =  schemas.find(
     (s) => s.content_id + "" === schema_id + ""
   )
     var _template = await data.newTemplate({ title: "  template  " + schema.title,schema_id,app_id: schema.app_id });
    // await data.close();
   //  await getTemplates();
      viewTemplate (_template.template_id)

   };
   const newTemplate = async (schema_id) => {
    //  await data.init("template", "", false, project);
    var schema =  schemas.find(
      (s) => s.content_id + "" === schema_id + ""
    )
      var _template = await data.newTemplate({ title: "  template  " + schema.title,schema_id,app_id: schema.app_id });
     // await data.close();
    //  await getTemplates();
       viewTemplate (_template.template_id)

    };

    const viewTemplate=(template_id)=>{

      window.open(
         "/template/" + template_id,
         "viewtpl" + template_id,
         "menubar=no,toolbar=no,location=no,address=no,"
       );

    }



    /* 
    console.log("ShowTemplateStructure 1 "  )
    const [_structure,setStructure] = useState(getStructure(template.structure,selected))
    
    const update=async (structure)=>{
      setStructure(structure)
       await updateTemplateStructure(structure,selected)
    }
    */
    var schema = schemas.find(
      (s) => s.content_id + "" === template.schema_id + ""
    );




    return (
      <div id={"ShowTemplateProps"} className=" flex-none w-96 overflow-scroll   m-2 flex flex-col items-start p-2 justify-center border-t border-white shadow relative">
        <div className="flex justify-between items-center text-xs w-full p-2 text-white bg-black rounded-xl mb-1 sticky top-0">
          <FontAwesomeIcon icon={fa.faPen}></FontAwesomeIcon>
         Templates
          <FontAwesomeIcon
            icon={fa.faClose}
            onClick={() => setIsMod(!isMod)}
            className="appbutton"
          />
        </div>
        {
            showSchemaProps &&     <div
          className={
            " p-2 w-full text-left flex justify-between border-b border-slate-800"
          }
          //  onClick={()=>{  setShowSchemaProps(s=>!s)}}
        >
     <span className=" font-bold ">{schema.title}</span>
     <InputSelectToggle className={"text-xs "}  value={ showInput } onChange={setShowInput} options={["all","view","input","select"]}    />
        </div>}
        {showSchemaProps &&
          props.map((p, i) => (
            <div className={"p-0 w-full flex-col border-b border-slate-800"} key={i}>
              <div
                className={
                  "flex justify-between p-2 w-full text-left "
                }
        
              >
             <div>       { 
                    <span  className={" mx-1 appbutton px-2 p-1 text-xs  text-black  rounded-md "  +(  !showTplProps[p.name]  ?  "bg-green-200" :"bg-red-200")}  onClick={()=>{ 

                      setShowTplProps(s=> ( {...s, [p.name]:s[p.name]? null:p}))
                    }} >  {  showTplProps[p.name] ? "-" : "+"} </span>
               }
               
               <span className=" font-bold"> {p.name} 
               </span>  

               { !p.is_value && !showSubProps[p.name] &&
                    <span  className=" mx-1 appbutton px-2 p-1 text-xs bg-green-200 text-black  rounded-md "    onClick={()=>{ 

                      setShowSubProps(s=> ( {...s, [p.name]:s[p.name]? null:p}))
                    }} > {">"} </span>
               }
              { !p.is_value && showSubProps[p.name] &&
                    <span  className=" mx-1 appbutton px-2 p-1 text-xs bg-red-200 text-black  rounded-md "    onClick={()=>{ 

                      setShowSubProps(s=> ( {...s, [p.name]:s[p.name]? null:p}))
                    }} > {"<"} </span>
               }
               </div>

             
               {(p.invprop || p.type_nombre==="F" || p.type_nombre==="I") &&
                    <span  className="appbutton p-1 text-xs bg-blue-200 text-black  rounded-md " > #list</span>
               }
      
            {   showTplProps[p.name] &&  <span className="appbutton p-1 text-xs bg-green-800 text-white rounded-md " onClick={()=>newTemplate(p.schema_id )}>Créer   { showInput==="input" ? " Input " :"Template "}  {p.invprop ? "List" :""}  { schemas.find(
      (s) => s.content_id + "" === p.schema_id + ""
    ).title }</span> }
              </div>
          { false &&    <VarContent data={schemas.find(s=>s.content_id===p.schema_id).content}/>}
              {  showTplProps[p.name] && 
                <>
                  <div className="w-full overflow-x-auto ">
                  <VarContent data={getPropContent (p)}/>
                    <div className="  snap-x snap-proximity inline-flex  gap-2 p-2  justify-center items-center">
                  
                      {[..._templates
                        .filter((t) => ((p.schema_id === t.schema_id) || ( t.schema_id===509 && !p.is_value )  )   && ( showInput==="all" || (  showInput==="view" && t.layout!=="select"  && t.layout!=="input" )  ||  (  showInput ===t.layout)   )
                     
                     && (( t.layout!=="list" && !p.invprop) || ( t.layout==="list" && p.invprop))
                     ),
                    ...meta_templates(p)]
                        .map((tpl, i) => (
                          <div
                            key={i}
                            className=" snap-center w-80  overflow-hidden  flex flex-col p-1 borderq-t border-slate-500"
                          >
                            <div
                              draggable={true}
                              style={{ cursor: "grab" }}
                              className="  w-full flex flex-col items-center justify-center   border-slate-400  hover:rotate-2  "
                              onDragStart={(e) => {
                                e.dataTransfer.clearData();
                                e.dataTransfer.setData(
                                  "application/json",
                                  JSON.stringify({ tpl, prop: p })
                                );
                              }}
                            >
                            
                              <TemplateX
                                content={
{ ...content,
...GetSetTersProps( tpl,content["_get_" + p.name] ,content["_set_" + p.name])    
}
                                }
                                template={
                              tpl.meta_structure ??    {
                                  ...tpl.structure,
                                  className:
                                    tpl.structure.className +
                                    "  appbutton  shadow ",
                                }
                              }
                                templates={templates}
                                safeDisplay={true}
                              />
                            </div>
                          {selectingChoose && <div className="w-full flex items-center justify-center" onClick={()=>selectingChoose({ tpl, prop: p })}><FontAwesomeIcon   icon={fa.faHand} className="appbutton bg-yellow-600 rounded-full text-white h-3 w-3 p-1"  /></div> }
                            <TemplateInfos tpl={tpl}/>{tpl.title}
                          </div>
                        ))}
                    </div>
                  </div>


                </>
              }
            </div>
          ))}
          {
            showSchemaProps && 
           <>
          
           <div className={"p-2 w-full flex-col border-b border-slate-800"} >
           <div
             className={
               "flex justify-between p-2 w-full text-left "
             }
     
           >
  
            <span className=" font-bold"> Propriétés Calculées </span>
            <span className="appbutton p-1 text-xs bg-green-800 text-white rounded-md " onClick={()=>newAction(template.schema_id )}>+ Calcul</span>
           </div>
           {_actions.filter(a=>a.isComputed).map((action,i)=>
             <div   key={action.action.content_id}  >   
              {action.Sortie.name}
             <div className="w-full overflow-x-auto ">
            
                    <div className="  snap-x snap-proximity inline-flex  gap-2 p-2  justify-center items-center">
               
                      {_templates
                        .filter((t) => (( action.Sortie.schema_id   === t.schema_id)   &&  t.layout!=="list" &&  t.layout!=="input" )
                     )
                        .map((tpl, i) => (
                          <div
                            key={i}
                            className=" snap-center w-80  overflow-hidden  flex flex-col p-1 borderq-t border-slate-500"
                          >
                            <div
                              draggable={true}
                              style={{ cursor: "grab" }}
                              className="  w-full flex flex-col items-center justify-center   border-slate-400  hover:rotate-2  "
                              onDragStart={(e) => {
                                e.dataTransfer.clearData();
                                e.dataTransfer.setData(
                                  "application/json",
                                  JSON.stringify({ structure:{...tpl.structure ,schema_label:null,text:action.action.title,actions:[
                                    { Event:"onClick",Action:action.action }
                                  ]    } })
                                );
                              }}
                            >
                            
                              <TemplateX
                                content={{
                                ...content,   text_link : action.action.title
                                 
                                }}
                                template={{
                                  ...tpl.structure,
                                  className:
                                    tpl.structure.className +
                                    "  appbutton  shadow ",
                                }}
                                templates={templates}
                                safeDisplay={true}
                              />
                            </div>
                            {selectingChoose && <div className="w-full flex items-center justify-center" onClick={()=>selectingChoose({ structure:{...tpl.structure ,schema_label:null,text:action.action.title,actions:[
                                    { Event:"onClick",Action:action.action }
                                  ]    } })}><FontAwesomeIcon   icon={fa.faHand} className="appbutton bg-yellow-600 rounded-full text-white h-3 w-3 p-1"  /></div> }
                    
                            <TemplateInfos tpl={tpl}/>
                          </div>
                        ))}
                    </div>
                  </div>
             
             </div>

             )


             }
           </div>
           <div className={"p-2 w-full flex-col border-b border-slate-800"} >
            <div
              className={
                "flex justify-between p-2 w-full text-left "
              }
      
            >
   
             <span className=" font-bold"> Actions </span>
             <span className="appbutton p-1 text-xs bg-green-800 text-white rounded-md " onClick={()=>newAction(template.schema_id )}>+ Action</span>
             </div>

            
             {_actions.filter(a=>!a.isComputed).map((action,i)=>
             <div   key={action.action.content_id}  >   
                {action.action.title}
             <div className="w-full overflow-x-auto ">
            
                    <div className="  snap-x snap-proximity inline-flex  gap-2 p-2  justify-center items-center">
                  
                      {_templates
                        .filter((t) => ((523 === t.schema_id)  )
                     )
                        .map((tpl, i) => (
                          <div
                            key={i}
                            className=" snap-center w-80  overflow-hidden  flex flex-col p-1 borderq-t border-slate-500"
                          >
                            <div
                              draggable={true}
                              style={{ cursor: "grab" }}
                              className="  w-full flex flex-col items-center justify-center   border-slate-400  hover:rotate-2  "
                              onDragStart={(e) => {
                                e.dataTransfer.clearData();
                                e.dataTransfer.setData(
                                  "application/json",
                                  JSON.stringify({ structure:{...tpl.structure ,schema_label:null,text:action.action.title,actions:[
                                    { Event:"onClick",Action:action.action }
                                  ]    } })
                                );
                              }}
                            >
                            
                              <TemplateX
                                content={{
                                  ...content,  text_link : action.action.title
                                 
                                }}
                                template={{
                                  ...tpl.structure,
                                  className:
                                    tpl.structure.className +
                                    "  appbutton  shadow ",
                                }}
                                templates={templates}
                                safeDisplay={true}
                              />
                            </div>
                            {selectingChoose && <div className="w-full flex items-center justify-center" onClick={()=>selectingChoose({ structure:{...tpl.structure ,schema_label:null,text:action.action.title,actions:[
                                    { Event:"onClick",Action:action.action }
                                  ]    } })}><FontAwesomeIcon   icon={fa.faHand} className="appbutton bg-yellow-600 rounded-full text-white h-3 w-3 p-1"  /></div> }
                  
                            <TemplateInfos tpl={tpl}/>
                          </div>
                        ))}
                    </div>
                  </div>
             
             </div>

             )


             }

            </div>

</>
          }
                  {
          <div className="w-full  flex flex-col gap-2 p-2  sticky top-0">
                       <div className={"flex justify-between p-2 w-full text-left "   }
           >
             <span  className={" mx-1 appbutton px-2 p-1 text-xs  text-black  rounded-md "  +(  !showAllStrs   ?  "bg-green-200" :"bg-red-200")}  onClick={()=>{ 
                      setShowAllStrs (s=> !s)
                    }} >  {  showAllStrs ? "-" : "+"} </span>
                <span className=" font-bold"> Les structures  </span>
         
           </div>
            { showAllStrs   && 
<>      TODOS  afficher des structures predefinies   </> }
          </div>
        }
                          {
          <div className="w-full  flex flex-col gap-2 p-2  sticky top-0">
                       <div className={"flex justify-between p-2 w-full text-left "   }
           >
             <span  className={" mx-1 appbutton px-2 p-1 text-xs  text-black  rounded-md "  +(  !showAllElts   ?  "bg-green-200" :"bg-red-200")}  onClick={()=>{ 
                      setShowAllElts (s=> !s)
                    }} >  {  showAllElts ? "-" : "+"} </span>
                <span className=" font-bold"> Les élémentaires  </span>
         
           </div>
            { showAllElts   && 
<>      TODOS  afficher des templates basiques texte/image/icone/video/audio/input   </> }
          </div>
        }
        {
          <div className="w-full  flex flex-col gap-2 p-2  sticky top-0">
                       <div
             className={
               "flex justify-between p-2 w-full text-left "
             }
     
           >
  
  { 
                    <span  className={" mx-1 appbutton px-2 p-1 text-xs  text-black  rounded-md "  +(  !showAllTpls   ?  "bg-green-200" :"bg-red-200")}  onClick={()=>{ 

                      setShowAllTpls (s=> !s)
                    }} >  {  showAllTpls ? "-" : "+"} </span>
               } <span className=" font-bold"> Tous les templates </span>
         
           </div>
            { showAllTpls   && 
<>           <input type="text" className=" sticky top-0 p-2 " placeholder="search"   onChange={(e)=>setSearchText( new RegExp( e.target.value,"i"))}/>

            {  _templates
              .filter((t) => (!searchText || searchText.test(t.title+"")) &&   ( !template.schema_id || (!t.schema_id && t.layout!=="bloc" && t.layout!=="header" && t.layout!=="footer")))
              .map((tpl, i) => (
                <div
                  key={i}
                  className=" w-full  overflow-hidden  flex flex-col p-1 borderq-t border-slate-500"
                >
                  <div
                    draggable={true}
                    style={{ cursor: "grab" }}
                    className="   w-full flex flex-col items-center justify-center   border-slate-400  hover:rotate-2  "
                    onDragStart={(e) => {
                      e.dataTransfer.clearData();
                      e.dataTransfer.setData(
                        "application/json",
                        JSON.stringify({ tpl, prop: null,structure : tpl.schema_id ? null :tpl.structure })
                      );
                    }}
                  >
                    <TemplateX
                      content={ content }
                      template={{
                        ...tpl.structure,
                        className:
                          tpl.structure.className + "  appbutton  shadow ",
                      }}
                      templates={templates}
                      safeDisplay={true}
                    />
                  </div>
                  {selectingChoose && <div className="w-full flex items-center justify-center" onClick={()=>selectingChoose({ tpl, prop: null,structure : tpl.schema_id ? null :tpl.structure})}><FontAwesomeIcon   icon={fa.faHand} className="appbutton bg-yellow-600 rounded-full text-white h-3 w-3 p-1"  /></div> }
                  
<TemplateInfos tpl={tpl}/>
                </div>
              ))}</> }
          </div>
        }
      </div>
    );
  }
);

export default ShowTemplateProps;
