import React, { useEffect, useMemo, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

import TemplateX from "../../../../components/templates/TemplateX";
import {
  _GetSetTers,
  activateContent,
  contentConvert,
  createClass,
  setPropInputs,
} from "../../../../tools/tpl";

import "../../../../App.css";

import { setBackground } from "../../../../service/background";
import { retrieveProject, setToken } from "../../../../service/mftc-api";
import {
  AnimatePositions,
  ClosingModes,
  ModalPositions,
  closeModal,
  showModal,
} from "../../../../components/modal";
import VarContent from "../../../../components/VarContent";
import {
  getActions,
  loadActions,
  runAction,
  runActionSync,
} from "../../../../tools/actions";
import { data } from "../../../../service/data";
import { addWorkingStone } from "../../../../service/milestone";
var old_slug;
function Page({ pageDefault }) {
  const { domain, slug } = useParams();
  const params = useParams();

   const project = retrieveProject();

  const [baseContent, setBaseContent] = useState({
    "App.Name": "CDesign",
    "App.Logo": "cdesign",
  });
  const [internalContent, setInternalContent] = useState({});
  const [page, setPage] = useState(pageDefault ?? { domain, slug });

  const [actions, setActions] = useState();
  const [selected, setSelected] = useState();
  const [contents, setContents] = useState([]);

  const setInternal = (key, value) => {
    console.log("SETTING set" + key, { value, internalContent });
    setInternalContent((_internalContent) => ({
      ..._internalContent,
      [key]: value,
    }));
  };

  const initBaseContent = async (page) => {

    var _app = project.app ?? page.app;
    console.log("page", page);

    if (!_app) window.alert("Please !!!");
    if (!_app && domain) {
   _app = await data.getAppByDomain(domain);
      // _app =  await project.api("/get_app_domain/:domain",{args:{domain }})
    }

    const login = async () => {
      var wconnect = window.open(
        process.env.REACT_APP_URL_AUTH,
        "appisyou_auth"
      );

      window.addEventListener(
        "message",
        function (e) {
          // e.data hold the message from child
          console.log(e.data);

          if (e.data.token && e.data.authUser) {
            setToken(e.data.token, e.data.authUser);

            data.setAuthUser(e.data.authUser);
            wconnect.close();
            navigate("/");
          }
        },
        false
      );
    };
    const logout = async () => {
      setToken(null, null);
      data.setAuthUser();
      navigate("/");
    };

    console.log("App ", _app);


var paramsGetters ={}

Object.keys(params).forEach(function (key) {

if( key.split("_id").length===2 )
  {
       
  }
  else
  {

  }

  paramsGetters["_get_param_"+key] = { do:()=> params[key] ,stack:[ "App"  ]  }

})

    var _baseContent = {
      TitrePage: page.title,
      domain: _app.domain,
      "App.Name": _app.name,
      "App.app_id": _app.app_id,
      "App.Logo":
        process.env.REACT_APP_MFTC_SERVER_URL_FILES +
        `profiles/${_app.domain}/user.png`,
      "App.Domain": _app.domain,
      "App.Description": _app.description,
      "App.primary_color": _app.profile.primary_color,
      "App.secondary_color": _app.profile.secondary_color,
      "App.login": () => login().then(() => {}),
      "App.logout": () => logout().then(() => {}),
      "App.goBack": () => navigate(-1),
      ...params,
      ...paramsGetters
    };




    setBaseContent((b) => ({ ...b, ..._baseContent }));


    _app.profile.appColor?.forEach((color,i)=> 
      {
    var index= i+1
        createClass(
          "bg-app-color-" + index,
          ` background-color :${color} ;  `
        );
        createClass(
          "border-app-color-"+index,
          ` border-color :${color} ;   `
        );
        createClass(
          "text-app-color-"+index,
          ` color :${color}  ;  `
        );


      }
    )

    createClass(
      "bg-primary_color",
      ` background-color :${_app.profile.primary_color} ;  `
    );
    createClass(
      "border-primary_color",
      ` border-color :${_app.profile.primary_color} ;   `
    );
    createClass(
      "text-primary_color",
      ` color :${_app.profile.primary_color}  ;  `
    );
    createClass(
      "border-secondary_color",
      ` border-color :${_app.profile.secondary_color} ;   `
    );
    createClass(
      "bg-secondary_color",
      ` background-color :${_app.profile.secondary_color}  ;  `
    );
    createClass(
      "text-secondary_color",
      `color :${_app.profile.secondary_color}  ;  `
    );

    setBackground(_app.profile.primary_color);

    return _baseContent;
  };

  async function initPage(force) {
    console.log("initPage ** " + JSON.stringify( params) );
    if (force || !page.isReady  || (params  &&  JSON.stringify(page.params)!==  JSON.stringify(params)) ) {
      console.log("initPage ** ");
      // retirer  les dataSources deja sourcées 
      var _page = await project.api("/get_page_todisplay", {
        body: { ...(domain ? { domain, slug } : page), refresh: false },
        // cache : "page_" +(slug?? page_id)
      });
      if (!_page || !_page.isReady) {
        //  navigate("/")
        return;
      }

      if (_page.error) {
        navigate(_page.to);

        return;
      }

      old_slug = slug;
      _page.isReady = true;
      _page = { ...page, ..._page };

      var _baseContent = await initBaseContent(_page);
      var loadedContents={};

      const getSubC =(c)=>{
        if(!c ||   (typeof c !=="object")   || !c.content_id || !c.content || loadedContents[c.content_id])return
        loadedContents[c.content_id]=c
       
          for(let key of Object.keys(c.content) )  getSubC(c.content[key])
          
      }

  
      if (_page.contents) {
      _page.contents.forEach(c=> getSubC (c))
        _page.contents =   Object.values(loadedContents)
        console.log(" contentsAll Contents !!!  ",loadedContents);


        
        setContents(   _page.contents );
        
        console.log(" contentsAll Contents !!!  _page.contents = ", _page.contents);
      }

      if ( _page.templates) {

        var allPropsFromTemplates = {};

        _page.templates.forEach((t) => {
          if (t.props_from_templates) {
            for (let template_id of Object.keys(t.props_from_templates)) {
              allPropsFromTemplates[template_id] =
                t.props_from_templates[template_id];
            }
          }
        });
        _page.allPropsFromTemplates = allPropsFromTemplates;
      }


      await data.init(
        "page",
        _page.page_id,
        false,
        project,
        { setContents  },
       null,
        true, loadedContents
      );


      var _actions = []
      var onStartActions =[]
      if (_page.templates) {
       _actions = getActions(
        [_page, _page.app ?? project.app],
        _page.templates,true );

      await loadActions(_actions);
      onStartActions = _actions.filter(
        (action) => action.Event === "onStart"
      );
    }
    _page.params =params
      setPage(_page);
      await activateContent(
        _page,
        _baseContent,
        project,
        setInternal,
        null,
        setContents,
        _page.contents
      );

      if (onStartActions.length>0) {
        setTimeout(async () => {

         
          for (let startAction of onStartActions) {
            if (startAction.asynchrone)
              await runAction(null, startAction, baseContent, setInternal);
            else runActionSync(null, startAction, baseContent, setInternal);
          }
        }, 500);
      }



await  addWorkingStone( _page, "Preview","page" )   

window.dataSource=null

    }
  }




  const getInfos=(template,content_id,place,i)=>{
    console.log("_data_ getInfos ",{ template,  props :page?.allPropsFromTemplates} )

    var infos = { app_id:page?.app_id}
  
  var dataSources= []
  var cParams =  page.content.params
  var content = contents?.find(c=>c.content_id +"" ===""+ content_id) 
  if(content &&   page?.allPropsFromTemplates &&  page?.allPropsFromTemplates[template.template_id])

    Object.keys(content.content).filter(n=>n.startsWith("_data_")).forEach(key=>{
  

   var propname = key.replace("_data_", "");
  var pageParams={ }
if(cParams)

Object.keys(cParams).forEach(param=>{
var paramName = param.split(":")[1]
if(cParams[param].data_labels.find(dt=> dt.schema_label ===propname && dt.place===place && dt.i===i    ))
  {

    pageParams[  cParams[param].prop.name   ] =   params[paramName]
  }


})
  


  var prop =  page?.allPropsFromTemplates[template.template_id].props[propname]
  if(prop)
  dataSources.push({ prop,dataSource_id:  content.content[key].content_id,template_id:template.template_id,pageParams })
  
   })

    return {...infos,dataSources} 
  
  }

  

  const GetSetTers = (template, getSelf, setSelf, content_id,place,i) => {


return _GetSetTers( template,getSelf,setSelf,content_id,contents, page.allPropsFromTemplates, page.schemas  ,getInfos(template,content_id,place,i))

  };

  const selectTpl = () => {};

  const convertContents = useMemo(() => {
    var ccv = contents.map((c) => contentConvert(c));
    console.log("CCV", ccv);
    return ccv;
  }, [contents]);




  useEffect(() => {
  
    initPage(old_slug !== slug);
    return async () => {
      await data.close();
    };
  }, [domain, slug, params]);

  const navigate = useNavigate();

  return (
    <>
      {page && page.isReady && page.templates && (
        <div className="z-1 w-full  flex flex-col items-start  bg-white">
          {parseInt("0" + page.content.header) > 0 && (
            <TemplateX
              template={
                page.templates.find(
                  (t) => t.template_id + "" === "" + page.content.header
                )?.structure ?? {}
              }
              template_id={page.content.header}
              palette={page.content.headerPalette}
              content={{
                ...baseContent,
                headerContent_id: page.content.headerContent_id,
              ...(convertContents.find(
                  (c) =>
                    c.content_id + "" === page.content.headerContent_id + ""
                ) ?? {}),
                ...internalContent,
                ...GetSetTers (page.templates.find(
                  (t) => t.template_id + "" === "" +   page.content.header
                ), null, null,page.content.headerContent_id,"header" ),
                GetSetTers: (template,getSelf,setSelf)=>GetSetTers(template,getSelf,setSelf, page.content.headerContent_id ,"header")
                ,  params
              }}
              navigate={navigate}
              templates={page.templates}
              selectTpl={selectTpl}
              id={"header"}
              selected={selected}
            />
          )}

          {page.content.body.map((body, i) => (
            <div key={i} className=" flex   flex-col w-full">
              {parseInt("0" + body.template_id) > 0 && (
                <TemplateX
                  template={
                    page.templates.find(
                      (t) => t.template_id + "" === "" + body.template_id
                    )?.structure ?? {}
                  }
                  palette={body.palette}
                  content={{
                    ...baseContent,
                    bodycontent_id: body.content_id,
                 ...(convertContents.find(
                      (c) => c.content_id + "" === body.content_id + ""
                    ) ?? {}),
                   
                    ...(body.content ?? {}),
                /*    ...setPropInputs(
                      page.templates.find(
                        (t) => t.template_id === body.template_id
                      ),
                      page.schemas ?? [],
                      page.templates
                    ),
                    */
                    ...internalContent,
                    ...GetSetTers (page.templates.find(
                      (t) => t.template_id + "" === "" +  body.template_id
                    ), null, null,body.content_id,"body",i ),
                    GetSetTers: (template,getSelf,setSelf)=>GetSetTers(template,getSelf,setSelf, body.content_id ,"body",i)
                    ,  params
                  }}
                  navigate={navigate}
                  template_id={body.template_id}
                  templates={page.templates}
                  selectTpl={selectTpl}
                  id={"block" + i}
                  selected={selected}
                />
              )}
            </div>
          ))}

          {parseInt("0" + page.content.footer) > 0 && (
            <TemplateX
              template={
                page.templates.find(
                  (t) => t.template_id + "" === "" + page.content.footer
                )?.structure ?? {}
              }
              palette={page.content.footerPalette}
              navigate={navigate}
              content={{
                ...baseContent,
                footerContent_id: page.content.footerContent_id,
               ...(convertContents.find(
                  (c) =>
                    c.content_id + "" === page.content.footerContent_id + ""
                ) ?? {}),
                
                ...internalContent,
                ...GetSetTers (page.templates.find(
                  (t) => t.template_id + "" === "" + page.content.footer
                ), null, null,page.content.footerContent_id,"footer" ),
                GetSetTers: (template,getSelf,setSelf)=>GetSetTers(template,getSelf,setSelf, page.content.footerContent_id,"footer")
                ,  params
              }}
              templates={page.templates}
              template_id={page.content.footer}
              selectTpl={selectTpl}
              id={"footer"}
              selected={selected}
            />
          )}
        </div>
      )}
    </>
  );
}

export default Page;
