
import { useContext, useEffect } from "react";
import { useState } from "react";


import TemplateX from "../templates/TemplateX";
import { MfctContext } from "../../context";
import { _GetSetTers } from "../../tools/tpl";
import { data } from "../../service/data";
import { getRecord } from "../../service/localdata";
import { retrieveProject } from "../../service/mftc-api";


const ContentTemplate = ({
  content,
  content_id,
  template_id,
  schema,
  schema_id,
  onClick,
  className
}) => {

  const { getTemplates ,getSchemas} = useContext(MfctContext);
 
  const [displayTemplate, setDisplayTemplate] = useState();

const [ value, setValue] = useState(content)
const [ contents, setContents] = useState([])
  var templates = getTemplates();

  var schemas = getSchemas();





  useEffect(() => {



      const load = async () => {
 var _content_id=content_id
        var _schema =schema
var _contents = []
if(!_schema && schema_id)
  {
     _schema = await data.getSchema(schema_id,true);
  }

        if (template_id ) {
    
     
          var _template = await data.getTemplate(template_id,true);
          if(className) _template.structure.className += " "+ className;
          setDisplayTemplate(_template);
          console.log(_template);
        
        }
       if( content?.content_id &&  Object.keys(content).length === 1) {
        _content_id= content.content_id
     
       }
        var _content = schema?.content.is_value || (typeof content !== "object" ) ? content : {...content}

        if(!_schema?.content.is_value)
          {
if( _schema?.content.entity)
{
   
  if(typeof _content === "string"){ 
    _content_id = _content.split("_").pop()
   _content=null
  }


   else 
   {
    //_content_id = _content
   }
  }


        if((!content || !content.content_id || Object.keys(content).length === 1) && _content_id)
          {

console.log(   {_content_id,content, content_id, template_id,schema_id,className,schema} )
if(typeof _content_id === "object"  &&  Object.values(_content_id).length===0) return
if(_schema?.content.entity)
{

 _content = await retrieveProject().api("/get_entity_record",{ body:{entity:_schema.content.entity,id:_content_id,unconverted:true}});
 if (!_content) {
  console.log("UNKNOWN ",{entity:_schema.content.entity,id:_content_id,unconverted:true})
  _content={app_id:_content_id}
 }
 _content = data.entityToContent(_content,_schema.content.entity)
}else
{
            _content = await data.getContent(_content_id,true);
           }
                    
          }
          else if(content && _schema?.content.entity){
            console.log("entityToContent before " +_schema?.content.entity,_content,content)
      
     _content = data.entityToContent(_content,_schema.content.entity)
console.log("entityToContent ",_content)
          }
       
          setContents([_content])
        }

          setValue(_content)

      };

      load();


  }, [content, content_id, template_id,schema_id,className,schema]);

  if(!value) return <></>
  return  <>

      {displayTemplate && 

           <TemplateX
              content={

                { ..._GetSetTers( displayTemplate,null,null,"global",[{content_id:"global",content:{self:value } }, ...contents], displayTemplate.props_from_templates ??{}  , schemas )
                ,GetSetTers:(template, getSelf, setSelf,content_id)=> _GetSetTers( template,getSelf,setSelf,"global",
                   [{content_id:"global",content:{self:value}},...contents],displayTemplate.props_from_templates ??{}  , schemas )}
      
              }
              template={ displayTemplate.structure }
              templates={templates}
              selectTpl={()=> {  if(onClick) onClick(value)}}
              id={"value." +content_id }
              safeDisplay={true}
            />
                
      }

  </>
  ;
    }
export default ContentTemplate;
