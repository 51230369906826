import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";

import VarContent from "../../../../../components/VarContent";
import InputSelect from "../../../../../components/inputs/InputSelect";
import { useEffect,useRef } from "react";
import { getStructure } from "../../../../../tools/tpl";

const ShowLocalContent = ({
  setShow,localContent,structure,
  selectTpl
}) => {
  var   options=["-root"]
  document.getElementById("-root").querySelectorAll("[id]").forEach(element => {
    options.push({value: element.id, label : element.id + " " + element.tagName}); // Affiche l'id de chaque élément
  });

var panel = useRef()

useEffect(()=>{
  scrollIntoView()

},[])


const scrollIntoView = ()=>{
  if(panel.current)
    panel.current.scrollIntoView({ behavior: "smooth", block: "start", inline:"start" });
    
    
}
  return (
    <div id={"ShowLocalContent"} ref={panel} className=" flex-none  w-80  m-2 flex flex-col items-center justify-center border border-white rounded-xl shadow relative">
      <div className="flex justify-between items-center text-xs w-full p-2 text-white bg-black rounded-xl mb-1 sticky top-0">
        <FontAwesomeIcon icon={fa.faBug}></FontAwesomeIcon>
        Content Element Sélectionné
        <FontAwesomeIcon
          icon={fa.faClose}
          onClick={() => setShow((show) => ({ ...show, localContent: !show.localContent }))}
          className="appbutton"
        ></FontAwesomeIcon>
      </div>
<InputSelect  className={"w-full"}   onChange={(value)=> selectTpl(value)}  value={localContent.selected}  options={ options}   />

<div className="w-80 text-xs overflow-scroll p-4 ">
<VarContent data={structure ? getStructure(structure,localContent.selected ):null} className={""} />
  </div>


        <div className="w-80 text-xs overflow-scroll p-4 ">
        
          <VarContent data={localContent} className={""} />
         
       
      </div>
    </div>
  );
};

export default ShowLocalContent;
