
import { useMemo } from "react"
import InputSelect from "./InputSelect"



const InputSelectPropSchemaDirect = ({label,onChange,value,className,schema,placeholder}) => {
  



    const schemaProps = useMemo(()=>{

     

     return [{  name:"content_id" ,schema_id: 732 },
        ...schema.content.schema]
       
       
       
       },[schema])





return (
    <>
    <InputSelect value={value?.name} onChange={ (name)=>{

         onChange(  schemaProps?.find(p=>p.name===name)   )


    }   } options={["",...schemaProps?.map(p=>p.name) ]}   />
</> 
)
}
export default InputSelectPropSchemaDirect