// Library
// Action

import { retrieveProject } from "./mftc-api";

// Schema

var results = [];
const cache = {};

const getSuggestionsSchema = async (varname, schema_id) => {
  if (!cache["schema_" + schema_id]) {
    var props = await retrieveProject().api("/get_props/:schema_id", {
      args: { schema_id },
    });

    cache["schema_" + schema_id] = props;
  }

  return cache["schema_" + schema_id].map((prop) => ({
    ...prop,
    text: varname + "." + prop.name,
  }));
};

// Nouvelle fonction de filtrage
const filterContains = (text, searchText) => {
  return text?.toLowerCase().includes(searchText.toLowerCase());
};

const getSuggestionsFree = async (searchText, context, setSuggestions) => {
  if (!searchText) {
    setSuggestions([]);
    return;
  }

  var preResults = results.filter((r) => filterContains(r.text, searchText));

  if (preResults.length > 10) return preResults;

  var schema_ids = [428, 812, 811];
  // on cherche dans les actions / instructions  / paramètres librairies
  if (!cache["free_" + searchText]) {
    var contents = await retrieveProject().api("/get_content_where", {
      body: {
        where:
          " schema_id IN ( " +
          schema_ids.join(",") +
          " ) AND  title like '%" +
          searchText +
          "%' ",
      },
    });

    for (var content of contents) {
      if (content.schema_id === 812) {
        // On ajoute les paramètres de la librairie
        content.content.parametre?.forEach((parametre) => {
          contents.push({
            ...parametre,
            text: parametre.name,
            fromLibrary: content.content_id,
            content_id: content.content_id + ":" + parametre.name,
          });
        });
      }
    }
    if(context?.currentContent?.schema_id === 428 && context?.currentContent.content.Arguments.length>0){ //

    for(var arg of context?.currentContent.content.Arguments){
        if(filterContains(arg.name, searchText) ){
          contents.push({...arg,text:arg.name,  content_id: context?.currentContent.content_id + ":" + arg.name,});
        }
      let props=  await getSuggestionsSchema(arg.name,arg.schema_id);
     
   for(var prop of props){ 
      console.log(prop);
      if(prop && filterContains(prop.text, searchText) ){
       contents.push({...prop,  content_id: context?.currentContent.content_id + ":" + prop.text});
      }
    } 
        
        [{prefix:"set",suffix:"({...Produit})",source:"front"},{prefix:"post",suffix:"({...Produit})",source:"back"},{prefix:"open",suffix:"(slug)",source:"front"},
          {prefix:"popup",suffix:"(template_id)",source:"front"}
        ].forEach(({prefix,suffix,source})=>{ 
          if(filterContains(prefix+arg.name+suffix, searchText) ){
            contents.push({...arg,text:prefix+arg.name+suffix,  content_id: context?.currentContent.content_id + ":" + prefix+arg.name+suffix,source});
          }

        })

      }
    }
    

    contents.forEach((content) => {
      if (!results.find((r) => r.content_id === content.content_id))
        results.push({...content,text:content.text ??content.title});
    });

    if(context?.currentContent?.schema_id === 811){ // 

    }
  
  var sortedResults = results.filter(r => r.text && filterContains(r.text, searchText));

  sortedResults.sort((a, b) => {
    const aDistance = levenshteinDistance(searchText, a.text);
    const bDistance = levenshteinDistance(searchText, b.text);
    return aDistance - bDistance;
  });

  cache["free_" + searchText] = sortedResults.map((c) => c.content_id);

 
  }

  setSuggestions(cache["free_" + searchText].map((c) =>
    results.find((r) => r.content_id === c)
  ));
};

// Fonction utilitaire pour calculer la distance de Levenshtein
function levenshteinDistance(a, b) {
  if (a.length === 0) return b.length;
  if (b.length === 0) return a.length;

  const matrix = [];

  for (let i = 0; i <= b.length; i++) {
    matrix[i] = [i];
  }

  for (let j = 0; j <= a.length; j++) {
    matrix[0][j] = j;
  }

  for (let i = 1; i <= b.length; i++) {
    for (let j = 1; j <= a.length; j++) {
      if (b.charAt(i - 1) === a.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1,
          matrix[i][j - 1] + 1,
          matrix[i - 1][j] + 1
        );
      }
    }
  }

  return matrix[b.length][a.length];
}
export { getSuggestionsSchema, getSuggestionsFree };
