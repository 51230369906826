import { faPlay } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"

const VarContent = ({data,className,develop=true})=>{


const  [view,setView] =useState({})


  if( typeof data === "function")
    {
        return <>Function    <FontAwesomeIcon icon={faPlay} onClick={()=> {  var v =     data();     window.alert(JSON.stringify(v))}} /> </>
         
    }
    else
    if( data && typeof data === "object"){
   if(develop===false) return <div className="p-2 bg-slate-500 rounded-full"></div>
      if(Array.isArray(data))
      {
        return   <div className={  "flex flex-col items-start justify-start w-full"}>{ data.map(((value,i)=> 
        <div  className={ (className??"")  +" ml-1 pl-1 border-l border-zinc-500 flex flex-row items-center justify-center"} key={i} >
      <div className={"appbutton font-bold m-0 " +    (  view[i]  ? "bg-blue-400":"bg-sgreen-700"  ) }   onClick={ ()=>setView(v=>({...v,["d" + i]: !v["d" + i]}))  } >{ i} : </div>
     { <VarContent data = { value}   develop={view["d"+i] ?? false}  /> }
      </div>))} </div>
      }
    else
    {
        return   <div  className={ "flex flex-col items-start justify-start w-full"}>{  Object.keys(data).map((key=> <div  className={ (className??"") + " ml-1 pl-1 border-l border-zinc-500 flex fex-row items-center justify-start"} key={key} >
      <div className={" font-bold p-2 mt-0  "  +    (  view[key]  ? "bg-blue-400":"bg-sgreen-700"  )  } onClick={ ()=>setView(v=>({...v,[ key]: !v[ key]}))  } >{key} : </div>

     {   <VarContent data = { data[key]}   develop={view[key] ?? false}   /> }
      </div>))} </div>
    }
    
    
    
    }
    return    <>{data}</>
    
    
    }



    export default VarContent