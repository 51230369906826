const { useRef, useEffect } = require("react");

function useWhyDidYouUpdate(name, props) {
    const previousProps = useRef();
  
    useEffect(() => {
      if (previousProps.current) {
        const allKeys = Object.keys({ ...previousProps.current, ...props });
        const changesObj = {};
  
        allKeys.forEach((key) => {
          if (previousProps.current[key] !== props[key]) {
            changesObj[key] = {
              from: previousProps.current[key],
              to: props[key],
            };
          }
        });
  
        if (Object.keys(changesObj).length > 0) {
          console.log(`useWhyDidYouUpdate [${name}] changements :`, changesObj);
        }
      }
  
      previousProps.current = props;
    });
  }
  



  export default useWhyDidYouUpdate