import { useEffect, useRef, useState } from "react";
import useDynamicTextColor from "../../tools/useDynamicTextColor";
import useWhyDidYouUpdate from "../../tools/useWhyDidYouUpdate";



const InputText = ({id,label,onChange,value,className,infos,context,onFocus}) => {


useWhyDidYouUpdate("INputText", {id,label,onChange,value,className,infos,context})

    const elementRef = useRef(null);
    const inputRef = useRef(null);
    const {textColor,backgroundColor } = useDynamicTextColor(elementRef);

const [timer,setTimer] =useState()

const onChangeDelay=(new_value)=>{

    if( timer) clearTimeout(timer);

    setTimer( setTimeout(()=>{    setTimer(); onChange && onChange(new_value)    } ,300    )   )

}


const blur= ()=>{



}

useEffect(()=>{
   

 if(inputRef.current &&  inputRef.current.value!==(value??"")) inputRef.current.value=(value??"")

},[value,id,label])



if(context?.minimized)
    return (
    <>
    <div  ref={elementRef}  style={{ color: textColor } }  className={"relative text-xs" +className } >
    <input ref={inputRef} type="text" id={id ?? "input_"+label} onBlur={blur} onFocus={onFocus}    onClick={onFocus} onChange={(e)=>onChangeDelay(   e.target.value)}   defaultValue={value}    className="block p-1 w-fit   bg-transparent  border-b border-b-gray-400 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />

</div> 

</> 
)


return (
    <>
    <div  ref={elementRef}  style={{ color: textColor } }  className={"relative " +className } >
    <input ref={inputRef} type="text" id={id ?? "input_"+label} onBlur={blur}  onFocus={onFocus}    onClick={onFocus}  onChange={(e)=>onChangeDelay(e.target.value)}   defaultValue={value}    className="block px-2.5 pb-2.5 pt-4 w-full  bg-transparent rounded-lg border border-gray-400 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
    <label htmlFor={ id ?? "input_"+label } onFocus={onFocus} style={{background:backgroundColor}}   className="absolute text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0]   px-2 peer-focus:px-2  peer-focus:bg-white  peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">{label}</label>
    {infos &&  <div className="italic w-full text-xs p-1 text-left whitespace-pre-line">{infos}</div>}
</div> 

</> 
)
}
export default InputText




