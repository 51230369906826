import {
  faArrowCircleDown,
  faArrowCircleUp,
  faArrowUpShortWide,
  faClose,
  faPlusCircle,
  faSignOut,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo } from "react";
import InputSelect from "../inputs/InputSelect";
import * as fa from "@fortawesome/free-solid-svg-icons";
import InputIcon from "../inputs/InputIcon";
import InputBoolean from "../inputs/InputBoolean";
import InputTextArea from "../inputs/InputTextArea";
import { useState } from "react";
import ChooseImage from "../inputs/ChooseImage";
import InputText from "../inputs/InputText";
import TemplateEdition from "../edition/TemplateEdition";
import InputNum from "../inputs/InputNum";
import InputStyle from "../inputs/InputStyle";
import InputData from "../inputs/InputData";
import InputSelectPropSchema from "../inputs/InputSelectPropSchema";
import InputAction from "../inputs/InputAction";
import InputMultiSelect from "../inputs/InputMultiSelect";
import { arrayMerge } from "../../tools/tpl";
import { data } from "../../service/data";
import VarContent from "../VarContent";
import useWhyDidYouUpdate from "../../tools/useWhyDidYouUpdate";
import InputSchema from "../inputs/InputSchema";
import InputTemplate from "../inputs/InputTemplate";
import { metaDefaultValues, reduceMetaDataFunc } from "../../tools/meta";
import SelectDropDown from "../inputs/SelectDropDown";

function TemplateStructure({
  template,
  internals,
  domain,
  content,
  globalSchema,
  schemas,
  setStructure,
  unWrapFunc,
  upSortFunc,
  downSortFunc,
  deleteFunc,
  childBecomeSiblingFunc,
  createSubTemplateFunc,
  unChildFunc,
  templates,
  id,
  selected,
  selectTpl,
  shows,
  app_id,
  schema_id,
  schema_prop,
  key_schemas,
  templateContent,
  updateContent,
  context,
  mainTemplate
}) {

  const [showEditionTemplate, setShowEditionTemplate] = useState();
   
var _key_schemas = key_schemas

const  reduceMetaData =useMemo(()=>{

  return reduceMetaDataFunc(template.template_id,templates);
},[template,templates]);


const [flexboxes,setFlexBoxes] = useState([]);

useEffect(()=>{

const getFlexBoxes = async()=>{
console.log("getFlexBoxes")
const _f = await data.getContents((t)=>t.schema_id===714,true,true  )
     setFlexBoxes(_f)
}
  

getFlexBoxes()


},[])

const notAscendantTemplate = (tpl)=>{
return  !context?.template || tpl.template_id !==context.template.template_id 
  
  }

const currentFlexbox = ()=>{
  
var templateClasses = template.className.split(" ");
var flexbox = flexboxes.find(flx=> { 
    var classes =flx.content.className.split(" ")
    for(let classe of classes){
      if(!classe.trim() ) continue
      if(!templateClasses.includes(classe)) return false
    }
  return true  
  })
if(flexbox) return flexbox.content.className
return ""
}

const setFlexBox = async(_className)=>{

var flexClasses=[]

flexboxes.forEach(flx=> { flexClasses =  [...flexClasses, ...flx.content.className.split(" ") ]  })
var className = template.className;
var parts = className.split(" ");

parts = parts.filter( classe=> classe.trim() && !flexClasses.includes(classe)    )
parts.push (_className)
className = parts.join(" ")  

  setStructure({ ...template, className });
}

  const addChildren = () => {
    var children = ("children" in template && template.children) ?? [];

    children.push({ tag: "div", className: " p-2 ", children: [] });
    setStructure({ ...template, children });
  };

  const wrapDiv = () => {
    setStructure({ tag: "div", className: "", children: [template] });
  };

  const unWrapDiv = (i) => {
    var childWrapped = template.children[i].children;
    var childrenBefore = template.children.filter((c, idx) => idx < i);
    var childrenAfter = template.children.filter((c, idx) => idx > i);
    setStructure({
      ...template,
      children: [...childrenBefore, ...childWrapped, ...childrenAfter],
    });
  };

  const sortChildren = (i, pos) => {
    var i_ = i + pos;
    if (i_ < 0 || i_ >= template.children.length) return;

    var children = ("children" in template && template.children) ?? [];

    children = children.map((c, j) => {
      if (j === i) return children[i_];
      if (j === i_) return children[i];
      return c;
    });
    setStructure({ ...template, children });
  };

  const childOfChildBecomeChild = (i, tpl) => {
    var childrenBefore = template.children.filter((c, idx) => idx <= i);
    var childrenAfter = template.children.filter((c, idx) => idx > i);
    setStructure({
      ...template,
      children: [...childrenBefore, tpl, ...childrenAfter],
    });
  };

  const copy = (tpl) => {
    localStorage.setItem("copyTPL", JSON.stringify(tpl));
  };

  const paste = () => {
    var tpl = localStorage.getItem("copyTPL");
    if (tpl) {
      // localStorage.removeItem("copyTPL");
      setStructure({ ...template, ...JSON.parse(tpl) });
    }
  };
  const mySchema = useMemo(() => {
    return template.schema_id
      ? schemas.find((c) => c.content_id === template.schema_id)
      : null;
  }, [schemas, template]);

  const Popables = useMemo(() => {
    var _result = [];
    if (globalSchema) {
      _result.push({ prop: "Self" });
      // var result= schema.content.schema.filter(c=>true  || !template.schema_id || c.schema_id===template.schema_id).map(c=>{ return { value: c.name,label: `<${ c.name}>` , schema:c }})

      globalSchema.content.schema.forEach((c) => {
        var schema = schemas.find((s) => s.content_id === c.schema_id);
        if (schema) {
          if (!schema.content.is_value) {
            //  console.log("Popables ", c,  schema)

            _result.push({ prop: "<" + c.name + ">" });
          }
        }
      });
    }
    return _result;
  }, [globalSchema]);

  const popableObj = useMemo(() => {
    if (!template.onClick || !globalSchema) return;
    console.log("Popup emplate.onClick", template.onClick);
    if (template.onClick && template.onClick?.split("Popup:").length === 2) {
      var propname = template.onClick.replace("Popup:", "");
      if (propname === "Self") return { schema_id: globalSchema.content_id };
      return globalSchema.content.schema.find(
        (c) => "<" + c.name + ">" === propname
      );
    }
    return;
  }, [template, globalSchema]);

  const unChildren = (i) => {
    childBecomeSiblingFunc(template.children[i]);
  };

  const delChildren = (i) => {
    var children = (("children" in template && template.children) ?? []).filter(
      (c, idx) => idx !== i
    );
    setStructure({ ...template, children: children });
  };

  const bgParams = useMemo(() => {
    if (!template.background) return;

    var parts = template.background.split("[*");
    var prm = [];

    for (var i = 0; i < parts.length - 1; i++) {
      var nameclass = parts[i].split(".").pop();
      prm.push(nameclass);
    }
    return prm;
  }, [template]);

  const linkParams = useMemo(() => {
    if (!template.Link) return null;

    var parts = template.Link.split("[*");
    var prm = [];

    for (var i = 0; i < parts.length - 1; i++) {
      var nameclass = parts[i].split(".").pop();
      prm.push(nameclass);
    }
    return prm;
  }, [template]);

  const isSelected = useMemo(() => {
    return id === selected;
  }, [id, selected]);
  var parts = id.split(".");
  var breadcrumbs = [];
  while (parts.length > 1) {
    parts.pop();
    breadcrumbs.unshift(parts.join("."));
  }

  return (
    <div className="flex flex-col w-full border-l  items-start text-xs">
      {isSelected && (
        <>

          <div className="flex flex-col w-full">
            {shows.Name && (
              <div className="flex flex-col w-full">
                {  (
                  <>
                    <InputSelectPropSchema
                    context={context}
                      label="Propriété Label"
                      className="text-black w-full my-1"
                      value={template.schema_prop}
                      onChange={(schema_prop, schema_id,isList) => {
                        delete template.template_id;
                        if (!schema_prop) {
                          var _tpl = template;
                          delete _tpl.schema_prop;
                          delete _tpl.schema_id;
                          delete _tpl.template_id;
                          setStructure(_tpl);
                          return;
                        } else {
                          var code =
                            "" +
                            schemas.find(
                              (s) => s.schema_id + "" === "" + schema_id
                            )?.content.code;
                          var tag = template.tag;
                          if (code) {
                            if (code.startsWith("image")) tag = "img";
                            if (code.startsWith("icon")) tag = "icon";
                            if (code.startsWith("text")) tag = "text";
                          }
                         
                          setStructure({
                            ...template,
                            tag,
                            schema_id: schema_id ?? template.schema_id,
                            schema_prop,
                            isList
                          });
                        }
                      }}
                      globalSchema={globalSchema}
                      criteria_schema={(c) =>
                        true ||
                        c.schema_id === template.schema_id ||
                        !template.schema_id
                      }
                      schemas={schemas}
                      parentSchema_prop={schema_prop}
                      placeholder={"Choisir la propriété"}
                      options={[]}
                    />
                  </>
                )}

                { (
                  <div className="flex w-full">
                       <SelectDropDown  
              label={"Schéma"}
              placeholder={!template.schema_prop ?"<HTML>":null}
   mainClassName="text-black w-full my-1 text-sm"
              value={template.schema_id}
              className={"text-black w-full  text-sm"}
              
              fieldId={"content_id"}
              fieldSearch={"title"}

  
              onChange={(schema) => {
                schema_id = schema ? parseInt(schema.content_id) :  null
                var code =
                  "" +
                  schemas.find((s) => s.content_id + "" === "" + schema_id)
                    ?.content.code;
             
                var tag = template.tag;
                if(!schema_id) tag= "div"
                if (code) {
                  if (code.startsWith("image")) tag = "img";
                  if (code.startsWith("icon")) tag = "icon";
                  if (code.startsWith("text")) tag = "text";
                }
                setStructure({
                  ...template,
                  tag,
                  schema_id
                });
              }}
              
              options={schemas.filter(s=> !template.schema_prop || template.schema_id+""===""+s.content_id )}
              />
                            <InputBoolean
                  labelTrue={"Liste"}
                  value={template.isList}
                  onChange={(isList) => setStructure({ ...template, isList })}
                />
                {template.isList === 1 && (
                  <InputNum
                    className={"w-16"}
                    label={"N"}
                    value={template.listLength}
                    onChange={(listLength) =>
                      setStructure({ ...template, listLength })
                    }
                  />
                )}
              </div>
                )}

{(template.tag === "template" ||
                  template.schema_id ) && (
                  <div className="border-x-2 p-1 border-zinc-600  ">
                                <div className="flex flex-row w-full">
                      
                      <InputTemplate
                        label="Template"
                        value={template.template_id}
                        onChange={(template_id) => {
      
                                 // On efface les anciennes _meta_ 
                                 Object.keys(template).filter(key=>key.startsWith("_meta_")).forEach(key=> delete template[key])
                            //Si c'est un meta , on met les valeurs par defaut
                            template= metaDefaultValues(template_id,template,templates,context?.template?.template_id)
                          setStructure({ ...template, template_id });
                        }}
                        placeholder={"---   Template " +  (
                        (  mySchema &&
                          mySchema.content.is_value )? "Valeur":"") +" Par défaut  ----"}

                        filter={ (t) =>
                              ( 
                                ((template.template_id + "" ===
                                "" + t.template_id ||
                                !t.app_id ||
                                (app_id && t.app_id === app_id))  &&
                              (!template.schema_id ||
                                template.schema_id === t.schema_id))
                                || (Object.values(t.meta??{}).length>0)) 
                                && notAscendantTemplate( t)
                        }
                        className={" grow"}
                        context={{onlyId:true}}
                      />

                      <a
                        href={`/template/${template.template_id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                       { template.template_id && <FontAwesomeIcon
                          icon={fa.faEdit}
                          sonClick={() =>
                            setShowEditionTemplate(!showEditionTemplate)
                          }
                          className="p-2 text-xl"
                        />}
                       </a>
                    </div>
                    <div className="flex flex-row w-full hidden">

                      <InputSelect
                        label="Template"
                        value={template.template_id}
                        onChange={(template_id) => {
      
                                 // On efface les anciennes _meta_ 
                                 Object.keys(template).filter(key=>key.startsWith("_meta_")).forEach(key=> delete template[key])
                            //Si c'est un meta , on met les valeurs par defaut
                            template= metaDefaultValues(template_id,template,templates,context?.template?.template_id)
                          setStructure({ ...template, template_id });
                        }}
                        placeholder={"---   Template " +  (
                        (  mySchema &&
                          mySchema.content.is_value )? "Valeur":"") +" Par défaut  ----"}
                        options={templates
                          .filter(
                            (t) =>
                              ( 
                                ((template.template_id + "" ===
                                "" + t.template_id ||
                                !t.app_id ||
                                (app_id && t.app_id === app_id))  &&
                              (!template.schema_id ||
                                template.schema_id === t.schema_id))
                                || (Object.values(t.meta??{}).length>0)) 
                                && notAscendantTemplate( t)
                          )
                          .map((tpl, i) => {
                            return { value: tpl.template_id, label: tpl.title };
                          })}
                        className={" grow"}
                      />

                      <a
                        href={`/template/${template.template_id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                       { template.template_id && <FontAwesomeIcon
                          icon={fa.faEdit}
                          sonClick={() =>
                            setShowEditionTemplate(!showEditionTemplate)
                          }
                          className="p-2 text-xl"
                        />}
                       </a>
                    </div>
                    {showEditionTemplate && template.template_id && (
                      <TemplateEdition
                        id={id}
                        selected={selected}
                        template_id={template.template_id}
                        setSelected={selectTpl}
                      />
                    )}
                    {reduceMetaData  &&   reduceMetaData.map((r,i)=><div key={r.key} alt>
{
r.type==="schema" && <InputSchema  label={r.name +" - Schema Mapping"} value={template["_meta_"+ r.key]}  onChange={(schema_id) => {
  setStructure({ ...template, ["_meta_"+ r.key]:schema_id });
   
} }  
context={ r.suids.includes("main") ?{ filter:(s)=>s.content_id+""===""+ template.schema_id  ,onlyId:true   }:{ onlyId:true}}
/>
}
{
r.type==="template" && <InputTemplate  label={r.name +" - Template Mapping "} value={template["_meta_"+ r.key]}  onChange={(template_id) => {
  setStructure({ ...template, ["_meta_"+ r.key]:template_id });
}} 
filter={  (t)=>    notAscendantTemplate( t) &&  t.schema_id+"" === "" + template[ "_meta_"+    reduceMetaData.find(r0=> r0.type==="schema" && r0.suids.includes(r.suids[0]) )?.key   ]   }
context ={{ onlyId:true}}
/>
}

{ "_meta_"+  r.key } : {template[ "_meta_"+ r.key]}

                    </div>)
                
                    }
                  </div>
                )}
                {
                     <InputMultiSelect
                     label={"Template Size"}
                     value={template.sizes}
                     onChange={(sizes) => {
                      setStructure({
                        ...template,
                        sizes,
                      });
                     }}
                     values={["full","fit","screen","xs", "sm","lg","xl",
                       "w-full","w-fit","w-screen","w-xs", "w-sm","w-lg","w-xl",
                     "h-full","h-fit","h-screen","h-xs", "h-sm","h-lg","h-xl"].map((size) => ({
                       key: size,
                       label:size,
                     }))}
                   />
                }
                <div className="flex flex-row w-full">
                  <select
                    className="grow"
                    value={
                      template.prop ? "prop-" + template.prop : template.tag
                    }
                    onChange={(e) => {
                      if (e.target.value.includes("prop-")) {
                        var parts = e.target.value.split("-");
                        template.prop = parts[1];
                        template.tag = "div";
                      } else {
                        delete template.prop;
                        template.tag = e.target.value;
                      }

                      setStructure({ ...template });
                    }}
                  >
                    <option value={"div"}>DIV</option>
                    <option value={"span"}> SPAN</option>
                    <option value={"input"}> INPUT</option>
                    <option value={"text"}> Texte</option>
                    <option value={"img"}> Image</option>
                    <option value={"icon"}> Icone</option>
                    <option value={"video"}> Video</option>
                    <option value={"map"}> Map</option>
                    <option value={"template"}> Template</option>
                  </select>
     
                  {template.isList === 1 && (
                    <InputNum
                      className={"w-16"}
                      label={"N"}
                      value={template.listLength}
                      onChange={(listLength) =>
                        setStructure({ ...template, listLength })
                      }
                    />
                  )}
                </div>

  { !templateContent && template.schema_id && mySchema &&
  <InputData   prop={  { name:"défaut", type_nombre:template.isList? "I":null, schema_id: template.schema_id }}  value={template.default_value }  schema={mySchema} onChange={(default_value) => {
    setStructure({ ...template, default_value });
  }}  />}
    {templateContent && template.schema_id && mySchema &&
  <InputData   prop={  { name:"défaut", type_nombre:template.isList? "I":null, schema_id: template.schema_id }}  value={templateContent.content[template.suid] }  schema={mySchema} onChange={(default_value) => {
updateContent({...templateContent,content:{...templateContent.content,[template.suid]:default_value}});

  }}  />}
            

                {false && template.tag === "img" && (
                  <>
                    <ChooseImage
                      className={
                        "rounded-xl border border-zinc-400 w-full p-2 my-2"
                      }
                      domain={domain}
                      value={template.imageUrl}
                      onChange={(imageUrl) => {
                        setStructure({ ...template, imageUrl });
                      }}
                      placeholder="---  aucune image --- "
                    />
                  </>
                )}

                {!template.schema_id && (
                    <div className="w-full flex items-center"><InputSelect
                    className={"flex-grow "}
                      label={"Mise en Page"}
                      value={currentFlexbox()}
                      onChange={(className) => {
                        setFlexBox( className  );
                      }}
                      placeholder={"---  Mise en Page  ----"}
                      options={flexboxes.map((f, i) => {
                          return { value: f.content.className, label: f.title };
                        })}
                    />
                    <div onClick={addChildren} className="appbutton m-1 bg-blue-500 text-white p-2 px-3 rounded-full" >{   template.children.length} + </div>
                    
                    </div>
                  )}
                {!template.schema_id  && template.tag === "icon" && (
                  <>
                    <InputIcon
                      label={"icone"}
                      value={template.icon}
                      onChange={(value) => {
                        setStructure({ ...template, icon: value });
                      }}
                    />
                  </>
                )}

                {!template.schema_id  && template.tag === "text" && (
                  <InputTextArea
                    value={template.text}
                    label={"Text"}
                    className={"w-full my-2"}
                    bg="bg-zinc-100"
                    onChange={(value) =>
                      setStructure({ ...template, text: value })
                    }
                  />
                )}
                {template.schema_prop} /  {template.schema_label}
             {  !template.schema_prop  &&  <InputText
                  label={"Schéma Label"}
                  value={template.schema_label}
                  onChange={(schema_label) =>
                    setStructure({ ...template, schema_label })
                  }
                />
                }</div>
            )}
            {shows.Labels && <div className="flex flex-col p-2 w-full"></div>}

            {shows.Click && (
              <div className="flex flex-col p-2 w-full">
                <InputText
                  label={"Schéma Page Link"}
                  value={template.schema_link}
                  onChange={(schema_link) =>
                    setStructure({ ...template, schema_link })
                  }
                />
                <InputText
                  label={"Schéma Url"}
                  value={template.schema_url}
                  onChange={(schema_url) =>
                    setStructure({ ...template, schema_url })
                  }
                />
                <InputText
                  label={"Schéma Click"}
                  value={template.schema_click}
                  onChange={(schema_click) =>
                    setStructure({ ...template, schema_click })
                  }
                />
                <InputAction
                  label={"Action Click"}
                  prop={{ name: "IHM Action" }}
                  schema={{ content_id: 428 }}
                  value={template.action_click}
                  onChange={(action_click) =>
                    setStructure({ ...template, action_click })
                  }
                />


                <InputSelectPropSchema
                  label="Click"
                  value={template.onClick}
                  onChange={(value) => {
                    if (value) {
                      var parentStructure = null;
                      if (value.includes("SwitchInternal_")) {
                        var _internals = internals ?? [];
                        var i = value.split("_")[1];
                        if (!_internals.find((intern) => intern === i))
                          parentStructure = { internals: [..._internals, i] };
                      }
                      setStructure(
                        { ...template, onClick: value },
                        parentStructure
                      );
                    } else {
                      var _tpl = template;
                      delete _tpl.onClick;
                      setStructure(_tpl);
                    }
                  }}
                  globalSchema={globalSchema}
                  criteria_schema={(c) => c.code === "action"}
                  schemas={schemas}
                  parentSchema_prop={schema_prop}
                  placeholder={"---Click----"}
                  options={[
                    ...(content?.CRUD
                      ? [
                          {
                            value: "CONTENT_CREATE",
                            label: "Ajouter " + content.CRUD.label,
                          },
                          {
                            value: "CONTENT_SET",
                            label: "Enregistrer " + content.CRUD.label,
                          },
                          {
                            value: "CONTENT_UPDATE",
                            label: "Modifier " + content.CRUD.label,
                          },
                        ]
                      : []),
                    ...(content?.___D
                      ? [
                          {
                            value: "CONTENT_DELETE",
                            label: "Supprimer " + content.___D.label,
                          },
                        ]
                      : []),
                    ...Popables.map((val, i) => {
                      return {
                        value: "Popup:" + val.prop,
                        label: " Popup " + val.prop,
                      };
                    }),
                    ...[...(internals ?? []), 0].map((val, i) => {
                      return {
                        value: "SwitchInternal_" + val,
                        label: "Switch Internal " + val,
                      };
                    }),
                  ]}
                />

                {template.onClick &&
                  template.onClick?.split("Popup:").length === 2 && (
                    <InputSelect
                      label={"Template PopUp"}
                      value={template.popupTemplate}
                      onChange={(popupTemplate) => {
                        setStructure({ ...template, popupTemplate });
                      }}
                      placeholder={"---   Template  Popup  ----"}
                      options={templates
                        .filter(
                          (tpl) =>
                            tpl.schema_id + "" === popableObj?.schema_id + ""
                        )
                        .map((tpl, i) => {
                          return { value: tpl.template_id, label: tpl.title };
                        })}
                    />
                  )}

                <InputSelectPropSchema
                  label="Link"
                  value={template.Link}
                  onChange={(Link) => {
                    if (Link !== "-1") {
                      var parentStructure = null;

                      setStructure({ ...template, Link }, parentStructure);
                    } else {
                      var _tpl = template;
                      delete _tpl.Link;
                      setStructure(_tpl);
                    }
                  }}
                  globalSchema={globalSchema}
                  criteria_schema={(c) => c.code === "link"}
                  schemas={schemas}
                  placeholder={"---Link----"}
                  options={[]}
                  parentSchema_prop={schema_prop}
                />

                <div className="flex flex-row">
                  {linkParams &&
                    linkParams.map((param, index) => (
                      <input
                        type="number"
                        value={template["iLink" + param]}
                        placehoder="-"
                        onChange={(e) => {
                          template[`iLink${param}`] = e.target.value;
                          setStructure({ ...template });
                        }}
                        key={index}
                        className="w-8 border-b border-dashed border-black m-1"
                      />
                    ))}
                </div>
              </div>
            )}
            {shows.Vars && (
              <div className="flex flex-col p-2 w-full">
                                <InputData
                                id={id + "_event_"}
                  className={"w-full"}
                  prop={{ name: "Event Action", type_nombre: "I", define: true,define_open:true  }}
                  schema={{ content_id: 440 }}
                  value={template.actions ?? []}
                  onChange={(actions) => setStructure({ ...template, actions })}
                />

<InputData
                                id={id + "_computed_"}
                  className={"w-full"}
                  prop={{ name: "Propriété Calculée", type_nombre: "I", define: true,define_open:true  }}
                  schema={{ content_id: 653 ,content:{code:"computed"   }}}
                  value={template.computed ?? []}
                  onChange={(computed) => setStructure({ ...template, computed })}
                  context={{   app_id,
               key_schemas : _key_schemas    }}
                />




                <InputData
                  className={"w-full"}
                  prop={{ name: "Variable UI", type_nombre: "I", define: true,define_open:true }}
                  schema={{ content_id: 137 }}
                  value={template.vars ?? []}
                  onChange={(vars) => setStructure({ ...template, vars })}
                />
              </div>
            )}
            {shows.Style && (
              <div className="flex flex-col">
                <select
                  value={template.background}
                  onChange={(e) => {
                    if (e.target.value !== "-1")
                      setStructure({ ...template, background: e.target.value });
                    else {
                      var _tpl = template;
                      delete _tpl.background;
                      setStructure(_tpl);
                    }
                  }}
                >
                  <option value={-1}>--- Fond----</option>
                </select>
                {bgParams &&
                  bgParams.map((param, index) => (
                    <div className="flex flex-row" key={index}>
                      <label className="hidden">{param}</label>
                      <input
                        type="number"
                        value={template["iBg" + param]}
                        placehoder="-"
                        onChange={(e) => {
                          template[`iBg${param}`] = e.target.value;
                          setStructure({ ...template });
                        }}
                        key={index}
                        className="w-8 border-b border-dashed border-black"
                      />
                    </div>
                  ))}
                <InputStyle
                  value={template.className}
                  label={"Style"}
                  className={"w-full my-2"}
                  bg="bg-zinc-100"
                  onChange={(value) =>
                    setStructure({ ...template, className: value })
                  }
                />
                <InputBoolean
                  value={template.transition}
                  onChange={(value) =>
                    setStructure({ ...template, transition: value })
                  }
                  className={"hidden p-0 border-none w-20"}
                  size={"w-2"}
                  labelFalse={"Pas de transiton"}
                  labelTrue={"Transitions actives"}
                />
                <select
                  value={template.condition}
                  onChange={(e) => {
                    if (e.target.value !== "-1")
                      setStructure({ ...template, condition: e.target.value });
                    else {
                      var _tpl = template;
                      delete _tpl.condition;
                      setStructure(_tpl);
                    }
                  }}
                >
                  <option value={-1}>--- Condition d'affichage----</option>

                  {internals?.map((val, i) => (
                    <option key={i} value={"Internal_" + val + " OK"}>
                      {" "}
                      {"Internal " + val} OK
                    </option>
                  ))}

                  {internals?.map((val, i) => (
                    <option key={i} value={"Internal_" + val + " NO"}>
                      {" "}
                      {"Internal " + val} NO
                    </option>
                  ))}
                </select>

                {template.condition && (
                  <div className="  flex flex-col  md:grid md:grid-cols-3  w-full gap-2">
                    <InputStyle
                      value={template.classNameActive}
                      label={"Style Active"}
                      className={"w-full my-2"}
                      bg="bg-zinc-100"
                      onChange={(value) =>
                        setStructure({ ...template, classNameActive: value })
                      }
                    />
                    <InputStyle
                      value={template.classNameExit}
                      label={"Style Exit"}
                      className={"w-full my-2"}
                      bg="bg-zinc-100"
                      onChange={(value) =>
                        setStructure({ ...template, classNameExit: value })
                      }
                    />
                    <InputStyle
                      value={template.classNameEnter}
                      label={"Style Enter"}
                      className={"w-full my-2"}
                      bg="bg-zinc-100"
                      onChange={(value) =>
                        setStructure({ ...template, classNameEnter: value })
                      }
                    />
                  </div>
                )}
                <select
                  value={template.conditionStyle}
                  onChange={(e) => {
                    if (e.target.value !== "-1")
                      setStructure({
                        ...template,
                        conditionStyle: e.target.value,
                      });
                    else {
                      var _tpl = template;
                      delete _tpl.conditionStyle;
                      setStructure(_tpl);
                    }
                  }}
                >
                  <option value={-1}>--- Style Conditionné ----</option>

                  {internals?.map((val, i) => (
                    <option key={i} value={"Internal_" + val + " OK"}>
                      {" "}
                      {"Internal " + val} OK
                    </option>
                  ))}

                  {internals?.map((val, i) => (
                    <option key={i} value={"Internal_" + val + " NO"}>
                      {" "}
                      {"Internal " + val} NO
                    </option>
                  ))}
                </select>
                {template.conditionStyle && (
                  <div className="  flex flex-col  md:grid md:grid-cols-2  w-full gap-2">
                    <InputStyle
                      value={template.classNameConditionNO}
                      label={
                        "Style si (" + template.conditionStyle + ") est FAUX"
                      }
                      className={"w-full my-2"}
                      bg="bg-zinc-100"
                      onChange={(value) =>
                        setStructure({
                          ...template,
                          classNameConditionNO: value,
                        })
                      }
                    />
                    <InputStyle
                      value={template.classNameConditionOK}
                      label={
                        "Style si (" + template.conditionStyle + ") est VRAI"
                      }
                      className={"w-full my-2"}
                      bg="bg-zinc-100"
                      onChange={(value) =>
                        setStructure({
                          ...template,
                          classNameConditionOK: value,
                        })
                      }
                    />
                  </div>
                )}
              </div>
            )}
            {true && JSON.stringify(internals)}
          </div>
        </>
      )}
      {!template.prop && template.tag !== "text" && (
        <>
          {false && shows.Enfants && isSelected && (
            <>
              <div className="flex flex-row justify-start p-2">
                Children
                {template &&
                  template.children?.map((c, i) => (
                    <div
                      key={i}
                      className="rounded-full bg-black p-2 m-2"
                      onClick={() => selectTpl(id + "." + i)}
                    >
                      {" "}
                    </div>
                  ))}{" "}
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  onClick={addChildren}
                  className=" ml-8  text-green-600 text-xl "
                />
              </div>
            </>
          )}
          {"children" in template &&
            template.children.map((tpl, i) =>
           //   ({tpl,i})).filter(obj=>(selected+"").includes(id+"."+obj.i ))
            //.map(({tpl,i})=>       
             (
              <TemplateStructure
                globalSchema={globalSchema}
                schemas={schemas}
                template={tpl}
                domain={domain}
                key={i}
                internals={internals}
                deleteFunc={() => delChildren(i)}
                unWrapFunc={() => unWrapDiv(i)}
                downSortFunc={() => sortChildren(i, 1)}
                upSortFunc={() => sortChildren(i, -1)}
                unChildFunc={() => unChildren(i)}
                content={{ ...content }}
                templateContent={templateContent}
                createSubTemplateFunc={createSubTemplateFunc}
                childBecomeSiblingFunc={(tpl) =>
                  childOfChildBecomeChild(i, tpl)
                }
                templates={templates}
                setStructure={(structure, parentStructure) => {
                  var children = template.children.map((c, index) =>
                    i === index ? structure : c
                  );
                  setStructure({ ...template, children }, parentStructure);
                }}
                id={id + "." + i}
                selectTpl={selectTpl}
                selected={selected}
                shows={shows}
                app_id={app_id}
                schema_id= {schema_id}
                schema_prop={template.schema_prop ?? schema_prop ?? null}
                key_schemas={_key_schemas}
              />
            ))}
        </>
      )}
    </div>
  );

  //else
  //return <span >{endpoint} </span>
}

export default TemplateStructure;
