import { useEffect, useState } from "react"
import { data } from "../service/data"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as fa from "@fortawesome/free-solid-svg-icons";
import React from "react";
import DrawingSVG from "./SvgTools/DrawingSVG";


const SvgEditor = React.memo(({ content,setContent, className,style,onClick,id }) => {




    return (
      <DrawingSVG  content={content} setContent={setContent}/>
    )
  })
  

export default SvgEditor