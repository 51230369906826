import React, { useEffect, useMemo, useState, useContext } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InputText from '../../../../components/inputs/InputText';
import InputImage from '../../../../components/inputs/InputImage';
import InputColor from '../../../../components/inputs/InputColor';
import InputSelect from '../../../../components/inputs/InputSelect';
import InputTextArea from '../../../../components/inputs/InputTextArea';
import InputButton from '../../../../components/inputs/InputButton';


import '../../../../App.css';
import { MfctContext } from "../../../../context";
import ContentEdition from '../../../../components/edition/ContentEdition';

function AppSettings({ app, getApps }) {


  const { project, getProject, setAuthUser, authUser } = useContext(MfctContext);
  const [mods, setMods] = useState(app);
  const [pages, setPages] = useState([]);
  const [deployment_servers,setDeploymentServers] = useState(["prod1"]);

//requete vers liste accounts


  const deleteApp = async (app) => {
    if (!window.confirm("Are you sure you want to delete '" + app.name + "'?")) return
    await project.api("/delete_app/:app_id", { args: { app_id: app.app_id } })
    await getApps(true);
    toast("Successfully deleted '" + app.name);
  }

  const saveApp = async (e) => {
    e.preventDefault();

    const formData = new FormData()
    mods["display"] = mods["profile"]["display"]
    mods["primary_color"] = mods["profile"]["primary_color"]
    mods["secondary_color"] = mods["profile"]["secondary_color"]
    mods["homepage_id"] = mods["profile"]["homepage_id"]
    Object.keys({ ...mods, profile: null }).forEach(k => formData.append(k, mods[k]))


    var newapp = await project.api("/save_apppro", { body: formData });
    toast("Successfully saved '" + app.name);
    console.log(newapp)
    await getApps();
  }


  const getPages = async () => {

    var _pages = await project.api("/get_mypages/:app_id", { args: { app_id: app.app_id } })

    setPages(_pages);
  }



  useEffect(() => {
    if (project) {
      getPages()

    }
  }, [project])



  return (

    <div className='w-full flex flex-col py-4 bg-white px-4 rounded-xl' >
      <ContentEdition  content_id={"app_" + app.app_id}  schema_id={"60"}  />
      <div onClick={() => deleteApp(app)} className='text-red-700 p-1 underline text-xs'>Supprimer l'application {app.name}</div>
     { false && <div className='w-full flex flex-col md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  gap-3 hidden ' >
        <InputText label={"domain"} disabled={true} value={mods.domain} onChange={(value => setMods((m) => { return { ...m, domain: value } }))} />
        <InputText label={"short_name"} value={mods.short_name} onChange={(value => setMods((m) => { return { ...m, short_name: value } }))} />
        <InputText label={"name"} value={mods.name} onChange={(value => setMods((m) => { return { ...m, name: value } }))} />
        <InputImage label='Icone' value={process.env.REACT_APP_MFTC_SERVER_URL_FILES + `profiles/${app.domain}/user.png`} onChange={(value => setMods((m) => { return { ...m, file: value } }))} />
          <InputSelect label={"display"} value={mods.profile.display} options={[{ value: "fullscreen" }, { value: "standalone" }]} onChange={(value => setMods((m) => { return { ...m, profile: { ...m.profile, display: value } } }))} />
        <InputColor label={"primary_color"} value={mods.profile.primary_color} onChange={(value => setMods((m) => { return { ...m, profile: { ...m.profile, primary_color: value } } }))} />
        <InputColor label={"secondary_color"} value={mods.profile.secondary_color} onChange={(value => setMods((m) => { return { ...m, profile: { ...m.profile, secondary_color: value } } }))} />
        <InputTextArea label={"description"} value={mods.description} onChange={(value => setMods((m) => { return { ...m, description: value } }))} />
   
      </div>}
      {
        false 
        &&
        <div className=' flex flex-col w-full p-2'>
          <InputButton label={"Enregistrer"} onClick={saveApp} />
          <div onClick={() => deleteApp(app)} className='text-red-700 p-1 underline text-xs'>Supprimer l'application {app.name}</div>
        </div>}
    </div>
  );
}

export default AppSettings;
