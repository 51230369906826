import { useEffect, useState } from "react";

import InputSelect from "./InputSelect";
import { data } from "../../service/data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import * as fa from "@fortawesome/free-solid-svg-icons";
import InputText from "./InputText";
import { getAvailableActions } from "../../tools/actions";
import { api } from "../../service/mftc-api";
import { showModalOK } from "../modal";
import InputData from "./InputData";
import { getRecord } from "../../service/localdata";
import VarContent from "../VarContent";

var _schemas = {};

const InputEnvironment = ({
  label,
  onChange,
  value,
  className,
  placeholder,
  domain,
  filter,
  id,
  context,
}) => {



return <>Environment</>

};
export default InputEnvironment;
