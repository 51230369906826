
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import { data } from "../../../../../service/data";

import InputData from "../../../../../components/inputs/InputData";
import { useEffect, useState } from "react";
const TemplateMeta = ({template,setShow,schemas,roles,conditions,internalContent,setInternalContent,deleteTemplate,key_schemas}) => {

const  [strTemplates,setStrTemplates] = useState([])
const [options,setOptions] = useState([{one:true}])
const  [scopes,setScopes ] =useState({})
  useEffect(()=>{
var bds= {}

const loadTemplate= async(template_id)=>{
  if(!bds["template_" + template_id])    bds["template_" + template_id] = await data.getTemplate(template_id)
    bds["template_" + template_id].type="Template"
      bds["template_" + template_id].type_id=template_id
      bds["template_" + template_id].meta=true
    bds["template_" + template_id]._count = (bds["template_" + template_id]._count??0)+1
    return bds["template_" + template_id]

}

const loadSchema= async(schema_id)=>{
  if(!bds["schema_" + schema_id]) bds["schema_" + schema_id] = await data.getSchema(schema_id)
        bds["schema_" + schema_id].type="Schema"
          bds["schema_" + schema_id].meta=true
              bds["schema_" + schema_id].type_id=schema_id
    bds["schema_" + schema_id]._count = (bds["schema_" + schema_id]._count??0)+1
    return bds["schema_" + schema_id]

}

const setTemplates= async(structure)=>{
        var tpls = []
      
var blnMain = !structure  
 if(blnMain) structure = template.structure

 var obj = []
 if(blnMain && template.schema_id) {

  let schema = await loadSchema(template.schema_id)
  obj= [...obj,...[
  {type :"Main",  name :"self - " + schema.title +" - " + template.layout ,suid:"main" , action : template.layout}, 
  {type :"schema",  name :schema.title ,  prop:"self" ,type_id :template.schema_id ,suid:"main" , action : template.layout,meta:true}
 ]]

}
if(structure.schema_prop){
   obj.push({ type :"propriété" ,type_id:structure.schema_prop ,name :structure.schema_prop  + ( structure.isList ? "- List":"") , suid:structure.suid})


 
}
  if(structure.schema_id){
  let schema = await loadSchema(structure.schema_id)
  obj.push({ type :"schema" , name :schema.title  , type_id :structure.schema_id , prop: structure.schema_prop, isList : structure.isList, suid:structure.suid,meta:true})
}
if(structure.template_id){
  let tpl = await loadTemplate(structure.template_id)
  obj.push({ type :"template" ,name :tpl.title,  type_id :structure.template_id , prop: structure.schema_prop, suid:structure.suid,meta:true})
}
    
if(obj.length>0) tpls.push( obj  )

if(structure.children ) 
  for( let i  in structure.children) {
let struct = structure.children[i]
let result =await setTemplates (struct) 
    tpls = [...tpls,...result]  
}


if(blnMain )  { 
  setScopes(bds)
  setStrTemplates(tpls)
  setOptions(    [ ...Object.values( bds  ).filter(b=>b._count>1).length>0 ?[{scope:"global" }]:[] ,{scope:"local"}])
 }
  return tpls 

      } 
setTemplates()


  },[template])

    if(!template ) return <></>  
    return    <div id={"TemplateMeta"}className=" flex-none p-2 w-80   flex flex-col items-center justify-center border-t border-gray-400  shadow">
      <div className="flex justify-between items-center text-xs w-full p-2 text-white bg-black rounded-xl mb-1 ">
      <FontAwesomeIcon icon={fa.faSpinner}></FontAwesomeIcon>
        Meta Template 
        <FontAwesomeIcon icon={fa.faClose}  onClick={ ()=> setShow((show) => ({ ...show, Meta: !show.Meta })) }  className="appbutton"  ></FontAwesomeIcon>
        </div>
    
          {
            <div className="flex flex-col w-full text-xs">
              { 
                strTemplates.map((obj,i)=> <div key={i} className="w-full flex flex-col border-b"> 
                
                {
                  obj.map((subject,j)=> <div key={j} className=" p-2 w-full flex flex-between items-center" > 
                   {
                  options.map((option,j)=> <div key={j} 
                  onClick={
                   !subject.meta  ||  (!option.scope &&(option.type!== subject.type ||  option.type_id!== subject.type_id)) ? null :()=>   {
                    var meta = {...template.meta}
                    var new_value = {...subject,scope:  option.scope}
                   var value =  JSON.stringify( new_value) === JSON.stringify( meta[ subject.type + "-" +subject.suid]?? {}) ? null : new_value
                    if(value) meta[ subject.type + "-" +subject.suid] =value
                    else delete  meta[ subject.type + "-" +subject.suid]

                    console.log("new meta",{meta})
                    data.updateTemplate({ ...template, meta });
                  }}
                  className={ " appbutton mx-2 rounded-full p-1 border  "+ ( !subject.meta  || ( option.scope==="global" && ( !scopes[subject.type +"_"+subject.type_id ] ||  scopes[subject.type +"_"+subject.type_id ]._count<2   ))? " opacity-0 border-transparent  text-transparent " :(  option.scope ===   template.meta[ subject.type + "-" +subject.suid]?.scope ?"bg-green-700 border-gray-500" :" border-gray-500") 
                  )   }> {option.scope}</div> )
                }
                  <div>{subject.type } :{ subject.name }</div> </div> )
                }
                </div>  )
              }
             </div>
          }
    

        </div>
      };
  


      export default TemplateMeta