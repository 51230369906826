import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import { data } from "../../../../../service/data";

import { getStructure } from "../../../../../tools/tpl";
import VarContent from "../../../../../components/VarContent";
import InputBoolean from "../../../../../components/inputs/InputBoolean";

import ContentSelect from "../../../../../components/inputs/ContentSelect";
import { getDataSource } from "../../../../../service/dataSource";

const ShowDataSource = ({
  setShow,
  template,
  updateTemplateStructure,
  selected,
  templates,
  schemas,
  globalSchema,
  content,
  selectTpl,
  ComponentStructTools,
  key_schemas,
  app_id
}) => {
  var dataSource = template.structure.dataSource ?? {};


const  [showDataSource, setShowDataSource]=useState({})

  return (
    <div
      id={"ShowDataSource"}
      className=" flex-none w-80  m-2 flex flex-col items-center justify-center border-t   shadow"
    >
      <div className="flex justify-between items-center text-xs w-full p-2 text-white bg-black rounded-xl mb-1 ">
        <FontAwesomeIcon icon={fa.faDatabase}></FontAwesomeIcon>
        Data Source
        <FontAwesomeIcon
          icon={fa.faClose}
          onClick={() =>
            setShow((show) => ({ ...show, DataSource: !show.DataSource }))
          }
          className="appbutton"
        ></FontAwesomeIcon>
      </div>
      <div className="p-2 w-full flex flex-col">
        { template?.props_from_templates && template?.props_from_templates[template.template_id]?.props &&  Object.values(
          template.props_from_templates[template.template_id].props
        )
          .filter((prop) => prop.schema && !prop.schema.content.is_value &&   prop.from!=="self")
          .map((prop) => (
            <div
              className="w-full border-t border-gray-500 gap-1 my-1 flex flex-col items-start"
              key={prop.name}
            >
              <InputBoolean
                value={showDataSource[prop.name]}
                labelTrue={
                  prop.name +
                  (prop.type_nombre === "I" || prop.type_nombre === "F"
                    ? "-List"
                    : "") +
                  "  " +
                  prop.from
                }
                onChange={(value) =>setShowDataSource( s=>({...s, [ prop.name]:value }))

                }
              />
              {showDataSource[prop.name] ? (
                <>
                  <ContentSelect
                    className={"w-full"}
                    context={{
                      filter: (c) =>
                        c.content.schema?.content_id + "" === "" + prop.schema_id 
                   &&  (!!c.content.isList)  ===  (prop.type_nombre === "I" || prop.type_nombre === "F")  
                     ,
                      app_id:  app_id ??  template.app_id,
                    }}
                    value={""}
                    placeholder={"-- Sélectionner source --"}
                    onChange={async (dataSource_id) => {
                     
           
                     
                     var data = await getDataSource( dataSource_id,content,template.template_id )


                   if(content["_set_" + prop.name])  content["_set_" + prop.name].do(   data   )
                    console.log(" looking for _set_" + prop.name, content );
                    /*  updateTemplateStructure(
                        {
                          ...template.structure,
                          ["_data_" + prop.name]: value,
                        },
                        "-root"
                      )
                    */
                    
                    }
                    }
                    schema_id={688}
                  />
                </>
              ):<></>}
            </div>
          ))}

        <VarContent
          className={"text-xs hidden"}
          data={template.props_from_templates && template.props_from_templates[template.template_id]?.props}
        />
      </div>
    </div>
  );
};

export default ShowDataSource;
