import React, { useContext, useEffect, useState, useRef } from "react";
import { MfctContext } from "../../context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faMicrophone, faLink, faPlusCircle, faUpload, faPlay, faPause } from "@fortawesome/free-solid-svg-icons";
import ReactDOM from "react-dom";

function getTextFrom(blob) {
    return new Promise((resolve, reject) => {
        if (!('webkitSpeechRecognition' in window)) {
            resolve(""); 
            //reject(new Error('Désolé, votre navigateur ne supporte pas la reconnaissance vocale.'));
            return;
        }

        // Crée une nouvelle instance de l'API webkitSpeechRecognition
        var recognition = new window.webkitSpeechRecognition();
        
        // Définir la langue à "fr-FR" pour la reconnaissance vocale en français
        recognition.lang = 'fr-FR';
        
        // Permet de capturer les résultats continuellement
        recognition.continuous = false;
        recognition.interimResults = false;
        const audioContext = new AudioContext();
        recognition.onresult = function(event) {
            var transcript = event.results[0][0].transcript;
            resolve(transcript);  // Résout la Promise avec le texte transcrit
        };

        recognition.onerror = function(event) {
            resolve(""); 
          //  reject(new Error('Erreur de reconnaissance vocale: ' + event.error));  // Rejette la Promise avec l'erreur
        };

        // Convertir le blob en flux audio et l'utiliser pour la reconnaissance vocale
        var audioURL = URL.createObjectURL(blob);
        var audio = new Audio(audioURL);
        
        audio.onloadedmetadata = function() {
            // Créer un flux audio à partir de l'objet audio
            var stream = audio.captureStream();
            
            // Démarrer la reconnaissance vocale
            recognition.start();
            
            // Connecter le flux audio à la reconnaissance vocale
            var mediaStreamSource = audioContext.createMediaStreamSource(stream);
            mediaStreamSource.connect(audioContext.destination);
        };

        // Gérer la fermeture du flux audio
        audio.onended = function() {
            URL.revokeObjectURL(audioURL);
        };
    });
}



const ChooseAudio = ({ value, label, onChange, className, domain, placeholder, defaultValue, immersiveMode }) => {
  const id = "ChooseAudio" + Date.now();
  const { project } = useContext(MfctContext);
  const [show, setShow] = useState();
  const [editUrl, setEditUrl] = useState();
  const [audios, setAudios] = useState([]);

  const [recording, setRecording] = useState(false); // État pour savoir si on enregistre
  const mediaRecorderRef = useRef(null); // Référence au MediaRecorder
  const audioChunksRef = useRef([]); // Pour stocker les morceaux audio



  const [currentAudio, setCurrentAudio] = useState(null); // L'audio actuellement en lecture
  const [isPlaying, setIsPlaying] = useState(false); // Suivi de l'état de la lecture



  const getAudios = async () => {
    var _audios = await project.getFiles(domain ? "apps/" + domain : "", ["audio"]);
    setAudios(_audios);
  };

  useEffect(() => {
    if (show) getAudios();
  }, [show, domain, project]);

  function onUploadProgress(progressEvent) {
    const { loaded, total } = progressEvent;
    let percentage = Math.floor((loaded * 100) / total);
    console.log(percentage);
    if (percentage === 100) {
      getAudios();
    }
  }

  const onSelect = (audio) => {
    onChange(audio);
    setShow(false);
  };

  function handleAudioChange(e) {
    if (e.target.files.length === 0) return;
    setAudios([URL.createObjectURL(e.target.files[0]), ...audios]);

    project.uploadFile(e.target.files, onUploadProgress, domain ? "apps/" + domain : "").then((results) => {
      if (results && results.length === 1) {
        console.log(results[0]);
        onChange(results[0]);
      }
    });
  }
  const streamRef = useRef(null);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      streamRef.current = stream; 
      mediaRecorderRef.current = new MediaRecorder(stream);

      audioChunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = async () => {


        setRecording(false);

           
       // Arrêter le flux audio
       if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
        streamRef.current = null;
    }

        const blob = new Blob(audioChunksRef.current, { type: "audio/mp3" });


       const text =window.prompt("Saisir le nom de votre enregistrement", "sound")??"sound";

       // await getTextFrom(blob) ?? "sound" ;
        const file = new File([blob], text +"__.mp3", { type: "audio/mp3" });
        
        // Uploader l'audio via project.uploadFile
        const result = await project.uploadFile([file], onUploadProgress, domain ? "apps/" + domain : "");
        if (result && result.length === 1) {
          const uploadedAudioUrl = result[0];
          console.log("Fichier audio téléchargé :", uploadedAudioUrl);
          setAudios([uploadedAudioUrl, ...audios]);
          onChange(uploadedAudioUrl);
        }

    //    onChange(blob);
      };

      mediaRecorderRef.current.start();
      setRecording(true);
    } catch (err) {
      console.error("Erreur lors de l'enregistrement : ", err);
    }
  };

  const togglePlayPause = (audio) => {
    if (currentAudio && currentAudio.src === audio) {
      if (isPlaying) {
        currentAudio.pause();
        setIsPlaying(false);
      } else {
        currentAudio.play();
        setIsPlaying(true);
      }
    } else {
      if (currentAudio) currentAudio.pause(); // Stop any currently playing audio
      const newAudio = new Audio(audio);
      setCurrentAudio(newAudio);
      newAudio.play();
      setIsPlaying(true);

      newAudio.onended = () => {
        setIsPlaying(false);
        setCurrentAudio(null);
      };
    }
  };

  const stopRecording = () => {

    if (mediaRecorderRef.current) {
      
        mediaRecorderRef.current.stop();
     
    }
  };

  if (typeof value === "object") value = null;
  var labele = value && value.split("/").pop();
  if (labele && labele.length === 0) labele = null;

  return (
    <>
      {immersiveMode ? (
        <div className={className + " overflow-hidden"} onClick={() => setShow(true)}>
          {(defaultValue || value) && <audio src={value ?? defaultValue} className="w-full" controls />}
        </div>
      ) : (
        <div className="flex flex-col w-full">
          <label
            htmlFor={"input_" + label}
            className="text-left w-4 text-sm text-gray-500 z-40 duration-300 transform translate-y-2 scale-75 top-2 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
          >
            {label}
          </label>
          <div className={"flex flex-row  gap-2 justify-between items-center relative border-zinc-400 rounded-xl border p-2 overflow-hidden " + className}>
         <div className="flex flex-col md:flex-row items-center justify-start w-full gap-2"> 
            {((value ?? defaultValue)+"").split("/").pop().split("__").shift()} 
            {(defaultValue || value) && <audio src={value ?? defaultValue} className="" controls />}

          </div>  
         
            <div className="flex">
             
              <div onClick={() => setShow(true)} className="p-2 m-1">
                <FontAwesomeIcon icon={faMicrophone} />
              </div>
              <div onClick={() => setEditUrl(!editUrl)} className="p-2 m-1">
                <FontAwesomeIcon icon={faLink} />
              </div>
              {editUrl && (
                <input
                  type="url"
                  className="ml-2 w-full border-b border-gray-600"
                  placeholder="saisir url"
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                />
              )}
            </div>
            {value ? (
              <FontAwesomeIcon className="text-red-600 p-2" icon={faClose} onClick={() => onChange(null)} />
            ) : (
              <FontAwesomeIcon className="opacity-0 p-2" icon={faClose} />
            )}
            </div>
        
        </div>
      )}

      {show &&
        ReactDOM.createPortal(
          <div
            id={id}
            className="fixed h-screen w-screen p-4 top-0 left-0 bg-black bg-opacity-90 text-2xl font-bold flex flex-col justify-start items-start overflow-auto"
            style={{ zIndex: 500000 }}
          >
            <div className="w-full p-4 text-white flex flex-row justify-between">
              <span>Choisir un fichier audio {label} - {domain}</span>
              <FontAwesomeIcon className="appbutton" onClick={() => setShow(false)} icon={faClose} />
            </div>
            <div className="w-full flex flex-wrap p-4  items-center justify-center">
              <div onClick={() => document.getElementById("audio-upload-" + label).click()} className="m-2 w-20 h-20 rounded-xl flex items-center justify-center  text-white  bg-black text-2xl">
                <FontAwesomeIcon className={ "appbutton h-10"} icon={faUpload} />
              </div>
              <input
                type="file"
                accept="audio/mp3,audio/wav,audio/ogg"
                name="audio"
                id={"audio-upload-" + label}
                className="hidden"
                onChange={handleAudioChange}
                required
              />
                          {/* Bouton pour enregistrer */}
            <div
             className="m-2 w-20 h-20 flex items-center justify-center rounded-xl bg-black text-2xl"
              onClick={async()=> {  if (!recording) await  startRecording();else  stopRecording();  }}

          
            >
                  <FontAwesomeIcon className={ "appbutton h-10" + (recording ?  "  animate-bounce  text-red-500" : " text-white")   }  icon={ faMicrophone    } />
           
            </div>
              {audios.filter(audio=>audio.split(".").pop()==="wav" || audio.split(".").pop()==="mp3" ).map((audio, i) => (
                <div  className="m-2 p-2 h-20 rounded-xl flex flex-col items-center justify-center  bg-white  text-black text-2xl" key={i} >
                     
                     <FontAwesomeIcon
                    className="appbutton h-10"
                    icon={isPlaying && currentAudio?.src === audio ? faPause : faPlay}
                    onClick={() => togglePlayPause(audio)}
                  />
                     <audio    src={audio} key={i} className="hidden max-w-fit h-20 object-cover bg-white m-2" controls onClick={() => onSelect(audio)} />
                  <span className="text-xs appbutton " onClick={() => onSelect(audio)} >{ audio.split("/").pop().split("__").shift()}</span>        
                </div>
              ))}
                       
            </div>


          </div>,
          document.getElementById("root")
        )}
    </>
  );
};

export default ChooseAudio;
