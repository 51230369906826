
const reduceMetaDataFunc = (template_id,templates)=>{

    var _data =templates.find(t=>t.template_id+""===""+template_id)?.meta
    
    if(!_data || Object.values(_data).length===0) return
      const uniqueData = {};
    
      Object.values(_data).forEach(item => {
        // Ajoute 'suid' dans la clé si le scope est 'local'
        const key = item.scope === 'local' 
          ? `${item.type}-${item.type_id}-${item.suid}` 
          : `${item.type}-${item.type_id}`; // Si global, pas de suid dans la clé
    
    
          if (item.scope === 'global') {
            // Si le scope est global, on garde type, type_id et name
            uniqueData[key] = {
              type: item.type,
              type_id: item.type_id,
              prop:item.prop,
              name: item.name
              ,key,
              suids:[   ...(uniqueData[key]? uniqueData[key].suids:[]), item.suid]
            };
          } else if (item.scope === 'local') {
            // Si le scope est local, on garde type, type_id, name et aussi suid
            uniqueData[key] = {
              type: item.type,
              type_id: item.type_id,
              prop:item.prop,
              name: item.name,
              suids: [item.suid],
              key
            };
          }
        
        
      });
    
      return Object.values(uniqueData); // Retourne un tableau d'objets uniques
    }

    const metaDefaultValues=(template_id,template,templates,mainTemplate_id)=>{
  
        var keyDatas = reduceMetaDataFunc(template_id,templates)

         if(!keyDatas){
          delete template.is_meta
          return template
          } 
          
          keyDatas.forEach(r=>{
      
      if(r.type==="schema"){
          if(r.suids.includes("main")) template["_meta_" + r.key]= template.schema_id 
      
      
          }
          })
          keyDatas.forEach(r=>{
          if(r.type==="template"){
            let schema_id= template[ "_meta_"+    keyDatas.find(r0=> r0.type==="schema" && r0.suids.includes(r.suids[0]) )?.key]
          
      if(schema_id) template["_meta_" + r.key]= templates.find( t=>t.schema_id+""===""+schema_id  && (!mainTemplate_id || t.template_id+""!==""+mainTemplate_id) )?.template_id
      else window.alert("no sCHEMA_ID " + JSON.stringify({r,keyDatas} ))
           
          }
      
         })
         template.is_meta=true
         
         return template
      }

const metaStructureMapping=(structure,meta)=>{

var suid = structure.suid
var meta_adds = {}
if(structure.schema_id && (meta[ "_meta_schema-" + structure.schema_id] || meta[ "_meta_schema-" + structure.schema_id+"-" + suid]))
{
 
  meta_adds.schema_id = meta[ "_meta_schema-" + structure.schema_id+"-" + suid] ?? meta[ "_meta_schema-" + structure.schema_id] 
}
if(structure.template_id && (meta[ "_meta_template-" + structure.template_id] || meta[ "_meta_template-" + structure.template_id+"-" + suid]))
  {

    meta_adds.template_id =  meta[ "_meta_template-" + structure.template_id+"-" + suid] ?? meta[ "_meta_template-" + structure.template_id] 
  }

var children=structure.children?.map((struct,i) => metaStructureMapping(struct,meta));


return {...structure,...meta_adds,children}
}



    export {
        reduceMetaDataFunc,metaDefaultValues,
        metaStructureMapping
    }