import React, { useEffect, useMemo, useState, useContext, useRef } from "react";

import { Link, useNavigate, useParams, useLocation } from "react-router-dom";

import * as fa from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import TemplateDisplay from "../../../../components/templates/TemplateDisplay";

import Image from "../../../../components/Image";
import "../../../../App.css";
import { MfctContext } from "../../../../context";
import { getAllRecords, getFilterRecord } from "../../../../service/localdata";
import { data } from "../../../../service/data";
import Header1 from "../../../../components/Header1";
import UserThumb from "../../../../components/thumbs/UserThumb";
import UserPin from "../../../../components/UserPin";
import ButtonTool from "../../../../components/ButtonTool";
import InputForSchemaProp from "../../../../components/inputs/InputForSchemaProp";
import { showModal, ModalPositions, showModalOK } from "../../../../components/modal";
import { generateTemplateBySchema, genlistTemplates } from "../../../../tools/genTemplate";
import TemplateX from "../../../../components/templates/TemplateX";
import VarContent from "../../../../components/VarContent";
import useMediaQuery from "../../../../tools/useMediaQuery";

function Templates() {
  const location = useLocation();
  const [SCHEMA_ID, setSCHEMA_ID] = useState();
  const [schema, setSchema] = useState();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const _SCHEMA_ID = queryParams.get("schema_id")
      ? parseInt(queryParams.get("schema_id"))
      : null;

    setSCHEMA_ID(_SCHEMA_ID);
    setSchema(schemas.find((c) => c.content_id === _SCHEMA_ID));
  }, [location]);

  const navigate = useNavigate();
  const { app_id } = useParams();

  const isMdCss = useMediaQuery('md');
  const {
    project,
    getProject,
    setAuthUser,
    authUser,
    updateTemplate,
    getSchemas,
  } = useContext(MfctContext);

  const schemas = getSchemas();
  const [templates, setTemplates] = useState([]);

  const [authors, setAuthors] = useState({});
  const _MAX_ = 100000000;
  const [timeSort, setTimeSort] = useState(true);

  const normalSort = (a, b) => {
    if (a.schema_id ?? _MAX_ !== b.schema_id ?? _MAX_)
      return (a.schema_id ?? _MAX_) > (b.schema_id ?? _MAX_) ? 1 : -1;

    return a.template_id > b.template_id ? -1 : 1;
  };
  async function getTemplates() {
    var _templates;

    if (SCHEMA_ID)
      _templates = await getFilterRecord(
        (f) => f.schema_id === SCHEMA_ID,
        "template"
      );
    else _templates = await getAllRecords("template");
    //  project.api("/templates")
    console.log(_templates, "templates");
    var schemas_names = {};
    _templates.sort(normalSort);

    _templates = _templates.map((t) => {
      if (!schemas_names[t.schema_id ?? _MAX_]) {
        schemas_names[t.schema_id ?? _MAX_] = t.template_id;
        t.groupSchema =
          schemas.find((s) => s.content_id === t.schema_id)?.title ??
          "sans schéma";
      }
      return t;
    });

    var _authors = {};
    _templates.forEach((t) => (_authors[t.owner_id] = true));
    setAuthors(_authors);

    setTemplates(_templates ?? []);
  }

  useEffect(() => {
    if (project) {
      document.title = "Templates";
      getTemplates();
    }
  }, [project, SCHEMA_ID]);

  const newTemplate = async () => {
    await data.init("template", "", false, project);
    var _template = await data.newTemplate({ title: (schema ? "Template " + schema.title :  " Nouveau template - " ) +Date.now(), schema_id: schema ? schema.content_id :null ,app_id:   schema ? schema.app_id :null  });
    await data.close();
    await getTemplates();

    window.open(
      "/template/" + _template.template_id,
      "viewtpl" + _template.template_id,
      "menubar=no,toolbar=no,location=no,address=no,"
    );
  };
  const TEMPLATE_SCHEMA_ID = 421;
  const sortedTemplates = useMemo(() => {
    var _templates = templates.map((t) => t);
    _templates.sort(
      timeSort ? (a, b) => (a.updated_at > b.updated_at ? -1 : 1) : normalSort
    );
    return _templates;
  }, [timeSort, templates]);
  const searchBar = useRef();
  const [searchValue, setSearchValue] = useState("");
  const [filterUser, setFilterUser] = useState();

  const genDisplayTemplateInit = async () => {
    showModal("", {
      darkMode: false,
      position: ModalPositions.CENTER,
      Content: <FormGenDisplayTemplate />,
    });
  };

  const openTemplate = (template_id) => {
    navigate("/template/" + template_id);
  };

  const FormGenDisplayTemplate = ({}) => {
    const [options, setOptions] = useState();

const  [candidateTpls,setCandidateTpls ]=useState([])


useEffect(()=>{

  const loadTpl = async()=>{
       
   var tpls = await genlistTemplates(schema,options)
setCandidateTpls(tpls)

  }  


   loadTpl()

},[schema,options])

    const genTemplate = async () => {
      showModal(null, { close: true })
      var tpl = await generateTemplateBySchema(schema, options);

      openTemplate(tpl.template_id);
     
    };





    return (
      <div className="flex flex-col">
        Generation de template {schema.title}
<div className=" overflow-auto flex "  style={{ width:"90vw",maxHeight:"50vh" }}  >
{ 
     candidateTpls?.map((template,i)=>    
    <div 
    className="inline-flex  w-full scale-75  shadow-lg"
     ><TemplateX    
    
     template = {template}
     internals={{}}
     setInternal={()=>{}}

     _internalContent={{}}
     content={{}}
     templates={[]}
     schemas={[]}
     contents={{}}
     id="0"
     selectTpl={()=>{}}

     safeDisplay={true}

        />
        <VarContent  data={{availablePlaces :  template.availablePlaces,schema: schema.content.schema }}  />
        </div>
    
    )

}



</div>


        <div className="p-2 flex items-center justify-center">

          <div
            className="Appbutton border  rounded bg-green-500 p-2"
            onClick={genTemplate}
          >
            Generer le template.{" "}
          </div>
          <div
            className="Appbutton border  rounded bg-red-500 p-2 text-center "
            onClick={() => showModal(null, { close: true })}
          >
            Annuler{" "}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="safearea App relative">
      <Header1
        title={"Templates"}
        linkLeft={"/"}
  
      >

      </Header1>

<div className="grid   grid-cols-1   md:grid-cols-2  relative w-full"
 style={ { gridTemplateColumns:isMdCss ? `500px auto`:`1fr` }} 
>

<div className="mt-0 md:mt-20    my-1   w-full  "  >
<div className=" flex flex-col   my-1 px-4   w-full  md:sticky md:top-32  "  >
          {!SCHEMA_ID && (
            <input
              type="text"
              id={"searchBar"}
              ref={searchBar}
              placeholder={"Search"}
              onChange={(e) => setSearchValue(e.target.value)}
              className="w-full px-4  rounded-3xl text-zinc-700 p-2"
            />
          )}
          <div className="flex flex-col">
            {SCHEMA_ID && (
              <div className=" p-2 pl-4 appbutton bg-white text-black flex flex-row items-center  mb-4   text-xl rounded-xl">
                <Link to={`/templates`}> Tous les templates </Link>
              </div>
            )}
            {true && ( //SCHEMA_ID && typeof SCHEMA_ID === "number" && SCHEMA_ID > 0
              <div className=" p-2 bg-white text-black flex flex-row w-full items-center  my-4   text-xl rounded-xl">
              { SCHEMA_ID && typeof SCHEMA_ID === "number" && SCHEMA_ID > 0  &&  <Link to={`/schemas/?${SCHEMA_ID}`}>
                  {" "}
                  <FontAwesomeIcon
                    icon={fa.faChevronLeft}
                    className="mx-4"
                  />{" "}
                </Link>
               }
                <FontAwesomeIcon icon={fa.faMobile} className="mx-2" />
                Schéma
                <InputForSchemaProp
                  id={"open"}
                  className={"mx-2"}
                  label={"Schéma"}
                  name={"schema_id"}
                  placeholder={"Sélectionner"}
                  schema_id={TEMPLATE_SCHEMA_ID}
                  value={ SCHEMA_ID && typeof SCHEMA_ID === "number" && SCHEMA_ID > 0 ?{ content_id: parseInt(SCHEMA_ID) }:null}
                  onChange={(value) =>
                    navigate(value ? `/templates?schema_id=${value.content_id}`:`/templates`)
                  }
                  //options={getOptions({ deployment_id: "all" }, "app_id")}
                  context={{ minimized: true, json: true }}
                />
              </div>
            )}
          </div>
          <div className=" flex items-center justify-start w-full p-1 my-1 bg-white rounded-xl ">
            {Object.keys(authors).map((user_id) => (
              <UserThumb
                key={"UserThumb" + user_id}
                size={authors[user_id] ? "w-8 h-8" : "w-6 h-6 "}
                className={
                  " transition-all rounded-full " +
                  (filterUser ? "mr-1" : " -mr-4 ") +
                  (authors[user_id]
                    ? " border border-white  opacity-100 "
                    : " opacity-20 ")
                }
                user_id={user_id}
                noname={true}
                onClick={() => {
                  var _authors = {};
                  if (filterUser) {
                    _authors = { ...authors, [user_id]: !authors[user_id] };

                    var _filterUser = false;
                    Object.keys(authors).forEach((u) => {
                      _filterUser = _filterUser || _authors[u];
                    });
                    if (!_filterUser) {
                      setFilterUser();
                      Object.keys(authors).forEach((u) => {
                        _authors[u] = true;
                      });
                    }

                    setAuthors(_authors);
                  } else {
                    Object.keys(authors).forEach((u) => {
                      _authors[u] = u + "" === "" + authUser.user_id;
                    });
                    setAuthors(_authors);
                    setFilterUser(true);
                  }
                }}
              />
            ))}
            <div className="grow flex items-end justify-end">
              <ButtonTool
                nolabelActive={true}
                label={"Trier chronologiquement"}
                side="bottom"
                active={timeSort}
                icon={fa.faClock}
                onClick={() => setTimeSort(!timeSort)}
              />
            </div>
          </div>

          {schema && !schema.content.is_value && SCHEMA_ID && (
          <>
            <div
              onClick={genDisplayTemplateInit}
              className="appbutton  w-full  flex flex-col items-center justify-center my-1 bg-white bg-opacity-80 text-black  rounded-xl py-2"
            >
              <FontAwesomeIcon
                className="m-2 text-3xl"
                icon={fa.faPlusCircle}
              />
              <span> DISPLAY TEMPLATE </span>
              <FontAwesomeIcon className="m-2 text-2xl" icon={fa.faDesktop} />

              <div className="px-4 text-xs mt-4">
                {" "}
                Choisis les champs et les actions hop !{" "}
              </div>
            </div>
            <div className="appbutton  w-full flex flex-col items-center justify-center my-1 bg-white bg-opacity-80 text-black  rounded-xl py-2">
              <FontAwesomeIcon
                className="m-2 text-3xl"
                icon={fa.faPlusCircle}
              />
              <span> FORMULAIRE TEMPLATE </span>

              <FontAwesomeIcon
                className="m-2 text-2xl"
                icon={fa.faWindowRestore}
              />
              <div className="px-4 text-xs mt-4">
                {" "}
                Choisis les champs de saisie et press {"<Enter>"} !{" "}
              </div>
            </div>

            <div className="appbutton  w-full  flex flex-col items-center justify-center my-1 bg-white bg-opacity-80 text-black  rounded-xl py-2">
              <FontAwesomeIcon
                className="m-2 text-3xl"
                icon={fa.faPlusCircle}
              />
              <span> LIST TEMPLATE </span>
              <FontAwesomeIcon className="m-2 text-2xl" icon={fa.faList} />
              <div className="px-4 text-xs mt-4">
                {" "}
                Choisis ton item, tes modes de navigation, tes actions et
                scrolle !{" "}
              </div>
            </div>
            <div className="appbutton  w-full  flex flex-col items-center justify-center my-1 bg-white bg-opacity-80 text-black  rounded-xl py-2">
              <FontAwesomeIcon
                className="m-2 text-3xl"
                icon={fa.faPlusCircle}
              />
              <span> ITEM TEMPLATE </span>
              <FontAwesomeIcon
                className="m-2 text-2xl"
                icon={fa.faRectangleList}
              />
              <div className="px-4 text-xs mt-4">
                {" "}
                Désigne ton affichage, tes actions !{" "}
              </div>
            </div>
            <div className="appbutton  w-full  flex flex-col items-center justify-center my-1 bg-white bg-opacity-80 text-black  rounded-xl py-2">
              <FontAwesomeIcon
                className="m-2 text-3xl"
                icon={fa.faPlusCircle}
              />
              <span> MAGIC TEMPLATE </span>
              <FontAwesomeIcon className="m-2 text-2xl" icon={fa.faStar} />
              <div className="px-4 text-xs mt-4"> Coming very soon ! </div>
            </div>
          </>
        )}
        {SCHEMA_ID > 0 && schema && schema.content.is_value && (
          <>
            <div className="appbutton  w-full flex flex-col items-center justify-center my-1 bg-white bg-opacity-80 text-black  rounded-xl py-2">
              <FontAwesomeIcon
                className="m-2 text-3xl"
                icon={fa.faPlusCircle}
              />
              <span> VIEW TEMPLATE </span>
              <FontAwesomeIcon
                className="m-2 text-2xl"
                icon={fa.faCircleInfo}
              />

              <div className="px-4 text-xs mt-4">
                {" "}
                Choisis comment on voit ton information
              </div>
            </div>
            <div className="appbutton  w-full  flex flex-col items-center justify-center my-1 bg-white bg-opacity-80 text-black  rounded-xl py-2">
              <FontAwesomeIcon
                className="m-2 text-3xl"
                icon={fa.faPlusCircle}
              />
              <span> INPUT TEMPLATE </span>

              <FontAwesomeIcon className="m-2 text-2xl" icon={fa.faKeyboard} />
              <div className="px-4 text-xs mt-4">
                {" "}
                Crée ton desgin de saisie
              </div>
            </div>
          </>
        )}
{        <div className="appbutton  w-full  flex flex-col items-center justify-center my-1 bg-white bg-opacity-80 text-black  rounded-xl py-2"
onClick={newTemplate}
>
              <FontAwesomeIcon
                className="m-2 text-3xl"
                icon={fa.faPlusCircle}
              />
              <span> NEW TEMPLATE </span>
              <FontAwesomeIcon className="m-2 text-2xl" icon={fa.faList} />
              <div className="px-4 text-xs mt-4">
                {" "}
                Feuille blanche!{" "}
              </div>
            </div>}
        </div>
        </div>
      <div className="w-full flex  p-2 px-4  grow flex-wrap justify-between">
        {sortedTemplates
          .filter(
            (t) =>
              authors[t.owner_id] &&
              (!searchValue ||
                searchValue === "" ||
                (t.title &&
                  t.title.toLowerCase().indexOf(searchValue.toLowerCase()) >
                    -1))
          )
          .map((template, i) => (
            <>
              {!timeSort && template.groupSchema && (
                <div
                  key={"g" + template.template_id}
                  className="p-2 w-screen text-xl text-left font-bold text-white border-b-2 mt-2 mb-1 border-white"
                >
                  {template.groupSchema}
                </div>
              )}
              <div
                key={template.template_id}
                className="m-2  flex flex-col items-center justify-between my-1 bg-black bg-opacity-40 text-white  rounded-xl overflow-hidden "
              >
                <TemplateDisplay
                  template={template}
                  isEditable={true}
                  safeDisplay={true}
                  showLayout={false}
                />
              </div>
            </>
          ))}

       
      </div>
      </div>
    </div>
  );
}

export default Templates;
