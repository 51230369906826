import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import { data } from "../../../../../service/data";

import ContentEdition from "../../../../../components/edition/ContentEdition";
import VarContent from "../../../../../components/VarContent";
import { useEffect, useState } from "react";

import InputSelect from "../../../../../components/inputs/InputSelect";
import { retrieveProject } from "../../../../../service/mftc-api";
import { loadActions, runAction, runActionSync } from "../../../../../tools/actions";
import InputBoolean from "../../../../../components/inputs/InputBoolean";
const ShowTestingContent = ({
  setShow,
  schemas,
  testContent,
  updateTestContent,

  setInternal,
  propsTemplates,
  infos,   

  GetActions
}) => {
  var project = retrieveProject();
  const [app_id, setAppId] = useState();

  const [testingActions, setTestingActions] = useState();

  const[apps,setApps]=useState([])

  useEffect(()=>{
  
  const init=async()=>{
  
    var _apps = await data.getApps()
    setApps(_apps)
  }
  
  
  init()
  },[])
  

  useEffect(() => {
    var actions =GetActions()  
    loadActions( actions);
    setTestingActions(actions)
  }, []);


if(!testContent) return <>ERREUR </>
  
  return (
    <div id={"ShowTestingContent"} className=" flex-none  w-80  bg-white m-2 flex flex-col items-center justify-center border border-white rounded-xl shadow">
      <div className="flex justify-between items-center text-xs w-full p-2 text-white bg-black rounded-xl mb-1 ">
        <FontAwesomeIcon icon={fa.faNoteSticky}></FontAwesomeIcon>
        Données de template
        <FontAwesomeIcon
          icon={fa.faClose}
          onClick={() => setShow((show) => ({ ...show, Data: !show.Data }))}
          className="appbutton"
        ></FontAwesomeIcon>
      </div>
      { !parseInt(testContent.content_id) &&
<InputBoolean   labelTrue={"Sauvegarde des préférences"     }  value={ !!localStorage.getItem( "Pref_" + testContent.content_id)}   

onChange={(value)=>{


if(value){
  localStorage.setItem( "Pref_" + testContent.content_id, JSON.stringify(testContent))
}
else{
  localStorage.removeItem( "Pref_" + testContent.content_id)
}

}}
/>}
      <div className="flex flex-col w-full">
        {!testContent?.app_id && (
          <InputSelect
            label={"Application"}
            value={app_id}
            className="text-black w-full my-1"
            onChange={(app_id) => {
              setAppId(app_id);
            }}
            options={apps.map((app) => {
              return { value: app.app_id + "", label: app.name };
            })}
            placeholder={"Choisir l'application"}
          />
        )}

        {testContent.app_id ?? app_id ? (
          <ContentEdition
            key={"testContent"}
            defaultContent={{
              ...testContent,
              app_id: testContent.app_id ?? app_id,
            }}
            _schemas={schemas}
            dataOnly={true}
            updateLiveContent={updateTestContent}
            data_schema={propsTemplates}
          />
        ) : (
          <div className="flex flex-col w-full">Aucune Application Liée</div>
        )}
        {testingActions &&
          testingActions.map((action, i) => (
            <div
              className="flex w-full flex-row p-2 gap-2 items-center justify-between "
              key={i}
            >
              Tester{" "}
              <div
                className="rounded-md grow text-white bg-blue-600 p-2"
                onClick={(event) =>action.asynchrone ?  runAction(event, action,infos,setInternal) :  runActionSync(event, action,infos,setInternal) }
              >
                {action.title}{" "} 
              </div>
     
            </div>
          ))}

        <div className="w-80 text-xs overflow-scroll p-4 ">
          propsTemplates
          <VarContent data={{propsTemplates}} className={"hidden"} />
          Contents
          <VarContent data={infos} className={" hidden"} />
          <VarContent data={testingActions}    className={"hidden"} />
        </div>
      </div>
    </div>
  );
};

export default ShowTestingContent;
