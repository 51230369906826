import { useContext, useEffect,useMemo } from "react"
import { useState } from "react"
import { MfctContext } from "../../context"
import InputSelect from "./InputSelect"
import InputText from "./InputText"
import { parse } from "@fortawesome/fontawesome-svg-core"
import { data } from "../../service/data"



const InputSchema = ({label,onChange,value,className,placeholder,trueValue,context}) => {

   const [schemas,setSchemas] =useState([])
 //    const {getSchemas} = useContext(MfctContext)
//const schemas =getSchemas();

useEffect (()=>{

const getSchemas =async()=>{
   var s= await data.getSchemas(context?.filter,true,true)

var app_id =context?.app_id
s.sort((a,b)=> { 
   
   if(app_id  ){
      if(a.app_id === app_id &&   b.app_id!==app_id) return -1
      if(b.app_id === app_id &&   a.app_id!==app_id) return 1
   }
   
   return a.title > b.title?1:-1;
})

   setSchemas(s)
}

getSchemas()
},[context])

return (
 <>
 <InputSelect label={label} className={className}  placeholder={placeholder} value={context?.onlyId ? value : value?.content_id} onChange={schema_id=>{
       
   schema_id = schema_id ? parseInt(schema_id) :null
   if( context?.onlyId) { onChange( schema_id)
      return 
   }
     var schema = null
      
         if(schema_id){  


                
  
         schema = schemas.find(s=>s.content_id===schema_id)
        
        }
       
onChange(schema)
     }}   options={ ["" ,...schemas.map(s=>{ return {label : s.title  +(context?.app_id===s.app_id ? "*":"")  ,value:s.content_id}   }  )  ] } /> </>

)
}
export default InputSchema