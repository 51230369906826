import { useEffect, useState } from "react"
import { data } from "../service/data"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as fa from "@fortawesome/free-solid-svg-icons";
import React from "react";


const Icon = React.memo(({ ref,icon,className,style,onClick,id }) => {


const [svg,setSvg]= useState()
 
useEffect(()=>{

        const getSvg= async ()=>{
          
            var _svg = await data.getContent(icon.split("svg:").pop(),true)
           
          setSvg((prevSvg) => (prevSvg !== _svg ? _svg : prevSvg)); 
        }

if(   (typeof icon === "string" && (icon+"").startsWith("svg:"))  ) {
  //console.log( {"getsvg " : icon})
  getSvg()
}else if(typeof icon==="object" && icon?.content_id)
  {
   // console.log( {"svg " : icon})
   // setSvg(icon)
  } 


},[icon])

if(!icon) 
    return <></>
if((typeof icon === "string" && fa[icon] )){
    return <FontAwesomeIcon id={id} ref={ref} icon={fa[ icon]} className= {className} style={style} onClick={onClick}/>
    }

if((typeof icon !== "string" || !icon.startsWith("svg:") )&&   (typeof icon!=="object" || !icon.content_id) ){
 return <FontAwesomeIcon id={id} ref={ref} icon={ icon} className= {className} style={style} onClick={onClick}/>
 }

 if (svg) {
    return (
      <svg
        ref={ref}
        id={id}
        className={" svg-inline--fa  inline-block  " +className}
        style={style}
        onClick={onClick}
        aria-hidden="true"
        focusable="false"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={svg.content.viewBox }
        dangerouslySetInnerHTML={{ __html: svg.content.path }}
        
      />
    )
  }

return null

})
export default Icon