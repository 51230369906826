import { useEffect, useState } from "react"
import VarContent from "../VarContent"
import InputSelect from "./InputSelect"
import InputData from "./InputData"
import { data } from "../../service/data"



const InputContentVariable = ({id,label,onChange,value,className,bg="bg-white",infos,disabled,context}) => {



const [schema,setSchema] = useState()

useEffect(()=>{

const getSchema = async ()=>{
var prop = context?.Variables.find( p=> p.name ===context.Filtre.A  ) 
    var _schema = await data.getSchema(prop.schema_id)
    setSchema(_schema)
}

 if( context?.Variables && context.Filtre?.A  && context.idVariable ==="B"  )  getSchema()
},[context])



return (
    <>
    {context.idVariable ==="A" &&
    <InputSelect  options={["",...(context?.Variables?.map(c=>c.name   ) ??  []) ]}    value={value}  onChange={(value)=>onChange(value)}   />
    }
{ schema && context.Filtre?.A  && context.idVariable ==="B"    &&

<>
{  context.AvailablesParams ?
 <InputSelect label={"Variable"}  options={["",...(context.AvailablesParams?.filter(p=> p.schema_id === schema.content_id).map(c=>c.name   ) ??  []) ]}    value={value}  onChange={(value)=>onChange(value)}   />
:
<InputData   prop={ context?.Variables.find( p=> p.name ===context.Filtre.A  )   }  value={value}  schema={schema} onChange={(value)=>onChange(value)}   />
}


</>

}
    
</> 
)
}
export default InputContentVariable