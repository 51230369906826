import { data } from "./data";
import { retrieveProject } from "./mftc-api";

var dataSources = [];

const getSourceParamNames = (dataSource) => {
  var names = [];
//todo
  return names;
};

const getParamsContent = (content, dataSource) => {
  var params = {};

  return params;//todo
};

const getDataSource = async (dataSource_id, content, template_id,pageParams) => {
  var dataSource;
  if (dataSources[dataSource_id]) dataSource = dataSources[dataSource_id];
  else {
    dataSource = await data.getContent(dataSource_id, true);
if(!dataSource ){
  window.alert("No data source " + dataSource_id)
  return 
}
    dataSource.names = getSourceParamNames(dataSource);
  }
  var params = getParamsContent(dataSource, content);

  var result = await retrieveProject().api("/get_data_source", {
    body: { dataSource_id, template_id, params,pageParams },
  });
  console.log("dataSource " + dataSource_id, result  )

  if(result.users){
    data.setContents(result.users.map(user=> data.entityToContent(user,"user")));
  }
  if(result.apps){
    data.setContents(result.apps.map(app=> data.entityToContent(app,"app")));
  }

if(result.contents)
 data.setContents(result.contents);


  return result.data;
};

export { getDataSource };
