import React, { useEffect, useMemo, useState ,useContext} from 'react';
import { Link, useNavigate } from "react-router-dom";


import '../../../../App.css';
import { MfctContext } from "../../../../context";
import ContentEdition from '../../../../components/edition/ContentEdition';


function NewApp() {

const { project, getProject,setAuthUser,authUser} = useContext (MfctContext);
  const [file, setFile] = useState();
  const [urlApp,setUrlApp] = useState();   
  
  const[primaryColor,setPrimaryColor]=useState("#000000");
  const[secondaryColor,setSecondaryColor]=useState("#FFFFFF");
  
  function handleImgChange(e) {
      console.log(e.target.files);
      setFile(URL.createObjectURL(e.target.files[0]));
  }


  const navigate = useNavigate();
const changePrimary = ()=>{
  setPrimaryColor(document.getElementById('primary_color').value);
}

const changeSecondary = ()=>{
  setSecondaryColor(document.getElementById('secondary_color').value);
}
const handleImgUpload=async (e)=>{
  e.preventDefault();

const formData = new FormData()

var domain =document.getElementById('domain').value;
formData.append('file',document.getElementById('image-upload').files[0]);
formData.append('domain',domain);
formData.append('short_name',document.getElementById('short_name').value);
formData.append('name',document.getElementById('name').value);
formData.append('primary_color',document.getElementById('primary_color').value);
formData.append('display',document.getElementById('display').value);
formData.append('secondary_color',document.getElementById('secondary_color').value);
formData.append('description',document.getElementById('description').value);

var  url_server = process.env.REACT_APP_MFTC_SERVER_URL;
var parts =  window.location.href.split(":");
if(   parts[0]=="http"  && parts[1]!=="//localhost") url_server = url_server.replace("//localhost",parts[1]);

var newapp =   await project.api("/new_apppro", { body:formData }); 

console.log(newapp)

navigate("/apps")

}


  






  return (
    <div className="App  text-zinc-600 text-sm">
      <ContentEdition   schema_id={60}       />
     { !urlApp &&  <header className="App-header min-h-screen relative">

  <div className='z-1 w-full'>      Ma nouvelle application

       <form id="uploadimage"  className="flex flex-col items-center p-2 w-full" onSubmit={handleImgUpload} action="" method="post" encType="multipart/form-data">
    
       <div className='w-full h-80 relative'>
   <div className='absolute  top-0 left-0 w-full h-1/2 z-0' style={{background:primaryColor}}></div>
<div className='absolute top-1/2 left-0 w-full h-1/2 z-0' style={{background:secondaryColor}}></div>   
       <div  className=" absolute top-20  w-40 h-40  m-2 bg-slate-400 rounded-sm" >

    <div  className='w-full h-full p-2 absolute top-0 left-0 flex flex-row items-center justify-center'>  <label  >Select Your Image</label></div>
        <img id="previewing" className="w-full h-full absolute top-0 left-0 " src={file}   onClick={()=>{ document.getElementById("image-upload").click()}} />
    
</div>
  </div>      <input type="file" name="image" id="image-upload" className="hidden" onChange={handleImgChange} required />
    
       <label>Nom du Domaine</label>
   
   <input  type="text"  className='text-black w-full border-b border-zinc-700' name="domain" id="domain"    />
   
          <label>Nom complet de l'application</label>
   
   <input  type="text"  className='text-black  w-full border-b border-zinc-700' name="name" id="name"    />
   <label>Nom Court de l'application </label>
   
   <input  type="text"  className='text-black  w-full border-b border-zinc-700' name="short_name" id="short_name"    />
   <label>Couleur primaire </label>
   
   <input  type="text"  className='text-black  w-full border-b border-zinc-700' name="primary_color" id="primary_color" defaultValue={primaryColor} onChange={changePrimary}  />
   
   <label>Couleur secondaire </label>
   
   <input  type="text"  className='text-black  w-full border-b border-zinc-700' name="secondary_color" id="secondary_color"    defaultValue={secondaryColor} onChange={changeSecondary} />
   <label>Affichage </label>
   
   <select   className='text-black  w-full border-b border-zinc-700' name="display" id="display"    defaultValue={"fullscreen"} 
    >
      <option value="fullscreen">fullscreen</option>
      <option value="standalone">standalone</option>
   </select>

     <label>Description</label>
   <textarea   className='text-black  w-full border  border-zinc-700' name="description"  id="description"    />
 

  
    
    <button type="submit" className="border border-1 p-2 rounded" id="upload">Create my New App</button>
</form>
</div>
      </header> }

      { urlApp && 
      <header className="App-header">
      Votre application a été créée avec succès<br/>
      <a  href={"https://"+ urlApp} target='_blank' > {urlApp}  
      <img  className="w-40 h-40  rounded-sm shadow-sm  " src={"https://"+ urlApp+ "/profile/user.png"} />
      </a>
    
</header>
      
      
      }
  
    </div>
  );
}

export default NewApp;
