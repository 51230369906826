import React, { useEffect, useMemo, useState,useContext, useRef } from 'react';

import { Link,useParams } from 'react-router-dom';

import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Content from '../../../../components/Content';

import Image from '../../../../components/Image';
import '../../../../App.css';
import { MfctContext } from "../../../../context";
import HeaderBack from '../../../../components/HeaderBack';
import Header1 from '../../../../components/Header1';
import ContentSchema from '../../../../components/ContentSchema';
import { getFilterRecord } from '../../../../service/localdata';
import { data } from '../../../../service/data';

function ContentSchemas() {


const [contents,setContents]= useState([])
const { project, getProject,setAuthUser,authUser} = useContext (MfctContext);

const getContents = async()=>{
  var _contents = await getFilterRecord((content)=>content.is_schema, "content");
  console.log( _contents)
  _contents.sort( (a,b) => a.title>b.title ? 1 : -1 )
    setContents(_contents);
}

const  deleteContent = async(content)=>{
  if (!window.confirm("Are you sure you want to delete '" + content.title+"'?")) return
  // 
  await data.init("content",content.content_id,null,project)
await data.delete_content(content.content_id)
await data.close()

await    getContents();
}

useEffect(()=>{
  document.title = 'Schémas de données'
if(project)
  getContents()
 
},[project])

  
const searchBar = useRef();
const [searchValue,setSearchValue]=useState("")

 return (
    <div className="safearea App p-4" > 
  
    <Header1   title={"Schémas de données"}   linkLeft={"/"} linkRight={`/schema/add`}  iconRight={fa.faPlus} classRight={"p-2 rounded-full border border-white "  } >
    <input type="text" id={ "searchBar" }  ref={ searchBar }  onChange={(e)=>setSearchValue(e.target.value)} className="w-full p-2 px-4 m-1 mx-4 rounded-3xl text-zinc-700 " /> 
  </Header1>
      

  
      
     <div  className='w-full my-2 flex flex-wrap p-2  rounded-xl ' >
   {  contents.filter( t=>   !searchValue || searchValue==="" || t.title.toLowerCase().indexOf( searchValue.toLowerCase()   )>-1  ).map((content,i)=>
    
     <ContentSchema  key={i} content={content } deleteContent={deleteContent} isEditable={true}/>
) 
     }
     </div>   
    </div>
  );
}

export default ContentSchemas;