import * as babelParser from "@babel/parser";
import React, { useState, useEffect, Suspense } from "react";
import generate from "@babel/generator";
import * as Babel from "@babel/standalone";
import { retrieveProject } from "../service/mftc-api";

// Initialisation de la base de données IndexedDB
const dbName = "ComponentCache";
const storeName = "components";
let db;

const initDB = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, 1);
    request.onerror = (event) =>
      reject("Erreur d'ouverture de la base de données");
    request.onsuccess = (event) => {
      db = event.target.result;
      resolve(db);
    };
    request.onupgradeneeded = (event) => {
      db = event.target.result;
      db.createObjectStore(storeName, { keyPath: "id" });
    };
  });
};

const resetComponent = async (id) => {
  try {
    // Vérifier si le composant existe dans le cache en mémoire
    if (id in liveComponentCache) {
      delete liveComponentCache[id];
    }

    // Vérifier si le composant existe dans IndexedDB avant de le supprimer
    const cachedComponent = await getComponentFromCache(id);
    if (cachedComponent) {
      await deleteComponentInCache(id);
    }
  } catch (error) {
    console.error("Erreur lors de la réinitialisation du composant:", error);
  }
};

// Fonction pour récupérer un composant du cache
const getComponentFromCache = async (id) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([storeName], "readonly");
    const store = transaction.objectStore(storeName);
    const request = store.get(id);
    request.onerror = (event) => reject("Erreur de récupération du composant");
    request.onsuccess = (event) =>
      resolve(request.result ? request.result.component : null);
  });
};

// Fonction pour stocker un composant dans le cache
const storeComponentInCache = async (id, component) => {
  return new Promise((resolve, reject) => {
    resolve();
    return;
    const transaction = db.transaction([storeName], "readwrite");
    const store = transaction.objectStore(storeName);
    const request = store.put({ id, component });
    request.onerror = (event) => reject("Erreur de stockage du composant");
    request.onsuccess = (event) => resolve();
  });
};

// Fonction pour supprimer un composant du cache
const deleteComponentInCache = async (id) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([storeName], "readwrite");
    const store = transaction.objectStore(storeName);
    const request = store.delete(id);
    request.onerror = (event) => reject("Erreur de suppression du composant");
    request.onsuccess = (event) => resolve();
  });
};
var context;
var modulesIn = {};
const loadImport = (obj_import,prefixe=null) => {
  console.log("Import Module " + obj_import);
  if (!context) {
    console.log("Import Module " + obj_import);
    context = require.context("../.", true, /\.js$/);

    context.keys().forEach((key) => {
      if(key==="./setupTests.js") return ;
      const componentName = key.replace(/^.+\/([^/]+)\.js?$/, "$1");
      try {
        for (const [k, v] of Object.entries(context(key))) {
          modulesIn[componentName + ":" + k] = v;
          if (k !== "default") modulesIn[k] = v;
        }
      } catch (e) {
        console.error(e);
        console.log("error " + key);
      }
    });

    console.log("moduleImports", modulesIn);
  }

  try {
    console.log(Object.keys(modulesIn));
    if (modulesIn[obj_import]) {
      return modulesIn[obj_import];
    } else if (modulesIn[obj_import + ":default"]) {
      return modulesIn[obj_import + ":default"];
    }
    else if (modulesIn[ prefixe + ":"+obj_import]){
      return modulesIn[ prefixe + ":"+obj_import];
    } else {
      console.log("NO IMPORT " + obj_import, modulesIn);
    }
  } catch (error) {
    console.error("Erreur lors du chargement du module:", error);
    return null;
  }
};

const loadImports = async (imports) => {
  const modules = {};
  for (const imp of imports) {
    // Récupérer le nom importé
    const modulePath = imp.source.value; // Récupérer le chemin du module
    var module;
    if (modulePath === "react") module = React;
    else {
      console.log("import module Path ", modulePath);
      // module = await import(modulePath,modules);
      module = await loadImport(imp.specifiers[0].local.name, modules,modulePath.split("/").pop().split(".")[0] );
    }



    for (const specifier of imp.specifiers) {




      const moduleName = specifier.local.name;
      console.log("Module to iMPORT ",{moduleName,modulePath ,spe: specifier.type,module} )
      if (specifier.type === "ImportDefaultSpecifier")
        modules[moduleName] = module;
     
      else if (specifier.type === "ImportNamespaceSpecifier")
      {
          modules[moduleName] = module[specifier.imported.name];
      }
      else if (specifier.imported.name in  module){
        modules[moduleName] = module[specifier.imported.name];
      }
      else    if (specifier.type === "ImportSpecifier")
        modules[moduleName] = module;
    }
  }
  return modules;
};

// Ajoutez ce cache en mémoire au début du fichier, en dehors de tout composant
const liveComponentCache = {};

const compileComponent = async (componentContent, id) => {
  // Vérifier si le composant est déjà dans le cache en mémoire
  if (liveComponentCache[id]) {
    return liveComponentCache[id];
  }

  const ast = babelParser.parse(componentContent, {
    sourceType: "module",
    plugins: ["jsx"],
  });

  const imports = ast.program.body.filter(
    (node) => node.type === "ImportDeclaration"
  );
  ast.program.body = ast.program.body.filter(
    (node) => node.type !== "ImportDeclaration"
  );

  const output = generate(ast).code;

  const componentName = id;
  const transpiledCode =
    Babel.transform(output, { presets: ["react"] }).code +
    `
  exports.default = ${componentName}`;


  const modules = await loadImports(imports);
  
  console.log({imports,modules})
  const exports = {};
  const func = new Function(...Object.keys(modules), "exports", transpiledCode);
  func(...Object.values(modules), exports);

  // Stocker le composant compilé dans le cache en mémoire
  liveComponentCache[id] = exports.default;

  return exports.default;
};

const liveComponent = (params) => {
  const id = `${params.page_id ? "Page" + params.page_id : ""}${
    params.template_id ? "Template" + params.template_id : ""
  }${params.content_id ? "C" + params.content_id : ""}`;

  // Vérifier d'abord le cache en mémoire
  if (liveComponentCache[id]) {
    return liveComponentCache[id];
  }
};
const pendingRequests = new Map();

const loadComponent = async (params) => {
  try {
    const id = `${params.page_id ? "Page" + params.page_id : ""}${
      params.template_id ? "Template" + params.template_id : ""
    }${params.content_id ? "C" + params.content_id : ""}`; // Vérifier d'abord le cache en mémoire
    if (liveComponentCache[id]) {
      return liveComponentCache[id];
    }
    var compiledComponent;
    const requestKey = `${JSON.stringify(params)}`;
    if (pendingRequests.has(requestKey)) {
      // Si une requête similaire est en cours, attendre son résultat
      console.log("pending " + requestKey);
      compiledComponent = await pendingRequests.get(requestKey);
      return compiledComponent;
    } else {
      // Créer une promesse pour cette requête
      const requestPromise = new Promise(async (resolve) => {
        if (!db) {
          await initDB();
        }

        // Vérifier si le componentContent est dans le cache IndexedDB
        const cachedComponentContent = await getComponentFromCache(id);
        if (cachedComponentContent) {
          const compiledComponent = await compileComponent(
            cachedComponentContent,
            id
          );
          resolve(compiledComponent);
        }

        console.log("computing " + requestKey);
        const response = await retrieveProject().api("/gen_component", {
          body: params,
        });

        if (response?.component) {
          const componentContent = response.component;

          if (componentContent) {
            // Compiler le composant
            const compiledComponent = await compileComponent(
              componentContent,
              id
            );

            // Stocker le componentContent dans le cache IndexedDB
            await storeComponentInCache(id, componentContent);
            resolve(compiledComponent);
          }
        } else {
          console.error(
            "Erreur lors de la réponse du serveur:",
            response.status
          );
        }
      });

      pendingRequests.set(requestKey, requestPromise);
      try {
        var compiledComponent = await requestPromise;
        console.log("resolve " + requestKey);
      } finally {
        // Supprimer la promesse de la map une fois terminée
        pendingRequests.delete(requestKey);
      }
      return compiledComponent;
    }
  } catch (error) {
    console.error("Erreur lors du chargement du composant dynamique:", error);
  }
};

export { loadComponent, resetComponent, liveComponent };
